import {
  IProfile,
  PatchProfilePictureRequest,
  PatchProfileRequest,
  PostProfilePictureRequest,
} from "../../models";

import { api } from "../api";

export const getProfile = async (): Promise<IProfile> => {
  const response = await api.get<IProfile>(`/my-profile`);
  return response.data;
};

export const patchProfile = async (
  request: PatchProfileRequest
): Promise<void> => {
  await api.patch(`/my-profile`, request);
};

export const patchProfilePicture = async (
  request: PatchProfilePictureRequest
): Promise<void> => {
  await api.patch(`/my-profile`, request);
};

export const postProfilePicture = async (
  request: PostProfilePictureRequest
): Promise<void> => {
  const formData = new FormData();
  formData.append("file", request.file);
  const response = await api.post(`/my-profile`, formData, {
    headers: {
      "Content-type": "multipart/form-date",
    },
  });
  return response.data;
};
