import {
    IMe,
    ISignUpRequest,
    ISignUpWithGoogleRequest,
    getMe,
    refreshAccessTokens,
    signInWithGoogle,
    signInWithPassword,
    signUpWithGoogle,
    signUpWithPassword,
} from "@daxrm/api";
import { ReactNode, createContext, useEffect, useReducer } from "react";
import { getRefreshToken, setRefreshTokeSession, setSession } from "../utils/jwt";

import {
    UserCredential,
} from 'firebase/auth';

const DEFAULT_TOKEN_REFRESH_INTERVAL = 55 * 60 * 1000; // 55 minutes
const tokenRefreshIntervalEnvValue = Number(
    process.env.REACT_APP_TOKEN_REFRESH_INTERVAL,
);
const tokenRefreshInterval =
    isNaN(tokenRefreshIntervalEnvValue) || tokenRefreshIntervalEnvValue <= 0
        ? DEFAULT_TOKEN_REFRESH_INTERVAL
        : tokenRefreshIntervalEnvValue;


export type ActionMap<M extends { [index: string]: any }> = {
    [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
    }
    : {
        type: Key;
        payload: M[Key];
    };
};

export type AuthUser = null | IMe;

export type AuthState = {
    isAuthenticated: boolean;
    isInitialized: boolean;
    user: AuthUser;
    googleAuthUserCredentials?: UserCredential
};

export type JWTContextType = {
    isAuthenticated: boolean;
    isInitialized: boolean;
    user: AuthUser;
    googleAuthUserCredentials?: UserCredential;
    signUp: (email: string, password: string, data: Omit<ISignUpRequest, 'email' | 'password'>) => Promise<void>;
    signIn: (email: string, password: string, rememberMe: boolean) => Promise<void>;
    signOut: () => Promise<void>;
    googleSignIn: (idToken: string, rememberMe: boolean) => Promise<void>;
    googleSignUp: (idToken: string, data: Omit<ISignUpWithGoogleRequest, 'idToken'>) => Promise<void>;
    refreshAccessToken: () => Promise<void>;
    doGoogleAuth: () => Promise<void>;
    accountMe: () => Promise<void>;
};

enum Types {
    Initial = "INITIALIZE",
    SignIn = "SIGN_IN",
    SignOut = "SIGN_OUT",
    CompleteGoogleRegistration = "COMPLETE_GOOGLE_REGISTRATION",
    RefreshToken = 'REFRESH_ACCESS_TOKEN'
}

type JWTAuthPayload = {
    [Types.Initial]: {
        isAuthenticated: boolean;
        user: AuthUser;
    };
    [Types.SignIn]: {
        user: AuthUser;
    };
    [Types.SignOut]: undefined;
    [Types.CompleteGoogleRegistration]: {
        googleAuthUserCredentials: UserCredential;
    };
    [Types.RefreshToken]: undefined;
};

export type JWTActions =
    ActionMap<JWTAuthPayload>[keyof ActionMap<JWTAuthPayload>];

const initialState: AuthState = {
    isAuthenticated: false,
    isInitialized: false,
    user: null,
};

const JWTReducer = (state: AuthState, action: JWTActions) => {
    switch (action.type) {
        case "INITIALIZE":
            return {
                isAuthenticated: action.payload.isAuthenticated,
                isInitialized: true,
                user: action.payload.user,
                googleAuthUserCredentials: undefined
            };
        case "SIGN_IN":
            return {
                ...state,
                isAuthenticated: true,
                isInitialized: true,
                user: action.payload.user,
                googleAuthUserCredentials: undefined
            };
        case "SIGN_OUT":
            return {
                ...state,
                isAuthenticated: false,
                user: null,
                googleAuthUserCredentials: undefined
            };
        case "COMPLETE_GOOGLE_REGISTRATION":
            return {
                ...state,
                isAuthenticated: false,
                isInitialized: true,
                googleAuthUserCredentials: action.payload.googleAuthUserCredentials,
            };
        default:
            return state;
    }
};

const AuthContext = createContext<JWTContextType | null>(null);

function AuthProvider({ children }: { children: ReactNode }) {
    const [state, dispatch] = useReducer(JWTReducer, initialState);

    const signUp = async (email: string, password: string, data: Omit<ISignUpRequest, 'email' | 'password'>) => {
        await signUpWithPassword({
            email: email,
            password: password,
            ...data
        });
        // await signIn(email, password, false);
    };


    const signIn = async (email: string, password: string, rememberMe: boolean) => {
        const { accessToken, refreshToken } = await signInWithPassword(email, password, rememberMe);

        setSession(accessToken);
        setRefreshTokeSession(refreshToken);

        const user = await getMe();
        dispatch({
            type: Types.SignIn,
            payload: {
                user
            }
        });
    };


    const signOut = async () => {
        setSession(null);
        setRefreshTokeSession(null);
        dispatch({ type: Types.SignOut });
    };

    const googleSignIn = async (idToken: string, rememberMe: boolean) => {
        const { accessToken, refreshToken } = await signInWithGoogle(idToken, rememberMe);

        setSession(accessToken);
        setRefreshTokeSession(refreshToken);

        const user = await getMe();
        dispatch({
            type: Types.SignIn,
            payload: {
                user
            }
        });
    };

    const googleSignUp = async (idToken: string, data: Omit<ISignUpWithGoogleRequest, 'idToken'>) => {
        await signUpWithGoogle({
            idToken: idToken,
            ...data
        });
    };

    const refreshAccessToken = async () => {
        const refreshToken = getRefreshToken();
        if (refreshToken) {
            const { accessToken, refreshToken: newRefreshToken } = await refreshAccessTokens(refreshToken)
            setSession(accessToken);
            setRefreshTokeSession(newRefreshToken);
            dispatch({ type: Types.RefreshToken });
        }
    };

    const accountMe = async () => {
        const user = await getMe();
        if (user) {
            dispatch({
                type: Types.Initial,
                payload: {
                    isAuthenticated: true,
                    user: user,
                },
            });
        }
    };

    const doGoogleAuth = async () => { };

    useEffect(() => {
        const initialize = async () => {
            try {
                dispatch({
                    type: Types.Initial,
                    payload: {
                        isAuthenticated: false,
                        user: null,
                    },
                });
            } catch (err) {
                setSession(null);
                dispatch({
                    type: Types.Initial,
                    payload: {
                        isAuthenticated: false,
                        user: null,
                    },
                });
            }
        };

        initialize();
    }, []);

    useEffect(() => {
        if (state.isAuthenticated) {
            const refreshTokenHandler = setInterval(() => {
                refreshAccessToken();
            }, tokenRefreshInterval);
            return () => {
                if (refreshTokenHandler) {
                    clearTimeout(refreshTokenHandler);
                }
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.isAuthenticated]);


    return (
        <AuthContext.Provider
            value={{
                ...state,
                signUp,
                signIn,
                signOut,
                googleSignIn,
                googleSignUp,
                refreshAccessToken,
                doGoogleAuth,
                accountMe,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
}

export { AuthContext, AuthProvider };
