import { api } from "..";
import {
  IRankTracker,
  PatchRankTrackerRequest,
} from "../../models/rank-tracker";

export const getRankTracker = async (
  subscriptionId: string
): Promise<IRankTracker> => {
  const response = await api.get<IRankTracker>(
    `/${subscriptionId}/serp/frequency/setting`
  );
  return response.data;
};

export const patchRankTracker = async (
  subscriptionId: string,
  request: PatchRankTrackerRequest
): Promise<void> => {
  await api.patch(`/${subscriptionId}/serp/frequency/setting`, request);
};
