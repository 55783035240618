import {
  DeleteGAAccountRequest,
  GetGoogleGaConnectorAuthUrlRequest,
  GetGoogleGaConnectorRequest,
  IGoogleConnector,
  IGoogleConnectorAuthUrlResponse,
  IGoogleConnectorTokenResponse,
  PostConnectGoogleConnectorRequest,
} from "../../../../models";

import { api } from "../../../api";

export const getGoogleGa3ConnectorAuthUrl = async (
  subscriptionId: string,
  request?: GetGoogleGaConnectorAuthUrlRequest
): Promise<IGoogleConnectorAuthUrlResponse> => {
  const response = await api.get<IGoogleConnectorAuthUrlResponse>(
    `/${subscriptionId}/connector/google/ga/auth-url`,
    {
      params: request,
    }
  );
  return response.data;
};

export const getGoogleGaConnectors = async (
  subscriptionId: string,
  request?: GetGoogleGaConnectorRequest
): Promise<IGoogleConnector[]> => {
  const response = await api.get<IGoogleConnector[]>(
    `/${subscriptionId}/connector/google/ga`,
    {
      params: request,
    }
  );
  return response.data;
};

export const connectToGoogleGaConnector = async (
  subscriptionId: string,
  request: PostConnectGoogleConnectorRequest
): Promise<IGoogleConnector> => {
  const response = await api.post<IGoogleConnector>(
    `/${subscriptionId}/connector/google/ga/connect`,
    request
  );
  return response.data;
};

export const disconnectFromGoogleGaConnector = async (
  subscriptionId: string,
  id: string,
  request: DeleteGAAccountRequest
): Promise<void> => {
  await api.delete(
    `/${subscriptionId}/connector/google/ga/disconnect/${encodeURIComponent(
      id
    )}`,
    {
      params: request,
    }
  );
};

export const getGoogleGa3ConnectorToken = async (
  subscriptionId: string,
  id: string
): Promise<IGoogleConnectorTokenResponse> => {
  const response = await api.get<IGoogleConnectorTokenResponse>(
    `/${subscriptionId}/connector/google/ga/token/${encodeURIComponent(id)}`
  );
  return response.data;
};
