import {
  GetCompaniesRequest,
  ICompany,
  IPaginated,
  PatchCompanyRequest,
  PostCompanyRequest,
  PutCompanyRequest,
} from "../models";

import { api } from "./api";

export const getCompanies = async (
  subscriptionId: string,
  currentPage: number,
  itemsPerPage: number,
  request?: GetCompaniesRequest
): Promise<IPaginated<ICompany>> => {
  const response = await api.get<IPaginated<ICompany>>(
    `/${subscriptionId}/company`,
    {
      params: {
        currentPage: currentPage,
        itemsPerPage: itemsPerPage,
        ...request,
      },
    }
  );
  return response.data;
};

export const getCompany = async (
  subscriptionId: string,
  id: string
): Promise<ICompany> => {
  const response = await api.get<ICompany>(`/${subscriptionId}/company/${id}`);
  return response.data;
};

export const postCompany = async (
  subscriptionId: string,
  request: PostCompanyRequest
): Promise<ICompany> => {
  const response = await api.post<ICompany>(
    `/${subscriptionId}/company`,
    request
  );
  return response.data;
};

export const putCompany = async (
  subscriptionId: string,
  id: string,
  request: PutCompanyRequest
): Promise<void> => {
  await api.put(`/${subscriptionId}/company/${id}`, request);
};

export const patchCompany = async (
  subscriptionId: string,
  id: string,
  request: PatchCompanyRequest
): Promise<void> => {
  await api.patch(`/${subscriptionId}/company/${id}`, request);
};

export const deleteCompany = async (
  subscriptionId: string,
  id: string
): Promise<void> => {
  await api.delete(`/${subscriptionId}/company/${id}`);
};
