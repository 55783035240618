import {
  GetAccountConnectorRequest,
  GetGoogleConnectorReportRequest,
  IGA4Account,
  IGA4AccountReport,
  IGAConnectedAccount,
  PatchGAAccountRequest,
  PatchUnlinkAccountConnectedItemRequest,
} from "../../../../models";

import { api } from "../../../api";

export const getGA4Accounts = async (
  subscriptionId: string,
  request?: GetAccountConnectorRequest
): Promise<IGA4Account> => {
  const response = await api.get<IGA4Account>(
    `/${subscriptionId}/account-connector/link`,
    {
      params: request,
    }
  );
  return response.data;
};

export const patchGA4Account = async (
  subscriptionId: string,
  accountId: string,
  request: PatchGAAccountRequest
): Promise<void> => {
  await api.patch(`/${subscriptionId}/account-connector/${accountId}`, request);
};

export const getGA4ConnectedAccount = async (
  subscriptionId: string,
  accountId: string,
  request?: GetAccountConnectorRequest
): Promise<IGAConnectedAccount> => {
  const response = await api.get<IGAConnectedAccount>(
    `/${subscriptionId}/account-connector/${accountId}/list`,
    {
      params: request,
    }
  );
  return response.data;
};

export const unlinkGA4ConnectedAccount = async (
  subscriptionId: string,
  accountId: string,
  request: PatchUnlinkAccountConnectedItemRequest
): Promise<void> => {
  await api.patch(
    `/${subscriptionId}/account-connector/${accountId}/unlink`,
    request
  );
};

export const getGA4AccountReport = async (
  subscriptionId: string,
  accountId: string,
  request?: GetGoogleConnectorReportRequest
): Promise<IGA4AccountReport> => {
  const response = await api.get<IGA4AccountReport>(
    `/${subscriptionId}/account-connector/${accountId}/report`,
    {
      params: request,
    }
  );
  return response.data;
};
