import {
  GetCompetitorKeywordTrackingsRequest,
  ICompetitor,
  ICompetitorKeywordTracking,
  IPaginated,
  PostCompetitorRequest,
} from "../../../models";
import { api } from "../../api";

export const getCompetitors = async (
  subscriptionId: string,
  serpId: string
): Promise<ICompetitor[]> => {
  const response = await api.get<ICompetitor[]>(
    `/${subscriptionId}/serp/${serpId}/competitor`
  );
  return response.data;
};

export const postCompetitor = async (
  subscriptionId: string,
  serpId: string,
  request: PostCompetitorRequest
): Promise<ICompetitor> => {
  const response = await api.post<ICompetitor>(
    `/${subscriptionId}/serp/${serpId}/competitor`,
    request
  );
  return response.data;
};

export const deleteCompetitor = async (
  subscriptionId: string,
  serpId: string,
  id: string
): Promise<void> => {
  await api.delete(`/${subscriptionId}/serp/${serpId}/competitor/${id}`);
};

export const getCompetitorKeywords = async (
  subscriptionId: string,
  serpId: string,
  currentPage: number,
  itemsPerPage: number,
  request?: GetCompetitorKeywordTrackingsRequest
): Promise<IPaginated<ICompetitorKeywordTracking>> => {
  const response = await api.get<IPaginated<ICompetitorKeywordTracking>>(
    `/${subscriptionId}/serp/${serpId}/competitor/audit`,
    {
      params: {
        currentPage: currentPage,
        itemsPerPage: itemsPerPage,
        ...request,
      },
    }
  );
  return response.data;
};
