import {
  AddPaymentMethodRequest,
  GetPaymentMethodsRequest,
  SetDefaultPaymentMethodRequest,
} from "../../models";
import { PaymentIntent, PaymentMethod } from "@stripe/stripe-js";

import { api } from "../api";

export const getPaymentMethods = async (
  subscriptionId: string,
  request: GetPaymentMethodsRequest
): Promise<PaymentMethod[]> => {
  const response = await api.get<PaymentMethod[]>(
    `/${subscriptionId}/stripe/payment-method`,
    {
      params: {
        ...request,
      },
    }
  );
  return response.data;
};

export const addPaymentMethod = async (
  subscriptionId: string,
  request: AddPaymentMethodRequest
): Promise<PaymentIntent> => {
  const response = await api.post<PaymentIntent>(
    `/${subscriptionId}/stripe/payment-method`,
    request
  );
  return response.data;
};

export const setDefaultPaymentMethod = async (
  subscriptionId: string,
  request: SetDefaultPaymentMethodRequest
): Promise<void> => {
  await api.post<void>(
    `/${subscriptionId}/stripe/payment-method/default`,
    request
  );
};
