import {
  GetAccountProjectRequest,
  IAccountProject,
  IImportAccountProjectTask,
  PatchAccountProjectRequest,
  PostAccountProjectRequest,
  PostImportAccountProjectTaskRequest,
} from "../../models";
import { api } from "../api";

export const getAccountProjects = async (
  subscriptionId: string,
  accountId: string,
  request?: GetAccountProjectRequest
): Promise<IAccountProject[]> => {
  const response = await api.get<IAccountProject[]>(
    `/${subscriptionId}/account-project/${accountId}`,
    {
      params: {
        ...request,
      },
    }
  );
  return response.data;
};

export const getAccountProject = async (
  subscriptionId: string,
  accountId: string,
  projectId: string
): Promise<IAccountProject> => {
  const response = await api.get<IAccountProject>(
    `/${subscriptionId}/account-project/${accountId}/project/${projectId}`
  );
  return response.data;
};

export const postAccountProject = async (
  subscriptionId: string,
  accountId: string,
  request: PostAccountProjectRequest
): Promise<IAccountProject> => {
  const response = await api.post<IAccountProject>(
    `/${subscriptionId}/account-project/${accountId}`,
    request
  );
  return response.data;
};

export const patchAccountProject = async (
  subscriptionId: string,
  projectId: string,
  request: PatchAccountProjectRequest
): Promise<void> => {
  await api.patch(`/${subscriptionId}/account-project/${projectId}`, request);
};

export const importAccountProjectTask = async (
  subscriptionId: string,
  projectId: string,
  projectTemplateId: string,
  request: PostImportAccountProjectTaskRequest
): Promise<IImportAccountProjectTask> => {
  const response = await api.post<IImportAccountProjectTask>(
    `/${subscriptionId}/account-project/${projectId}/project-template/${projectTemplateId}`,
    request
  );
  return response.data;
};

export const deleteAccountProject = async (
  subscriptionId: string,
  projectId: string
): Promise<void> => {
  await api.delete(`/${subscriptionId}/account-project/${projectId}`);
};
