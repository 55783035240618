import {
    GetCompanyLookupRequest,
    ICompanyLookup,
    IPaginated,
  } from "../models";
  
  import { api } from "./api";
  
  export const getCompanyLookup = async (
    subscriptionId: string,
    currentPage: number,
    itemsPerPage: number,
    request?: GetCompanyLookupRequest
  ): Promise<IPaginated<ICompanyLookup>> => {
    const response = await api.get<IPaginated<ICompanyLookup>>(
      `/${subscriptionId}/company-lookup`,
      {
        params: {
          currentPage: currentPage,
          itemsPerPage: itemsPerPage,
          ...request,
        },
      }
    );
    return response.data;
  };