import {
  GetAccountConnectorRequest,
  GetFacebookAdsConnectorAuthUrlRequest,
  GetMetaConnectorReportRequest,
  IFADSAccount,
  IFADSConnectedAccount,
  IFacebookConnectorAuthUrlResponse,
  IMetaConnector,
  IMetaConnectorReport,
  PatchFADSAccountRequest,
  PatchUnlinkMetaConnectedItemRequest,
  PostConnectMetaConnectorRequest,
} from "../../../models";

import { api } from "../../api";

export const getFacebookAdsConnectorAuthUrl = async (
  subscriptionId: string,
  request?: GetFacebookAdsConnectorAuthUrlRequest
): Promise<IFacebookConnectorAuthUrlResponse> => {
  const response = await api.get<IFacebookConnectorAuthUrlResponse>(
    `/${subscriptionId}/connector/meta/fads/auth-url`,
    {
      params: request,
    }
  );
  return response.data;
};

export const getMetaFadsConnectors = async (
  subscriptionId: string
): Promise<IMetaConnector[]> => {
  const response = await api.get<IMetaConnector[]>(
    `/${subscriptionId}/connector/meta/fads`
  );
  return response.data;
};

export const connectToMetaFadsConnector = async (
  subscriptionId: string,
  request: PostConnectMetaConnectorRequest
): Promise<IMetaConnector> => {
  const response = await api.post<IMetaConnector>(
    `/${subscriptionId}/connector/meta/fads/connect`,
    request
  );
  return response.data;
};

export const disconnectFromMetaFadsConnector = async (
  subscriptionId: string,
  id: string
): Promise<void> => {
  await api.delete(
    `/${subscriptionId}/connector/meta/fads/disconnect/${encodeURIComponent(
      id
    )}`
  );
};

export const getMetaFadsAccounts = async (
  subscriptionId: string,
  request?: GetAccountConnectorRequest
): Promise<IFADSAccount> => {
  const response = await api.get<IFADSAccount>(
    `/${subscriptionId}/account-connector/link`,
    {
      params: request,
    }
  );
  return response.data;
};

export const patchMetaFadsAccount = async (
  subscriptionId: string,
  accountId: string,
  request: PatchFADSAccountRequest
): Promise<void> => {
  await api.patch(`/${subscriptionId}/account-connector/${accountId}`, request);
};

export const getMetaFadsConnectedAccount = async (
  subscriptionId: string,
  accountId: string,
  request?: GetAccountConnectorRequest
): Promise<IFADSConnectedAccount> => {
  const response = await api.get<IFADSConnectedAccount>(
    `/${subscriptionId}/account-connector/${accountId}/list`,
    {
      params: request,
    }
  );
  return response.data;
};

export const unlinkMetaFadsConnectedLocation = async (
  subscriptionId: string,
  accountId: string,
  request: PatchUnlinkMetaConnectedItemRequest
): Promise<void> => {
  await api.patch(
    `/${subscriptionId}/account-connector/${accountId}/unlink`,
    request
  );
};

export const getMetaFadsAccountReport = async (
  subscriptionId: string,
  accountId: string,
  request?: GetMetaConnectorReportRequest
): Promise<IMetaConnectorReport> => {
  const response = await api.get<IMetaConnectorReport>(
    `/${subscriptionId}/account-connector/${accountId}/report`,
    {
      params: request,
    }
  );
  return response.data;
};
