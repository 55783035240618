import {
  GetGoogleConnectorReportRequest,
  IGADSAccountAdGroupsClickReport,
  IGADSAccountAdGroupsReport,
  IGADSAccountAdsClickReport,
  IGADSAccountAdsReport,
  IGADSAccountCallReport,
  IGADSAccountCampaignsClickReport,
  IGADSAccountCampaignsReport,
  IGADSAccountConversionsReport,
  IGADSAccountDemographicsAgeColumnChartReport,
  IGADSAccountDemographicsAgeLineChartReport,
  IGADSAccountDemographicsAgeReport,
  IGADSAccountDemographicsGenderDonutChartReport,
  IGADSAccountDemographicsGenderLineChartReport,
  IGADSAccountDemographicsGenderReport,
  IGADSAccountKeywordsClickReport,
  IGADSAccountKeywordsReport,
  IGADSAccountSearchTermsClickReport,
  IGADSAccountSearchTermsReport,
} from "../../../../models";
import { api } from "../../../api";

export const getGAdsCampaignsListReport = async (
  subscriptionId: string,
  accountId: string,
  request?: GetGoogleConnectorReportRequest
): Promise<IGADSAccountCampaignsReport> => {
  const response = await api.get<IGADSAccountCampaignsReport>(
    `/${subscriptionId}/account-connector/${accountId}/report`,
    {
      params: request,
    }
  );
  return response.data;
};

export const getGAdsCampaignsClickReport = async (
  subscriptionId: string,
  accountId: string,
  request?: GetGoogleConnectorReportRequest
): Promise<IGADSAccountCampaignsClickReport> => {
  const response = await api.get<IGADSAccountCampaignsClickReport>(
    `/${subscriptionId}/account-connector/${accountId}/report`,
    {
      params: request,
    }
  );
  return response.data;
};

export const getGAdsAdGroupsListReport = async (
  subscriptionId: string,
  accountId: string,
  request?: GetGoogleConnectorReportRequest
): Promise<IGADSAccountAdGroupsReport> => {
  const response = await api.get<IGADSAccountAdGroupsReport>(
    `/${subscriptionId}/account-connector/${accountId}/report`,
    {
      params: request,
    }
  );
  return response.data;
};

export const getGAdsAdGroupsClickReport = async (
  subscriptionId: string,
  accountId: string,
  request?: GetGoogleConnectorReportRequest
): Promise<IGADSAccountAdGroupsClickReport> => {
  const response = await api.get<IGADSAccountAdGroupsClickReport>(
    `/${subscriptionId}/account-connector/${accountId}/report`,
    {
      params: request,
    }
  );
  return response.data;
};

export const getGAdsKeywordsListReport = async (
  subscriptionId: string,
  accountId: string,
  request?: GetGoogleConnectorReportRequest
): Promise<IGADSAccountKeywordsReport> => {
  const response = await api.get<IGADSAccountKeywordsReport>(
    `/${subscriptionId}/account-connector/${accountId}/report`,
    {
      params: request,
    }
  );
  return response.data;
};

export const getGAdsKeywordsClickReport = async (
  subscriptionId: string,
  accountId: string,
  request?: GetGoogleConnectorReportRequest
): Promise<IGADSAccountKeywordsClickReport> => {
  const response = await api.get<IGADSAccountKeywordsClickReport>(
    `/${subscriptionId}/account-connector/${accountId}/report`,
    {
      params: request,
    }
  );
  return response.data;
};

export const getGAdsSearchTermsListReport = async (
  subscriptionId: string,
  accountId: string,
  request?: GetGoogleConnectorReportRequest
): Promise<IGADSAccountSearchTermsReport> => {
  const response = await api.get<IGADSAccountSearchTermsReport>(
    `/${subscriptionId}/account-connector/${accountId}/report`,
    {
      params: request,
    }
  );
  return response.data;
};

export const getGAdsSearchTermsClickReport = async (
  subscriptionId: string,
  accountId: string,
  request?: GetGoogleConnectorReportRequest
): Promise<IGADSAccountSearchTermsClickReport> => {
  const response = await api.get<IGADSAccountSearchTermsClickReport>(
    `/${subscriptionId}/account-connector/${accountId}/report`,
    {
      params: request,
    }
  );
  return response.data;
};

export const getGAdsAdsListReport = async (
  subscriptionId: string,
  accountId: string,
  request?: GetGoogleConnectorReportRequest
): Promise<IGADSAccountAdsReport> => {
  const response = await api.get<IGADSAccountAdsReport>(
    `/${subscriptionId}/account-connector/${accountId}/report`,
    {
      params: request,
    }
  );
  return response.data;
};

export const getGAdsAdsClickReport = async (
  subscriptionId: string,
  accountId: string,
  request?: GetGoogleConnectorReportRequest
): Promise<IGADSAccountAdsClickReport> => {
  const response = await api.get<IGADSAccountAdsClickReport>(
    `/${subscriptionId}/account-connector/${accountId}/report`,
    {
      params: request,
    }
  );
  return response.data;
};

export const getGAdsConversionsListReport = async (
  subscriptionId: string,
  accountId: string,
  request?: GetGoogleConnectorReportRequest
): Promise<IGADSAccountConversionsReport> => {
  const response = await api.get<IGADSAccountConversionsReport>(
    `/${subscriptionId}/account-connector/${accountId}/report`,
    {
      params: request,
    }
  );
  return response.data;
};

export const getGAdsDemographicsAgeListReport = async (
  subscriptionId: string,
  accountId: string,
  request?: GetGoogleConnectorReportRequest
): Promise<IGADSAccountDemographicsAgeReport> => {
  const response = await api.get<IGADSAccountDemographicsAgeReport>(
    `/${subscriptionId}/account-connector/${accountId}/report`,
    {
      params: request,
    }
  );
  return response.data;
};

export const getGAdsDemographicsAgeColumnReport = async (
  subscriptionId: string,
  accountId: string,
  request?: GetGoogleConnectorReportRequest
): Promise<IGADSAccountDemographicsAgeColumnChartReport> => {
  const response = await api.get<IGADSAccountDemographicsAgeColumnChartReport>(
    `/${subscriptionId}/account-connector/${accountId}/report`,
    {
      params: request,
    }
  );
  return response.data;
};

export const getGAdsDemographicsAgeLineReport = async (
  subscriptionId: string,
  accountId: string,
  request?: GetGoogleConnectorReportRequest
): Promise<IGADSAccountDemographicsAgeLineChartReport> => {
  const response = await api.get<IGADSAccountDemographicsAgeLineChartReport>(
    `/${subscriptionId}/account-connector/${accountId}/report`,
    {
      params: request,
    }
  );
  return response.data;
};

export const getGAdsDemographicsGenderListReport = async (
  subscriptionId: string,
  accountId: string,
  request?: GetGoogleConnectorReportRequest
): Promise<IGADSAccountDemographicsGenderReport> => {
  const response = await api.get<IGADSAccountDemographicsGenderReport>(
    `/${subscriptionId}/account-connector/${accountId}/report`,
    {
      params: request,
    }
  );
  return response.data;
};

export const getGAdsDemographicsGenderDonutReport = async (
  subscriptionId: string,
  accountId: string,
  request?: GetGoogleConnectorReportRequest
): Promise<IGADSAccountDemographicsGenderDonutChartReport> => {
  const response =
    await api.get<IGADSAccountDemographicsGenderDonutChartReport>(
      `/${subscriptionId}/account-connector/${accountId}/report`,
      {
        params: request,
      }
    );
  return response.data;
};

export const getGAdsDemographicsGenderLineReport = async (
  subscriptionId: string,
  accountId: string,
  request?: GetGoogleConnectorReportRequest
): Promise<IGADSAccountDemographicsGenderLineChartReport> => {
  const response = await api.get<IGADSAccountDemographicsGenderLineChartReport>(
    `/${subscriptionId}/account-connector/${accountId}/report`,
    {
      params: request,
    }
  );
  return response.data;
};

export const getGAdsCallReport = async (
  subscriptionId: string,
  accountId: string,
  request?: GetGoogleConnectorReportRequest
): Promise<IGADSAccountCallReport> => {
  const response = await api.get<IGADSAccountCallReport>(
    `/${subscriptionId}/account-connector/${accountId}/report`,
    {
      params: request,
    }
  );
  return response.data;
};
