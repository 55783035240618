import {
  GetAccountConnectorRequest,
  GetMicrosoftConnectorReportRequest,
  IBWTAccount,
  IBWTAccountReport,
  IBWTConnectedAccount,
  IMicrosoftConnector,
  PatchBWTAccountRequest,
  PatchUnlinkAccountConnectedItemRequest,
  PostConnectMicrosoftConnectorRequest,
} from "../../../../models";

import { api } from "../../../api";

export const getMicrosoftBwtConnectors = async (
  subscriptionId: string
): Promise<IMicrosoftConnector[]> => {
  const response = await api.get<IMicrosoftConnector[]>(
    `/${subscriptionId}/connector/microsoft/bwt`
  );
  return response.data;
};

export const connectToMicrosoftBwtConnector = async (
  subscriptionId: string,
  request: PostConnectMicrosoftConnectorRequest
): Promise<IMicrosoftConnector> => {
  const response = await api.post<IMicrosoftConnector>(
    `/${subscriptionId}/connector/microsoft/bwt/connect`,
    request
  );
  return response.data;
};

export const disconnectFromMicrosoftBwtConnector = async (
  subscriptionId: string,
  id: string
): Promise<void> => {
  await api.delete(
    `/${subscriptionId}/connector/microsoft/bwt/disconnect/${encodeURIComponent(
      id
    )}`
  );
};

export const getMicrosoftBwtAccounts = async (
  subscriptionId: string,
  request?: GetAccountConnectorRequest
): Promise<IBWTAccount> => {
  const response = await api.get<IBWTAccount>(
    `/${subscriptionId}/account-connector/link`,
    {
      params: request,
    }
  );
  return response.data;
};

export const patchMicrosoftBwtAccount = async (
  subscriptionId: string,
  accountId: string,
  request: PatchBWTAccountRequest
): Promise<void> => {
  await api.patch(`/${subscriptionId}/account-connector/${accountId}`, request);
};

export const getMicrosoftBwtConnectedAccount = async (
  subscriptionId: string,
  accountId: string,
  request?: GetAccountConnectorRequest
): Promise<IBWTConnectedAccount> => {
  const response = await api.get<IBWTConnectedAccount>(
    `/${subscriptionId}/account-connector/${accountId}/list`,
    {
      params: request,
    }
  );
  return response.data;
};

export const unlinkMicrosoftBwtConnectedAccount = async (
  subscriptionId: string,
  accountId: string,
  request: PatchUnlinkAccountConnectedItemRequest
): Promise<void> => {
  await api.patch(
    `/${subscriptionId}/account-connector/${accountId}/unlink`,
    request
  );
};

export const getMicrosoftBwtAccountReport = async (
  subscriptionId: string,
  accountId: string,
  request?: GetMicrosoftConnectorReportRequest
): Promise<IBWTAccountReport> => {
  const response = await api.get<IBWTAccountReport>(
    `/${subscriptionId}/account-connector/${accountId}/report`,
    {
      params: request,
    }
  );
  return response.data;
};
