import {
  GetTaskCommentsRequest,
  ITaskComment,
  PatchTaskCommentRequest,
  PostTaskCommentRequest,
  PutTaskCommentRequest,
} from "../../models";
import { api } from "../api";

export const getTaskComments = async (
  subscriptionId: string,
  taskId: string,
  request?: GetTaskCommentsRequest
): Promise<ITaskComment[]> => {
  const response = await api.get<ITaskComment[]>(
    `/${subscriptionId}/task/${taskId}/comment`,
    {
      params: {
        ...request,
      },
    }
  );
  return response.data;
};

export const postTaskComment = async (
  subscriptionId: string,
  taskId: string,
  request: PostTaskCommentRequest
): Promise<ITaskComment> => {
  const response = await api.post<ITaskComment>(
    `/${subscriptionId}/task/${taskId}/comment`,
    request
  );
  return response.data;
};

export const putTaskComment = async (
  subscriptionId: string,
  taskId: string,
  id: string,
  request: PutTaskCommentRequest
): Promise<void> => {
  await api.put(`/${subscriptionId}/task/${taskId}/comment/${id}`, request);
};

export const patchTaskComment = async (
  subscriptionId: string,
  taskId: string,
  id: string,
  request: PatchTaskCommentRequest
): Promise<void> => {
  await api.patch(`/${subscriptionId}/task/${taskId}/comment/${id}`, request);
};

export const deleteTaskComment = async (
  subscriptionId: string,
  taskId: string,
  id: string
): Promise<void> => {
  await api.delete(`/${subscriptionId}/task/${taskId}/comment/${id}`);
};
