import * as Yup from 'yup';

import { Box, CircularProgress, IconButton, InputAdornment, Stack, TextFieldProps, Typography } from '@mui/material';
import { FormProvider, IconButtonAnimate, Iconify, RHFTextField, useAlertSnackbar } from '@daxrm/components';
import { useState } from 'react';

import { IResponse } from '@daxrm/api';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

interface ITextFieldProps {
    value?: string;
    isMultiline?: boolean;
    onSave: (value?: string) => Promise<IResponse>;
}

export interface IEditTextFieldFormValues {
    value: string;
}

export const EditableTextField = ({
    label,
    value,
    isMultiline,
    onSave,
    ...rest
}: ITextFieldProps & Omit<TextFieldProps, 'value'>) => {
    const { onEnqueueSnackbar } = useAlertSnackbar();
    const [editingField, setEditingField] = useState(false);
    const [initialValue] = useState(value);
    const [editedValue, setEditedValue] = useState("");

    const Schema = Yup.object().shape({
        value: Yup.string().required('This is required')
    });

    const methods = useForm<IEditTextFieldFormValues>({
        resolver: yupResolver(Schema),
        defaultValues: {
            value: value || ''
        },
    });

    const onSaveHandler = async (formValues: IEditTextFieldFormValues): Promise<void> => {
        await onSave(formValues?.value).then(async (response) => {
            if (response && response.error) {
                onEnqueueSnackbar(response.error, { variant: 'error' });
            } else {
                setEditedValue(formValues?.value);
                setEditingField(false);
                reset(formValues);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    };

    const {
        handleSubmit,
        reset,
        formState: { isSubmitting, isDirty },
    } = methods;

    const onSubmitHandler = async (formValues: IEditTextFieldFormValues) => {
        await onSaveHandler(formValues);
    };

    const onCancelHandler = () => {
        setEditingField(false)
        reset();
    };

    return <>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmitHandler)}>
            {editingField ? (
                <RHFTextField
                    {...rest}
                    name='value'
                    label={label}
                    onBlur={() => handleSubmit(onSubmitHandler)}
                    fullWidth
                    disabled={isSubmitting}
                    multiline={isMultiline}
                    rows={isMultiline ? 4 : 1}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Box sx={{ position: 'relative' }}>
                                        <IconButtonAnimate type="submit" size="small" color="primary" disabled={!isDirty || isSubmitting}>
                                            <Iconify icon={'mdi:check'} />
                                        </IconButtonAnimate>
                                        {isSubmitting && (
                                            <CircularProgress
                                                size={25}
                                                sx={{
                                                    color: '#a7b1bc',
                                                    position: 'absolute',
                                                    top: 1,
                                                    left: 2,
                                                    zIndex: 1,
                                                }}
                                            />
                                        )}
                                    </Box>
                                </Box>
                                <IconButton onClick={onCancelHandler} size="small" color="error" edge="end">
                                    <Iconify icon={'mdi:window-close'} />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            ) : (
                <Stack onDoubleClick={() => setEditingField(true)} sx={{
                    my: 1,
                    maxWidth:
                        isMultiline === true
                            ? 1
                            : 250
                }}>
                    {editedValue ?
                        <Typography variant="body1" noWrap={!isMultiline}> {editedValue} </Typography> :
                        initialValue ?
                            <Typography variant="body1" noWrap={!isMultiline}> {initialValue} </Typography> :
                            <Typography variant="body1" sx={{ fontStyle: 'italic' }}>
                                Unassigned
                            </Typography>
                    }
                </Stack>
            )}
        </FormProvider>
    </>
}