import {
  GetServicesRequest,
  IPaginated,
  IService,
  PatchServiceRequest,
  PostServiceRequest,
} from "../../models";
import { api } from "../api";

export const getServices = async (
  subscriptionId: string,
  currentPage: number,
  itemsPerPage: number,
  request?: GetServicesRequest
): Promise<IPaginated<IService>> => {
  const response = await api.get<IPaginated<IService>>(
    `/${subscriptionId}/service`,
    {
      params: {
        currentPage: currentPage,
        itemsPerPage: itemsPerPage,
        ...request,
      },
    }
  );
  return response.data;
};

export const getService = async (
  subscriptionId: string,
  id: string
): Promise<IService> => {
  const response = await api.get<IService>(`/${subscriptionId}/service/${id}`);
  return response.data;
};

export const postService = async (
  subscriptionId: string,
  request: PostServiceRequest
): Promise<IService> => {
  const response = await api.post<IService>(
    `/${subscriptionId}/service`,
    request
  );
  return response.data;
};

export const patchService = async (
  subscriptionId: string,
  id: string,
  request: PatchServiceRequest
): Promise<void> => {
  await api.patch(`/${subscriptionId}/service/${id}`, request);
};
