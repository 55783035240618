import { ITaskWatcher, PostTaskWatcherRequest } from "../../models";
import { api } from "../api";

export const postTaskWatcher = async (
  subscriptionId: string,
  taskId: string,
  request: PostTaskWatcherRequest
): Promise<ITaskWatcher> => {
  const response = await api.post<ITaskWatcher>(
    `/${subscriptionId}/task/${taskId}/watcher`,
    request
  );
  return response.data;
};

export const deleteTaskWatcher = async (
  subscriptionId: string,
  taskId: string,
  id: string
): Promise<void> => {
  await api.delete(`/${subscriptionId}/task/${taskId}/watcher/${id}`);
};
