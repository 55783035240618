import {
  GetSubscriptionPaymentMethodsRequest,
  UpdateSubscriptionPaymentMethodRequest,
} from "../../models";
import { PaymentIntent, PaymentMethod } from "@stripe/stripe-js";

import { api } from "../api";

export const getSubscriptionPaymentMethod = async (
  subscriptionId: string,
  request: GetSubscriptionPaymentMethodsRequest
): Promise<PaymentMethod> => {
  const response = await api.get<PaymentMethod>(
    `/${subscriptionId}/stripe/subscription/payment-method`,
    {
      params: {
        ...request,
      },
    }
  );
  return response.data;
};

export const updateSubscriptionPaymentMethod = async (
  subscriptionId: string,
  request: UpdateSubscriptionPaymentMethodRequest
): Promise<void> => {
  const response = await api.put<void>(
    `/${subscriptionId}/stripe/subscription/payment-method`,
    request
  );
  return response.data;
};
