import {
  IAccountService,
  PostAccountServiceRequest,
  PatchAccountServiceRequest,
  PutAccountServiceRequest,
  IAccountServiceList,
  IAccountServiceDetail,
  PatchAccountServiceNoteRequest,
  PostAccountServiceNoteRequest,
  IAccountServiceNote,
} from "../../models";

import { api } from "../api";

export const getAccountServices = async (
  subscriptionId: string,
  accountId: string
): Promise<IAccountServiceList> => {
  const response = await api.get<IAccountServiceList>(
    `/${subscriptionId}/account/${accountId}/service`
  );
  return response.data;
};

export const getAccountService = async (
  subscriptionId: string,
  accountId: string,
  id: string
): Promise<IAccountServiceDetail> => {
  const response = await api.get<IAccountServiceDetail>(
    `/${subscriptionId}/account/${accountId}/service/${id}`
  );
  return response.data;
};

export const postAccountService = async (
  subscriptionId: string,
  accountId: string,
  request: PostAccountServiceRequest
): Promise<IAccountService> => {
  const response = await api.post<IAccountService>(
    `/${subscriptionId}/account/${accountId}/service`,
    request
  );
  return response.data;
};

export const putAccountService = async (
  subscriptionId: string,
  accountId: string,
  id: string,
  request: PutAccountServiceRequest
): Promise<void> => {
  await api.put(
    `/${subscriptionId}/account/${accountId}/service/${id}`,
    request
  );
};

export const patchAccountService = async (
  subscriptionId: string,
  accountId: string,
  id: string,
  request: PatchAccountServiceRequest
): Promise<void> => {
  await api.patch(
    `/${subscriptionId}/account/${accountId}/service/${id}`,
    request
  );
};

export const postAccountServiceNote = async (
  subscriptionId: string,
  accountServiceId: string,
  request: PostAccountServiceNoteRequest
): Promise<IAccountServiceNote> => {
  const response = await api.post<IAccountServiceNote>(
    `/${subscriptionId}/account-service/${accountServiceId}/note`,
    request
  );
  return response.data;
};

export const patchAccountServiceNote = async (
  subscriptionId: string,
  accountServiceId: string,
  id: string,
  request: PatchAccountServiceNoteRequest
): Promise<void> => {
  await api.patch(
    `/${subscriptionId}/account-service/${accountServiceId}/note/${id}`,
    request
  );
};

export const deleteAccountServiceNote = async (
  subscriptionId: string,
  accountServiceId: string,
  id: string
): Promise<void> => {
  await api.delete(
    `/${subscriptionId}/account-service/${accountServiceId}/note/${id}`
  );
};

export const deleteAccountService = async (
  subscriptionId: string,
  accountId: string,
  id: string
): Promise<void> => {
  await api.delete(`/${subscriptionId}/account/${accountId}/service/${id}`);
};
