import {
  GetRoleClaimsRequest,
  IRoleClaim,
  PatchRoleClaimsRequest,
} from "../../models";
import { api } from "../api";

export const getRoleClaims = async (
  subscriptionId: string,
  roleId: string,
  request?: GetRoleClaimsRequest
): Promise<IRoleClaim[]> => {
  const response = await api.get<IRoleClaim[]>(
    `/${subscriptionId}/role/${roleId}/claims`,
    {
      params: {
        ...request,
      },
    }
  );
  return response.data;
};

export const patchRoleClaim = async (
  subscriptionId: string,
  roleId: string,
  request: PatchRoleClaimsRequest
): Promise<void> => {
  await api.patch(`/${subscriptionId}/role/${roleId}/claim`, request);
};
