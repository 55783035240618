import { ITaskProject, PostTaskProjectRequest } from "../../models";
import { api } from "../api";

export const postTaskProject = async (
  subscriptionId: string,
  taskId: string,
  request: PostTaskProjectRequest
): Promise<ITaskProject> => {
  const response = await api.post<ITaskProject>(
    `/${subscriptionId}/task/${taskId}/project`,
    request
  );
  return response.data;
};

export const deleteTaskProject = async (
  subscriptionId: string,
  taskId: string,
  id: string
): Promise<void> => {
  await api.delete(`/${subscriptionId}/task/${taskId}/project/${id}`);
};
