import { Container, SxProps } from "@mui/material"
import { ReactNode } from "react";

export type WidthType = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

interface ISidebarFormContentProps {
    maxWidthValue?: WidthType;
    children: ReactNode;
    sx?: SxProps
}

export const SidebarFormContent = ({ maxWidthValue, children, sx }: ISidebarFormContentProps) => <Container
    sx={{
        py: 2,
        ...sx,
    }}
    maxWidth={(maxWidthValue) ? maxWidthValue : "md"}
>
    {
        children
    }
</Container>