import {
  GetRolesRequest,
  IPaginated,
  IRole,
  PatchRoleRequest,
  PostRoleRequest,
} from "../../models";
import { api } from "../api";

export const getRoles = async (
  subscriptionId: string,
  currentPage: number,
  itemsPerPage: number,
  request?: GetRolesRequest
): Promise<IPaginated<IRole>> => {
  const response = await api.get<IPaginated<IRole>>(`/${subscriptionId}/role`, {
    params: {
      currentPage: currentPage,
      itemsPerPage: itemsPerPage,
      ...request,
    },
  });
  return response.data;
};

export const getRole = async (
  subscriptionId: string,
  id: string
): Promise<IRole> => {
  const response = await api.get<IRole>(`/${subscriptionId}/role/${id}`);
  return response.data;
};

export const postRole = async (
  subscriptionId: string,
  request: PostRoleRequest
): Promise<IRole> => {
  const response = await api.post<IRole>(`/${subscriptionId}/role`, request);
  return response.data;
};

export const patchRole = async (
  subscriptionId: string,
  id: string,
  request: PatchRoleRequest
): Promise<void> => {
  await api.patch(`/${subscriptionId}/role/${id}`, request);
};
