import {
  IGettingStarted
} from "../../models";
import { api } from "../api";

export const getOnboardingActivity = async (
  subscriptionId: string
): Promise<IGettingStarted[]> => {
  const response = await api.get<IGettingStarted[]>(
    `/${subscriptionId}/onboarding-activity`
  );
  return response.data;
};