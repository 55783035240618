import {
  GetServiceLookupRequest,
  IPaginated,
  IServiceLookup,
} from "../../models";

import { api } from "../api";

export const getServiceLookup = async (
  subscriptionId: string,
  currentPage: number,
  itemsPerPage: number,
  request?: GetServiceLookupRequest
): Promise<IPaginated<IServiceLookup>> => {
  const response = await api.get<IPaginated<IServiceLookup>>(
    `/${subscriptionId}/service-lookup`,
    {
      params: {
        currentPage: currentPage,
        itemsPerPage: itemsPerPage,
        ...request,
      },
    }
  );
  return response.data;
};
