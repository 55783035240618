import { GetTimelineRequest, ITimeline } from "../../models";

import { api } from "../api";
import { sleep } from "@daxrm/utils";

let mockTimeline: ITimeline[] = [
  {
    historyId: "ec694f30-3881-11ed-959d-066e95b2b4ee",
    actionType: "UPDATE",
    label: "Company Status Type",
    moduleName: "COMPANY",
    subModuleName: null,
    description: "360 Degrees Property Inspections Pty Ltd",
    isHtml: false,
    oldValue: "RECONCILIATION",
    newValue: "ACTIVE",
    auditDateTime: "2022-09-20 11:16:58",
    auditDate: "2022-09-20",
    auditTime: "11:16 AM",
    actionBy: "Suresh Kshirsagar",
  },
  {
    historyId: "1a5f36b1-3881-11ed-959d-066e95b2b4ee",
    actionType: "UPDATE",
    label: "Company Name",
    moduleName: "COMPANY",
    subModuleName: null,
    description: "360 Degrees Property Inspections Pty Ltd",
    isHtml: false,
    oldValue: "360 Degrees Property Inspections",
    newValue: "360 Degrees Property Inspections Pty Ltd",
    auditDateTime: "2022-09-20 11:11:06",
    auditDate: "2022-09-20",
    auditTime: "11:11 AM",
    actionBy: "Rashesh Shah",
  },
  {
    historyId: "1a5f3f18-3881-11ed-959d-066e95b2b4ee",
    actionType: "UPDATE",
    label: "Company Status Type",
    moduleName: "COMPANY",
    subModuleName: null,
    description: "360 Degrees Property Inspections Pty Ltd",
    isHtml: false,
    oldValue: "ACTIVE",
    newValue: "RECONCILIATION",
    auditDateTime: "2022-09-20 11:11:06",
    auditDate: "2022-09-20",
    auditTime: "11:11 AM",
    actionBy: "Rashesh Shah",
  },
  {
    historyId: "972db7a6-0702-11ed-9577-066e95b2b4ee",
    actionType: "UPDATE",
    label: "Company Business Name",
    moduleName: "COMPANY",
    subModuleName: null,
    description: "360 Degrees Property Inspections",
    isHtml: false,
    oldValue: "",
    newValue: "360 Degrees Property Inspections",
    auditDateTime: "2022-07-19 11:32:02",
    auditDate: "2022-07-19",
    auditTime: "11:32 AM",
    actionBy: "Suresh Kshirsagar",
  },
  {
    historyId: "78dfad39-4e4b-11ec-a6a5-06e0cf92182e",
    actionType: "UPDATE",
    label: "Company Status Type",
    moduleName: "COMPANY",
    subModuleName: null,
    description: "360 Degrees Property Inspections",
    isHtml: false,
    oldValue: "RECONCILIATION",
    newValue: "ACTIVE",
    auditDateTime: "2021-11-26 10:57:39",
    auditDate: "2021-11-26",
    auditTime: "10:57 AM",
    actionBy: "Rashesh Shah",
  },
  {
    historyId: "a7b5239f-357e-11ec-a6a5-06e0cf92182e",
    actionType: "UPDATE",
    label: "Company Status Type",
    moduleName: "COMPANY",
    subModuleName: null,
    description: "360 Degrees Property Inspections",
    isHtml: false,
    oldValue: "ACTIVE",
    newValue: "RECONCILIATION",
    auditDateTime: "2021-10-25 21:31:03",
    auditDate: "2021-10-25",
    auditTime: "9:31 PM",
    actionBy: "Rashesh Shah",
  },
  {
    historyId: "7a3f2915-357e-11ec-a6a5-06e0cf92182e",
    actionType: "UPDATE",
    label: "Company Status Type",
    moduleName: "COMPANY",
    subModuleName: null,
    description: "360 Degrees Property Inspections",
    isHtml: false,
    oldValue: "RECONCILIATION",
    newValue: "ACTIVE",
    auditDateTime: "2021-10-25 21:29:47",
    auditDate: "2021-10-25",
    auditTime: "9:29 PM",
    actionBy: "Rashesh Shah",
  },
  {
    historyId: "f51c05bc-357d-11ec-a6a5-06e0cf92182e",
    actionType: "UPDATE",
    label: "Company Status Type",
    moduleName: "COMPANY",
    subModuleName: null,
    description: "360 Degrees Property Inspections",
    isHtml: false,
    oldValue: "ACTIVE",
    newValue: "RECONCILIATION",
    auditDateTime: "2021-10-25 21:26:04",
    auditDate: "2021-10-25",
    auditTime: "9:26 PM",
    actionBy: "Rashesh Shah",
  },
  {
    historyId: "741855e6-887f-11eb-8efd-0658e5aba3ba",
    actionType: "ADD",
    label: "Company",
    moduleName: "COMPANY",
    subModuleName: null,
    description: "360 Degrees Property Inspections",
    isHtml: false,
    oldValue: null,
    newValue: "360 Degrees Property Inspections",
    auditDateTime: "2021-01-01 11:00:00",
    auditDate: "2021-01-01",
    auditTime: "11:00 AM",
    actionBy: "Rashesh Shah",
  },
];

export const getTimelines = async (
  subscriptionId: string,
  sourceId: string,
  request?: GetTimelineRequest
): Promise<ITimeline[]> => {
  // const response = await api.get<ITimeline[]>(
  //   `/${subscriptionId}/company/${companyId}/timeline`,
  //   {
  //     params: {
  //       ...request,
  //     },
  //   }
  // );
  // return response.data;
  await sleep(150);
  return mockTimeline;
};
