import { GetReportsRequest, IPaginated, IReport, PatchReportAccountRequest, PostReportAccountCloneRequest, PostReportAccountRequest, PostReportBrandingLogoRequest } from "../../models";
import { api } from "../api";

export const getAccountReports = async (
  subscriptionId: string,
  accountId: string,
  currentPage: number,
  itemsPerPage: number,
  request?: GetReportsRequest
): Promise<IPaginated<IReport>> => {
  const response = await api.get<IPaginated<IReport>>(
    `/${subscriptionId}/report-account/${accountId}`,
    {
      params: {
        currentPage: currentPage,
        itemsPerPage: itemsPerPage,
        ...request,
      },
    }
  );
  return response.data;
};

export const getAccountReport = async (
  subscriptionId: string,
  accountId: string,
  id: string
): Promise<IReport> => {
  const response = await api.get<IReport>(`/${subscriptionId}/report-account/${accountId}/report/${id}`);
  return response.data;
};

export const postAccountReport = async (
  subscriptionId: string,
  accountId: string,
  request: PostReportAccountRequest
): Promise<IReport> => {
  const response = await api.post<IReport>(
    `/${subscriptionId}/report-account/${accountId}`,
    request
  );
  return response.data;
};

export const patchAccountReport = async (
  subscriptionId: string,
  accountId: string,
  id: string,
  request: PatchReportAccountRequest
): Promise<void> => {
  await api.patch(`/${subscriptionId}/report-account/${accountId}/report/${id}`, request);
};

export const deleteAccountReport = async (
  subscriptionId: string,
  accountId: string,
  id: string
): Promise<void> => {
  await api.delete(`/${subscriptionId}/report-account/${accountId}/report/${id}`);
};

export const cloneAccountReport = async (
  subscriptionId: string,
  request: PostReportAccountCloneRequest
): Promise<void> => {
  const response = await api.post<void>(
    `/${subscriptionId}/report-account/${request.accountId}/clone`,
    request
  );
  return response.data;
};

export const uploadReportTemplateBrandingLogo = async (
  subscriptionId: string,
  reportId: string,
  request: PostReportBrandingLogoRequest
): Promise<void> => {
  const formData = new FormData();
  formData.append("file", request.file);
  const response = await api.post(
    `/${subscriptionId}/report-template/${reportId}/brand-logo`,
    formData,
    {
      headers: {
        "Content-type": "multipart/form-date",
      },
    }
  );
  return response.data;
};

export const uploadReportAccountBrandingLogo = async (
  subscriptionId: string,
  accountId: string,
  reportId: string,
  request: PostReportBrandingLogoRequest
): Promise<void> => {
  const formData = new FormData();
  formData.append("file", request.file);
  const response = await api.post(
    `/${subscriptionId}/report-account/${accountId}/brand-logo/${reportId}`,
    formData,
    {
      headers: {
        "Content-type": "multipart/form-date",
      },
    }
  );
  return response.data;
};