import {
  GetProjectTemplatesLookupRequest,
  IPaginated,
  IProjectTemplateLookup,
} from "../../models";

import { api } from "../api";

export const getProjectTemplatesLookup = async (
  subscriptionId: string,
  currentPage: number,
  itemsPerPage: number,
  request?: GetProjectTemplatesLookupRequest
): Promise<IPaginated<IProjectTemplateLookup>> => {
  const response = await api.get<IPaginated<IProjectTemplateLookup>>(
    `/${subscriptionId}/project-template-lookup`,
    {
      params: {
        currentPage: currentPage,
        itemsPerPage: itemsPerPage,
        ...request,
      },
    }
  );
  return response.data;
};
