import { CardProps } from "@mui/material";
import merge from 'lodash/merge';
import ReactApexChart from 'react-apexcharts';
import { fCurrency, fFloatNumber, fNumber, fPercent, fPercentSingleDigit, fShortenNumber, secondsToDhms } from "@daxrm/utils";
import { useTheme, styled } from '@mui/material/styles';
import { BaseOptionChart } from "./chart-setting";

// ----------------------------------------------------------------------

const CHART_HEIGHT = 320;
const LEGEND_HEIGHT = 68;

const ChartWrapperStyle = styled('div')(({ theme }) => ({
    height: CHART_HEIGHT,
    marginTop: theme.spacing(1),
    '& .apexcharts-canvas svg': { height: CHART_HEIGHT },
    '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
        overflow: 'visible',
    },
    '& .apexcharts-legend': {
        height: LEGEND_HEIGHT,
        alignContent: 'center',
        position: 'relative !important' as 'relative',
        borderTop: `solid 1px ${theme.palette.divider}`,
        top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
    },
}));

// ----------------------------------------------------------------------

interface ReactApexDonutChartProps extends CardProps {
    chartData: {
        label: string;
        value: number;
    }[];
    chartColors?: string[];
    metricType?: string;
    total?: number | string;
    isShowLegend?: boolean
}

export const RADonutChart = ({
    chartData,
    chartColors,
    metricType,
    total,
    isShowLegend = true
}: ReactApexDonutChartProps) => {
    const theme = useTheme();
    const chartLabels = chartData.map((i) => i.label);
    const chartSeries = chartData.map((i) => i.value);

    const chartOptions = merge(BaseOptionChart(), {
        colors: chartColors,
        labels: chartLabels,
        stroke: { show: false, colors: [theme.palette.background.paper] },
        legend: {
            show: isShowLegend,
            floating: true,
            horizontalAlign: 'center',
            formatter: (val: string, opts: { w: { globals: { series: { [x: string]: string; }; }; }; seriesIndex: string | number; }) => {
                let finalVal = '';
                if (val == null) {
                    finalVal = "";
                }
                if (val.length <= 25) {
                    finalVal = val;
                } else {
                    val = val.substring(0, 25);
                    finalVal = val + "...";
                }

                if (metricType && metricType === 'userEngagementDuration') {
                    return finalVal + " - " + secondsToDhms(opts.w.globals.series[opts.seriesIndex]);
                } else if (metricType && metricType === 'gsc_impressions') {
                    return finalVal + " - " + fShortenNumber(opts.w.globals.series[opts.seriesIndex]);
                } else if (metricType && metricType === 'gsc_position') {
                    return finalVal + " - " + fFloatNumber(opts.w.globals.series[opts.seriesIndex]);
                } else if (metricType && metricType === 'gsc_ctr') {
                    return finalVal + " - " + fPercent(Number(opts.w.globals.series[opts.seriesIndex]));
                } else if (metricType && metricType === 'fads_impressions') {
                    return finalVal + " - " + fShortenNumber(Number(opts.w.globals.series[opts.seriesIndex]));
                } else if (metricType && metricType === 'fads_ctr') {
                    return finalVal + " - " + fPercent(Number(opts.w.globals.series[opts.seriesIndex]));
                } else if (metricType && metricType === 'fads_cpc') {
                    return finalVal + " - " + fCurrency(Number(opts.w.globals.series[opts.seriesIndex]));
                } else if (metricType && metricType === 'bwt_crawl_errors') {
                    return finalVal + " - " + fShortenNumber(opts.w.globals.series[opts.seriesIndex]);
                } else if (metricType && (metricType === 'engagementRate' || metricType === 'userKeyEventRate')) {
                    return finalVal + " - " + fPercent(Number(opts.w.globals.series[opts.seriesIndex]) * 100);
                } else if (metricType && (metricType === 'techDeviceCategory')) {
                    return finalVal + " - " + fPercentSingleDigit(Number(opts.w.globals.series[opts.seriesIndex]));
                } else {
                    return finalVal + " - " + opts.w.globals.series[opts.seriesIndex]
                }
            }
        },
        tooltip: {
            fillSeriesColor: false,
            y: {
                formatter: (seriesName: string) => {
                    if (metricType && (metricType === 'engagementRate' || metricType === 'userKeyEventRate')) {
                        return fPercent(Number(seriesName) * 100)
                    } else if (metricType && metricType === 'techDeviceCategory') {
                        return fPercentSingleDigit(Number(seriesName))
                    } else {
                        return fNumber(seriesName)
                    }
                },
                title: {
                    formatter: (seriesName: string) => `${seriesName}`,
                },
            },
        },
        plotOptions: {
            pie: {
                donut: {
                    size: '60%',
                    labels: {
                        value: {
                            formatter: (val: number | string) => {
                                if (metricType && (metricType === 'engagementRate' || metricType === 'userKeyEventRate')) {
                                    return fPercent(Number(val) * 100)
                                } else if (metricType && metricType === 'techDeviceCategory') {
                                    return fPercentSingleDigit(Number(val))
                                } else {
                                    return fNumber(val)
                                }
                            }
                        },
                        total: {
                            show: (metricType && (metricType === 'techDeviceCategory')) ? false : true,
                            formatter: (w: { globals: { seriesTotals: number[] } }) => {
                                if ((metricType && (metricType === 'engagementRate' || metricType === 'userKeyEventRate'))) {
                                    return fPercent(Number(total))
                                } else if (metricType && metricType === 'techDeviceCategory') {
                                    const sum = w.globals.seriesTotals.reduce((a, b) => a + Number(b), 0);
                                    return fPercentSingleDigit(Number(sum > 100 ? 100 : sum))
                                } else {
                                    const sum = w.globals.seriesTotals.reduce((a, b) => a + b, 0);
                                    return fNumber(sum);
                                }
                            },
                        },
                    },
                },
            },
        },
    });

    return (
        <ChartWrapperStyle dir="ltr">
            <ReactApexChart type="donut" series={chartSeries} options={chartOptions} height={250} />
        </ChartWrapperStyle>
    );
}