import {
  GetAccountConnectorRequest,
  GetGoogleGadsConnectorAuthUrlRequest,
  IGADSConnectedAccount,
  IGADSMccAccount,
  IGADSSubAccount,
  IGoogleConnector,
  IGoogleConnectorAuthUrlResponse,
  PatchGADSAccountRequest,
  PatchUnlinkAccountConnectedItemRequest,
  PostConnectGoogleConnectorRequest,
} from "../../../../models";

import { api } from "../../../api";

export const getGoogleGadsConnectorAuthUrl = async (
  subscriptionId: string,
  request?: GetGoogleGadsConnectorAuthUrlRequest
): Promise<IGoogleConnectorAuthUrlResponse> => {
  const response = await api.get<IGoogleConnectorAuthUrlResponse>(
    `/${subscriptionId}/connector/google/gads/auth-url`,
    {
      params: request,
    }
  );
  return response.data;
};

export const getGoogleGadsConnectors = async (
  subscriptionId: string
): Promise<IGoogleConnector[]> => {
  const response = await api.get<IGoogleConnector[]>(
    `/${subscriptionId}/connector/google/gads`
  );
  return response.data;
};

export const connectToGoogleGadsConnector = async (
  subscriptionId: string,
  request: PostConnectGoogleConnectorRequest
): Promise<IGoogleConnector> => {
  const response = await api.post<IGoogleConnector>(
    `/${subscriptionId}/connector/google/gads/connect`,
    request
  );
  return response.data;
};

export const disconnectFromGoogleGadsConnector = async (
  subscriptionId: string,
  id: string
): Promise<void> => {
  await api.delete(
    `/${subscriptionId}/connector/google/gads/disconnect/${encodeURIComponent(
      id
    )}`
  );
};

export const getGoogleGadsMccAccounts = async (
  subscriptionId: string,
  request?: GetAccountConnectorRequest
): Promise<IGADSMccAccount> => {
  const response = await api.get<IGADSMccAccount>(
    `/${subscriptionId}/account-connector/link`,
    {
      params: request,
    }
  );
  return response.data;
};

export const getGoogleGADSSubAccounts = async (
  subscriptionId: string,
  request?: GetAccountConnectorRequest
): Promise<IGADSSubAccount> => {
  const response = await api.get<IGADSSubAccount>(
    `/${subscriptionId}/account-connector/link`,
    {
      params: request,
    }
  );
  return response.data;
};

export const patchGADSAccount = async (
  subscriptionId: string,
  accountId: string,
  request: PatchGADSAccountRequest
): Promise<void> => {
  await api.patch(`/${subscriptionId}/account-connector/${accountId}`, request);
};

export const getGADSConnectedAccount = async (
  subscriptionId: string,
  accountId: string,
  request?: GetAccountConnectorRequest
): Promise<IGADSConnectedAccount> => {
  const response = await api.get<IGADSConnectedAccount>(
    `/${subscriptionId}/account-connector/${accountId}/list`,
    {
      params: request,
    }
  );
  return response.data;
};

export const unlinkGADSConnectedLocation = async (
  subscriptionId: string,
  accountId: string,
  request: PatchUnlinkAccountConnectedItemRequest
): Promise<void> => {
  await api.patch(
    `/${subscriptionId}/account-connector/${accountId}/unlink`,
    request
  );
};
