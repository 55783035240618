import {
  GetProjectTemplatesRequest,
  IPaginated,
  IProjectTemplate,
  PatchProjectTemplateRequest,
  PostProjectTemplateRequest,
} from "../../models";

import { api } from "../api";

export const getProjectTemplates = async (
  subscriptionId: string,
  currentPage: number,
  itemsPerPage: number,
  request?: GetProjectTemplatesRequest
): Promise<IPaginated<IProjectTemplate>> => {
  const response = await api.get<IPaginated<IProjectTemplate>>(
    `/${subscriptionId}/project-template`,
    {
      params: {
        currentPage: currentPage,
        itemsPerPage: itemsPerPage,
        ...request,
      },
    }
  );
  return response.data;
};

export const getProjectTemplate = async (
  subscriptionId: string,
  id: string
): Promise<IProjectTemplate> => {
  const response = await api.get<IProjectTemplate>(
    `/${subscriptionId}/project-template/${id}`
  );
  return response.data;
};

export const postProjectTemplate = async (
  subscriptionId: string,
  request: PostProjectTemplateRequest
): Promise<IProjectTemplate> => {
  const response = await api.post<IProjectTemplate>(
    `/${subscriptionId}/project-template`,
    request
  );
  return response.data;
};

export const patchProjectTemplate = async (
  subscriptionId: string,
  id: string,
  request: PatchProjectTemplateRequest
): Promise<void> => {
  await api.patch(`/${subscriptionId}/project-template/${id}`, request);
};
