import { BoxProps } from '@mui/material';
import Image from './Image';
import { Link as RouterLink } from 'react-router-dom';

type LogoSize = "default" | "small" | "medium";

const logoUrls: { [key in LogoSize]: string } = {
  "default": '/logo/logo-big.png',
  "small": "/logo/logo-small.png",
  "medium": '/logo/logo-medium.png'
}

interface Props extends BoxProps {
  disabledLink?: boolean;
  size?: LogoSize;
}

const LogoImage = ({ sx, size= 'default' }: Omit<Props, 'disabledLink'>) => (
  <Image
    sx={sx}
    visibleByDefault
    disabledEffect
    src={logoUrls[size]}
    alt='logo'
  />
);

export default function Logo({ disabledLink = false, size, sx }: Props) {
  if (disabledLink) {
    return <LogoImage size={size} sx={sx} />;
  }

  return <RouterLink to="/"><LogoImage size={size} sx={sx} /></RouterLink>;
}