import {
  AuthTokensResponse,
  EmailVerifyResponse,
  ForgotPasswordResponse,
  GoogleAuthVerificationResponse,
  IForgotPasswordRequest,
  IMe,
  IResetPasswordFirebaseRequest,
  ISignUpRequest,
  ISignUpWithGoogleRequest,
  ISignUpWithMsftRequest,
  IVerifyEmailFirebaseRequest,
  ResetPasswordFirebaseResponse,
} from "../models";
import { api, validatorApi } from "./api";

export const signInWithPassword = async (
  email: string,
  password: string,
  rememberMe: boolean
): Promise<AuthTokensResponse> => {
  const response = await api.post("/auth/signin", {
    email: email,
    password: password,
    rememberMe: rememberMe,
  });
  return response.data;
};

export const signUpWithPassword = async (
  request: ISignUpRequest
): Promise<boolean> => {
  const response = await api.post("/auth/signup", {
    email: request.email,
    password: request.password,
    firstName: request.firstName,
    lastName: request.lastName,
    companyName: request.companyName,
    phone: {
      callingCode: request.phone.callingCode,
      number: request.phone.number,
    },
    countryCode: request.countryCode,
    timezoneCode: request.timezoneCode,
    postCode: request.postCode,
    ipAddress: request.ipAddress,
    userAgent: {
      userAgent: request.userAgent.userAgent,
    },
  });
  return response.status === 201;
};

export const signUpWithGoogle = async (
  request: ISignUpWithGoogleRequest
): Promise<AuthTokensResponse> => {
  const response = await api.post("/auth/google/signup", {
    idToken: request.idToken,
    firstName: request.firstName,
    lastName: request.lastName,
    companyName: request.companyName,
    phone: {
      callingCode: request.phone.callingCode,
      number: request.phone.number,
    },
    countryCode: request.countryCode,
    email: request.email,
    timezoneCode: request.timezoneCode,
    postCode: request.postCode,
    ipAddress: request.ipAddress,
    userAgent: {
      userAgent: request.userAgent.userAgent,
    },
  });

  return response.data;
};

export const signInWithGoogle = async (
  idToken: string,
  rememberMe: boolean
): Promise<AuthTokensResponse> => {
  const response = await api.post("/auth/google/signin", {
    idToken: idToken,
    rememberMe: rememberMe,
  });
  return response.data;
};

export const refreshAccessTokens = async (
  refreshToken: string
): Promise<AuthTokensResponse> => {
  const response = await api.post(
    "/auth/refresh",
    {},
    {
      headers: {
        Authorization: `Bearer ${refreshToken}`,
      },
    }
  );
  return response.data;
};

export const verifyGoogleAuth = async (
  idToken: string
): Promise<GoogleAuthVerificationResponse> => {
  const response = await api.post("/auth/google/verify", {
    idToken: idToken,
  });
  return response.data;
};

export const forgotPassword = async (
  request: IForgotPasswordRequest
): Promise<ForgotPasswordResponse> => {
  const response = await api.post("/auth/forgot-password", request);
  return response.data;
};

export const verifyEmailFirebase = async (
  request: IVerifyEmailFirebaseRequest
): Promise<EmailVerifyResponse> => {
  const response = await api.post("/auth/email-verify", request);
  return response.data;
};

export const resetPasswordFirebase = async (
  request: IResetPasswordFirebaseRequest
): Promise<ResetPasswordFirebaseResponse> => {
  const response = await api.post("/auth/password-reset", request);
  return response.data;
};

export const verifyGoogleConnectorAuth = async (
  clientId: string,
  token: string
): Promise<any> => {
  const response = await api.post("/auth/google/verify-connector-auth", {
    clientId: clientId,
    token: token,
  });

  return response.data;
};

export const getAuthUrl = async (): Promise<any> => {
  return api.get("/auth/google/auth-url");
};

export const getValidatorApi = async (ipAddress: string): Promise<any> => {
  const response = await validatorApi.get<any>("", {
    params: {
      ...{ ip_address: ipAddress },
    },
  });
  return response.data;
};

export const verifyMsftAuth = async (
  email: string,
  userId: string
): Promise<GoogleAuthVerificationResponse> => {
  const response = await api.post("/auth/msft/verify", {
    email: email,
    userId: userId,
  });
  return response.data;
};

export const signUpWithMsft = async (
  request: ISignUpWithMsftRequest
): Promise<AuthTokensResponse> => {
  const response = await api.post("/auth/msft/signup", {
    userId: request.userId,
    firstName: request.firstName,
    lastName: request.lastName,
    companyName: request.companyName,
    phone: {
      callingCode: request.phone.callingCode,
      number: request.phone.number,
    },
    countryCode: request.countryCode,
    email: request.email,
    timezoneCode: request.timezoneCode,
    postCode: request.postCode,
    ipAddress: request.ipAddress,
    userAgent: {
      userAgent: request.userAgent.userAgent,
    },
  });

  return response.data;
};

export const signInWithMsft = async (
  userId: string,
  rememberMe: boolean
): Promise<AuthTokensResponse> => {
  const response = await api.post("/auth/msft/signin", {
    userId: userId,
    rememberMe: rememberMe,
  });
  return response.data;
};
