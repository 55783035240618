import { GetAuditsRequest, IAudit, IPaginated } from "../../models";
import { api } from "../api";

export const getAudits = async (
  subscriptionId: string,
  auditOn: string,
  auditId: string,
  currentPage: number,
  itemsPerPage: number,
  request?: GetAuditsRequest
): Promise<IPaginated<IAudit>> => {
  const response = await api.get<IPaginated<IAudit>>(
    `/${subscriptionId}/audit/${auditOn}/${auditId}`,
    {
      params: {
        currentPage: currentPage,
        itemsPerPage: itemsPerPage,
        ...request,
      },
    }
  );
  return response.data;
};

export const getSubAudits = async (
  subscriptionId: string,
  auditOn: string,
  auditId: string,
  subAuditOn: string,
  currentPage: number,
  itemsPerPage: number,
  request?: GetAuditsRequest
): Promise<IPaginated<IAudit>> => {
  const response = await api.get<IPaginated<IAudit>>(
    `/${subscriptionId}/audit/${auditOn}/${auditId}/${subAuditOn}`,
    {
      params: {
        currentPage: currentPage,
        itemsPerPage: itemsPerPage,
        ...request,
      },
    }
  );
  return response.data;
};
