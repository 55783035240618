import { CardProps } from "@mui/material";
import merge from 'lodash/merge';
import ReactApexChart from 'react-apexcharts';
import { fNumber } from "@daxrm/utils";
import { useTheme, styled } from '@mui/material/styles';
import { BaseOptionChart } from "./chart-setting";
import sumBy from 'lodash/sumBy';

interface ReactApexDonutChartProps extends CardProps {
    chartData: {
        label: string;
        value: number;
    }[];
    chartColors?: string[];
    chartWidth?: number;
    chartHeight?: number;
}

export const RARadialBarChart = ({
    chartData,
    chartColors,
    chartWidth,
    chartHeight
}: ReactApexDonutChartProps) => {
    const theme = useTheme();

    const total = sumBy(chartData, 'value');

    const chartSeries = (chartData.filter((i) => i.label !== '')[0].value / total) * 100;

    const chartColor = chartColors || [theme.palette.primary.light, theme.palette.primary.main];

    const chartOptions = merge(BaseOptionChart(), {
        legend: {
            show: false,
        },
        grid: {
            padding: { top: -32, bottom: -32 },
        },
        fill: {
            type: 'gradient',
            gradient: {
                colorStops: [chartColor].map((colr) => [
                    { offset: 0, color: colr[0] },
                    { offset: 100, color: colr[1] },
                ]),
            },
        },
        tooltip: {
            fillSeriesColor: false,
            y: {
                formatter: (seriesName: string) => fNumber(seriesName),
                title: {
                    formatter: (seriesName: string) => `${seriesName}`,
                },
            },
        },
        plotOptions: {
            radialBar: {
                hollow: { size: '50%' },
                dataLabels: {
                    name: { offsetY: -10 },
                    value: { offsetY: 1 },
                    total: {
                        label: 'Total',
                        formatter: () => fNumber(total),
                    },
                },
            },
        },
        // ...options,
    });

    return (
        <ReactApexChart type="radialBar" series={[chartSeries]} options={chartOptions} height={chartHeight || 250} width={chartWidth || 250} />
    );
}