import {
  IAccountNote,
  GetAccountNotesRequest,
  PostAccountNoteRequest,
  PatchAccountNoteRequest,
  PutAccountNoteRequest,
} from "../../models";

import { api } from "../api";

export const getAccountNotes = async (
  subscriptionId: string,
  accountId: string,
  request?: GetAccountNotesRequest
): Promise<IAccountNote[]> => {
  const response = await api.get<IAccountNote[]>(
    `/${subscriptionId}/account/${accountId}/note`,
    {
      params: {
        ...request,
      },
    }
  );
  return response.data;
};

export const postAccountNote = async (
  subscriptionId: string,
  accountId: string,
  request: PostAccountNoteRequest
): Promise<IAccountNote> => {
  const response = await api.post<IAccountNote>(
    `/${subscriptionId}/account/${accountId}/note`,
    request
  );
  return response.data;
};

export const putAccountNote = async (
  subscriptionId: string,
  accountId: string,
  id: string,
  request: PutAccountNoteRequest
): Promise<void> => {
  await api.put(`/${subscriptionId}/account/${accountId}/note/${id}`, request);
};

export const patchAccountNote = async (
  subscriptionId: string,
  accountId: string,
  id: string,
  request: PatchAccountNoteRequest
): Promise<void> => {
  await api.patch(
    `/${subscriptionId}/account/${accountId}/note/${id}`,
    request
  );
};

export const deleteAccountNote = async (
  subscriptionId: string,
  accountId: string,
  id: string
): Promise<void> => {
  await api.delete(`/${subscriptionId}/account/${accountId}/note/${id}`);
};
