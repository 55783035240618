import {
  GetProjectTemplateSectionRequest,
  ISection,
  PatchProjectTemplateRequest,
  PatchProjectTemplateSectionSequenceRequest,
  PostProjectTemplateRequest,
} from "../../models";

import { api } from "../api";

export const getProjectTemplateSections = async (
  subscriptionId: string,
  projectTemplateId: string,
  request?: GetProjectTemplateSectionRequest
): Promise<ISection[]> => {
  const response = await api.get<ISection[]>(
    `/${subscriptionId}/project-template/${projectTemplateId}/section`,
    {
      params: {
        ...request,
      },
    }
  );
  return response.data;
};

export const getProjectTemplateSection = async (
  subscriptionId: string,
  projectTemplateId: string,
  id: string
): Promise<ISection> => {
  const response = await api.get<ISection>(
    `/${subscriptionId}/project-template/${projectTemplateId}/section/${id}`
  );
  return response.data;
};

export const postProjectTemplateSection = async (
  subscriptionId: string,
  projectTemplateId: string,
  request: PostProjectTemplateRequest
): Promise<ISection> => {
  const response = await api.post<ISection>(
    `/${subscriptionId}/project-template/${projectTemplateId}/section`,
    request
  );
  return response.data;
};

export const patchProjectTemplateSection = async (
  subscriptionId: string,
  projectTemplateId: string,
  id: string,
  request: PatchProjectTemplateRequest
): Promise<void> => {
  await api.patch(
    `/${subscriptionId}/project-template/${projectTemplateId}/section/${id}`,
    request
  );
};

export const patchProjectTemplateSectionSequence = async (
  subscriptionId: string,
  projectTemplateId: string,
  request: PatchProjectTemplateSectionSequenceRequest
): Promise<void> => {
  await api.patch(
    `/${subscriptionId}/project-template/${projectTemplateId}/sequence`,
    request
  );
};

export const deleteProjectTemplateSection = async (
  subscriptionId: string,
  projectTemplateId: string,
  id: string
): Promise<void> => {
  await api.delete(
    `/${subscriptionId}/project-template/${projectTemplateId}/section/${id}`
  );
};
