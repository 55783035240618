import { OptionsObject, SnackbarMessage, useSnackbar } from "notistack";

export const useAlertSnackbar = () => {
  const { enqueueSnackbar } = useSnackbar();

  const onEnqueueSnackbarHandler = async (
    message: SnackbarMessage,
    options?: OptionsObject | undefined
  ) => {
    enqueueSnackbar(message, {
      variant: options?.variant,
      anchorOrigin: { vertical: "bottom", horizontal: "right" },
    });
  };

  return {
    onEnqueueSnackbar: (
      message: SnackbarMessage,
      options?: OptionsObject | undefined
    ) => onEnqueueSnackbarHandler(message, options),
  };
};
