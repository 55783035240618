import {
  IContactPhone,
  PatchContactPhoneRequest,
  PostContactPhoneRequest,
} from "../../models";
import { api } from "../api";

export const postAccountSettingPhone = async (
  subscriptionId: string,
  contactId: string,
  request: PostContactPhoneRequest
): Promise<IContactPhone> => {
  const response = await api.patch<IContactPhone>(
    `/${subscriptionId}/account-setting/${contactId}`,
    request
  );
  return response.data;
};

export const patchAccountSettingPhone = async (
  subscriptionId: string,
  contactId: string,
  request: PatchContactPhoneRequest
): Promise<void> => {
  await api.patch(`/${subscriptionId}/account-setting/${contactId}`, request);
};
