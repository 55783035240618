import { ITaskTeam, PostTaskTeamRequest } from "../../models";
import { api } from "../api";

export const postTaskTeam = async (
  subscriptionId: string,
  taskId: string,
  request: PostTaskTeamRequest
): Promise<ITaskTeam> => {
  const response = await api.post<ITaskTeam>(
    `/${subscriptionId}/task/${taskId}/team`,
    request
  );
  return response.data;
};

export const deleteTaskTeam = async (
  subscriptionId: string,
  taskId: string,
  id: string
): Promise<void> => {
  await api.delete(`/${subscriptionId}/task/${taskId}/team/${id}`);
};
