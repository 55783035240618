import {
  GetAccountConnectorRequest,
  GetGoogleConnectorReportRequest,
  GetGoogleGmbConnectorAuthUrlRequest,
  IGMBAccountConnector,
  IGMBLocationPerformanceReport,
  IGMBLocationReport,
  IGMBLocations,
  IGMBParentAccount,
  IGMBPostReport,
  IGMBReviewReport,
  IGoogleConnector,
  IGoogleConnectorAuthUrlResponse,
  IGoogleConnectorTokenResponse,
  PatchGMBAccountLocationRequest,
  PatchUnlinkAccountConnectedItemRequest,
  PostConnectGoogleConnectorRequest,
} from "../../../../models";

import { api } from "../../../api";

export const getGoogleGmbConnectorAuthUrl = async (
  subscriptionId: string,
  request?: GetGoogleGmbConnectorAuthUrlRequest
): Promise<IGoogleConnectorAuthUrlResponse> => {
  const response = await api.get<IGoogleConnectorAuthUrlResponse>(
    `/${subscriptionId}/connector/google/gmb/auth-url`,
    {
      params: request,
    }
  );
  return response.data;
};

export const getGoogleGmbConnectors = async (
  subscriptionId: string
): Promise<IGoogleConnector[]> => {
  const response = await api.get<IGoogleConnector[]>(
    `/${subscriptionId}/connector/google/gmb`
  );
  return response.data;
};

export const connectToGoogleGmbConnector = async (
  subscriptionId: string,
  request: PostConnectGoogleConnectorRequest
): Promise<IGoogleConnector> => {
  const response = await api.post<IGoogleConnector>(
    `/${subscriptionId}/connector/google/gmb/connect`,
    request
  );
  return response.data;
};

export const disconnectFromGoogleGmbConnector = async (
  subscriptionId: string,
  id: string
): Promise<void> => {
  await api.delete(
    `/${subscriptionId}/connector/google/gmb/disconnect/${encodeURIComponent(
      id
    )}`
  );
};

export const getGoogleGmbConnectorToken = async (
  subscriptionId: string,
  id: string
): Promise<IGoogleConnectorTokenResponse> => {
  const response = await api.get<IGoogleConnectorTokenResponse>(
    `/${subscriptionId}/connector/google/gmb/token/${encodeURIComponent(id)}`
  );
  return response.data;
};

export const getGoogleParentAccounts = async (
  subscriptionId: string,
  request?: GetAccountConnectorRequest
): Promise<IGMBParentAccount> => {
  const response = await api.get<IGMBParentAccount>(
    `/${subscriptionId}/account-connector/link`,
    {
      params: request,
    }
  );
  return response.data;
};

export const getGMBLocationsByParentAccount = async (
  subscriptionId: string,
  request?: GetAccountConnectorRequest
): Promise<IGMBLocations[]> => {
  const response = await api.get<IGMBLocations[]>(
    `/${subscriptionId}/account-connector/link`,
    {
      params: request,
    }
  );
  return response.data;
};

export const patchGMBAccount = async (
  subscriptionId: string,
  accountId: string,
  request: PatchGMBAccountLocationRequest
): Promise<void> => {
  await api.patch(`/${subscriptionId}/account-connector/${accountId}`, request);
};

export const getGMBConnectedLocations = async (
  subscriptionId: string,
  accountId: string,
  request?: GetAccountConnectorRequest
): Promise<IGMBAccountConnector> => {
  const response = await api.get<IGMBAccountConnector>(
    `/${subscriptionId}/account-connector/${accountId}/list`,
    {
      params: request,
    }
  );
  return response.data;
};

export const unlinkGMBConnectedLocation = async (
  subscriptionId: string,
  accountId: string,
  request: PatchUnlinkAccountConnectedItemRequest
): Promise<void> => {
  await api.patch(
    `/${subscriptionId}/account-connector/${accountId}/unlink`,
    request
  );
};

export const getGMBLocationPerformanceReport = async (
  subscriptionId: string,
  accountId: string,
  request?: GetGoogleConnectorReportRequest
): Promise<IGMBLocationPerformanceReport> => {
  const response = await api.get<IGMBLocationPerformanceReport>(
    `/${subscriptionId}/account-connector/${accountId}/report`,
    {
      params: request,
    }
  );
  return response.data;
};

export const getGMBLocationReport = async (
  subscriptionId: string,
  accountId: string,
  request?: GetGoogleConnectorReportRequest
): Promise<IGMBLocationReport> => {
  const response = await api.get<IGMBLocationReport>(
    `/${subscriptionId}/account-connector/${accountId}/report`,
    {
      params: request,
    }
  );
  return response.data;
};

export const getGMBReviewReport = async (
  subscriptionId: string,
  accountId: string,
  request?: GetGoogleConnectorReportRequest
): Promise<IGMBReviewReport> => {
  const response = await api.get<IGMBReviewReport>(
    `/${subscriptionId}/account-connector/${accountId}/report`,
    {
      params: request,
    }
  );
  return response.data;
};

export const getGMBPostReport = async (
  subscriptionId: string,
  accountId: string,
  request?: GetGoogleConnectorReportRequest
): Promise<IGMBPostReport> => {
  const response = await api.get<IGMBPostReport>(
    `/${subscriptionId}/account-connector/${accountId}/report`,
    {
      params: request,
    }
  );
  return response.data;
};
