import { secondsToDhms } from "@daxrm/utils";
import { Box, CardProps } from "@mui/material";
import merge from 'lodash/merge';
import ReactApexChart from 'react-apexcharts';
import { BaseOptionChart } from "./chart-setting";

interface ReactApexColumnChartProps extends CardProps {
    chartLabels: string[];
    chartData: {
        name: string;
        data: number[];
    }[];
    isDistributed?: boolean;
}

export const RAColumnStackedChart = ({
    chartLabels,
    chartData,
    isDistributed
}: ReactApexColumnChartProps) => {

    const chartOptions = merge(BaseOptionChart(), {
        chart: {
            stacked: true,
        },
        theme: {
            palette: 'palette4'
        },
        legend: {
            show: false
        },
        stroke: {
            show: true,
            width: 0,
            colors: ['transparent'],
        },
        xaxis: {
            categories: chartLabels,
            labels: {
                rotate: -45,
                trim: true,
            }
        },
        tooltip: {
            y: {
                formatter: (val: number) => {
                    return val;
                },
            },
            x: {
                show: true,
            },
        },
        plotOptions: {
            bar: {
                distributed: isDistributed
            }
        }
    });

    return (<>
        <Box sx={{ p: 3, pb: 1 }} dir="ltr">
            <ReactApexChart type="bar" series={chartData} options={chartOptions} height={250} />
        </Box>
    </>);

}