import {
    ClaimPermissions,
    getPermissions,
} from "@daxrm/api";
import { ReactNode, createContext, useEffect, useReducer } from "react";

import { setSession } from "../utils/jwt";
import { useAuth } from "../hooks";

type CLaimPermission = {
    [key in ClaimPermissions]: boolean;
};

const DEFAULT_PERMISSIONS: CLaimPermission = {} as CLaimPermission;

type ActionMap<M extends { [index: string]: any }> = {
    [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
    }
    : {
        type: Key;
        payload: M[Key];
    };
};

export type PermissionsState = {
    isInitialized: boolean;
    permissions: CLaimPermission;
};

export type PermissionsContextType = {
    isInitialized: boolean;
    permissions: CLaimPermission;
    hasClaim: (permissions: ClaimPermissions) => boolean;
    hasAnyClaim: (permissions: ClaimPermissions[]) => boolean;
    hasAllClaims: (permissions: ClaimPermissions[]) => boolean;
};

enum Types {
    Initial = "INITIALIZE",
}

type PermissionsPayload = {
    [Types.Initial]: {
        permissions: CLaimPermission;
    };
};

export type PermissionActions =
    ActionMap<PermissionsPayload>[keyof ActionMap<PermissionsPayload>];

const initialState: PermissionsState = {
    isInitialized: false,
    permissions: DEFAULT_PERMISSIONS
};

const PermissionsReducer = (state: PermissionsState, action: PermissionActions) => {
    switch (action.type) {
        case "INITIALIZE":
            return {
                ...state,
                isInitialized: true,
                permissions: action.payload.permissions
            };
        default:
            return state;
    }
};

const PermissionsContext = createContext<PermissionsContextType | null>(null);

function PermissionsProvider({ children }: { children: ReactNode }) {
    const [state, dispatch] = useReducer(PermissionsReducer, initialState);
    const { isAuthenticated } = useAuth();

    const hasClaim = (permissions: ClaimPermissions): boolean => state.permissions[permissions];

    const hasAnyClaim = (permissions: ClaimPermissions[]) => permissions.some(p => state.permissions[p]);

    const hasAllClaims = (permissions: ClaimPermissions[]) => permissions.every(p => state.permissions[p]);

    const initialize = async () => {
        try {
            const { permissions } = await getPermissions();
            dispatch({
                type: Types.Initial,
                payload: {
                    permissions: permissions
                },
            });

        } catch (err) {
            console.error(err);
            setSession(null);
            dispatch({
                type: Types.Initial,
                payload: {
                    permissions: DEFAULT_PERMISSIONS,
                },
            });
        }
    };

    useEffect(() => {
        if (isAuthenticated) {
            initialize();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated]);

    return (
        <PermissionsContext.Provider
            value={{
                ...state,
                hasClaim,
                hasAnyClaim,
                hasAllClaims
            }}
        >
            {children}
        </PermissionsContext.Provider>
    );
}

export { PermissionsContext, PermissionsProvider };
