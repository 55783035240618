import {
  GetContactsSummaryRequest,
  IContactSummary,
  IContactSummaryTemp,
  IPaginated,
} from "../../models";
import { api } from "../api";

export const getContactsSummary = async (
  subscriptionId: string,
  companyId: string,
  currentPage: number,
  itemsPerPage: number,
  request?: GetContactsSummaryRequest
): Promise<IPaginated<IContactSummary>> => {
  const response = await api.get<IPaginated<IContactSummary>>(
    `/${subscriptionId}/contact/${companyId}/contact-summary`,
    {
      params: {
        currentPage: currentPage,
        itemsPerPage: itemsPerPage,
        ...request,
      },
    }
  );
  return response.data;
};

export const getAllContactsSummary = async (
  subscriptionId: string,
  companyId: string,
  request?: GetContactsSummaryRequest
): Promise<IContactSummaryTemp[]> => {
  const response = await api.get<IContactSummaryTemp[]>(
    `/${subscriptionId}/contact/${companyId}/contact-summary`,
    {
      params: {
        ...request,
      },
    }
  );
  return response.data;
};
