import {
    GetCompanyPhonesRequest,
    ICompanyPhone,
    PostCompanyPhoneRequest,
    PutCompanyPhoneRequest,
    PatchCompanyPhoneRequest,
  } from "../models";
  
  import { api } from "./api";
  
  export const getCompanyPhones = async (
    subscriptionId: string,
    companyId: string,
    request?: GetCompanyPhonesRequest
  ): Promise<ICompanyPhone[]> => {
    const response = await api.get<ICompanyPhone[]>(
      `/${subscriptionId}/company/${companyId}/phone`,
      {
        params: {
          ...request,
        },
      }
    );
    return response.data;
  };
  
  export const postCompanyPhone = async (
    subscriptionId: string,
    companyId: string,
    request: PostCompanyPhoneRequest
  ): Promise<ICompanyPhone> => {
    const response = await api.post<ICompanyPhone>(
      `/${subscriptionId}/company/${companyId}/phone`,
      request
    );
    return response.data;
  };
  
  export const putCompanyPhone = async (
    subscriptionId: string,
    companyId: string,
    id: string,
    request: PutCompanyPhoneRequest
  ): Promise<void> => {
    await api.put(`/${subscriptionId}/company/${companyId}/phone/${id}`, request);
  };
  
  export const patchCompanyPhone = async (
    subscriptionId: string,
    companyId: string,
    id: string,
    request: PatchCompanyPhoneRequest
  ): Promise<void> => {
    await api.patch(`/${subscriptionId}/company/${companyId}/phone/${id}`, request);
  
  };
  
  export const deleteCompanyPhone = async (
    subscriptionId: string,
    companyId: string,
    id: string
  ): Promise<void> => {
    await api.delete(`/${subscriptionId}/company/${companyId}/phone/${id}`);
  };
  