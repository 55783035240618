import {
  GetCurrentSubscriptionDetailRequest,
  GetCurrentSubscriptionRequest,
  ISubscriptionDetail,
  SubscribeNewPlanRequest,
  SubscribePlanAddOnsRequest,
  UnsubscribePlanAddOnsRequest,
} from "../../models";

import Stripe from "stripe";
import { api } from "../api";

export const getCurrentSubscription = async (
  subscriptionId: string,
  request: GetCurrentSubscriptionRequest
): Promise<Stripe.Subscription> => {
  const response = await api.get<Stripe.Subscription>(
    `${subscriptionId}/subscription`,
    {
      params: {
        ...request,
      },
    }
  );
  return response.data;
};

export const subscribeNewPlan = async (
  subscriptionId: string,
  request: SubscribeNewPlanRequest
): Promise<Stripe.Subscription> => {
  const response = await api.put<Stripe.Subscription>(
    `/${subscriptionId}/subscription`,
    request
  );
  return response.data;
};

export const subscribeToPlanAddOns = async (
  subscriptionId: string,
  request: SubscribePlanAddOnsRequest
): Promise<Stripe.Subscription> => {
  const response = await api.put<Stripe.Subscription>(
    `/${subscriptionId}/subscription/add-ons`,
    request
  );
  return response.data;
};

export const subscribeToPlanAddOnsLimit = async (
  subscriptionId: string,
  request: SubscribePlanAddOnsRequest
): Promise<Stripe.Subscription> => {
  const response = await api.put<Stripe.Subscription>(
    `/${subscriptionId}/subscription/add-ons-limit`,
    request
  );
  return response.data;
};

export const unsubscribeToPlanAddOns = async (
  subscriptionId: string,
  request: UnsubscribePlanAddOnsRequest
): Promise<Stripe.Subscription> => {
  const response = await api.delete<Stripe.Subscription>(
    `/${subscriptionId}/subscription/add-ons`,
    {
      data: request,
    }
  );
  return response.data;
};

export const getCurrentSubscribeDetail = async (
  subscriptionId: string,
  request: GetCurrentSubscriptionDetailRequest
): Promise<ISubscriptionDetail> => {
  const response = await api.get<ISubscriptionDetail>(
    `${subscriptionId}/subscription-detail`,
    {
      params: {
        ...request,
      },
    }
  );
  return response.data;
};

export const getSubscriptionBrandLogo = async (
  subscriptionId: string
): Promise<string> => {
  const response = await api.get<string>(
    `/${subscriptionId}/subscription-brand-logo`
  );
  return response.data;
};
