import {
  GetTeamsRequest,
  IPaginated,
  ITeam,
  PatchTeamRequest,
  PostTeamRequest,
} from "../../models";
import { api } from "../api";

export const getTeams = async (
  subscriptionId: string,
  currentPage: number,
  itemsPerPage: number,
  request?: GetTeamsRequest
): Promise<IPaginated<ITeam>> => {
  const response = await api.get<IPaginated<ITeam>>(`/${subscriptionId}/team`, {
    params: {
      currentPage: currentPage,
      itemsPerPage: itemsPerPage,
      ...request,
    },
  });
  return response.data;
};

export const getTeam = async (
  subscriptionId: string,
  id: string
): Promise<ITeam> => {
  const response = await api.get<ITeam>(`/${subscriptionId}/team/${id}`);
  return response.data;
};

export const postTeam = async (
  subscriptionId: string,
  request: PostTeamRequest
): Promise<ITeam> => {
  const response = await api.post<ITeam>(`/${subscriptionId}/team`, request);
  return response.data;
};

export const patchTeam = async (
  subscriptionId: string,
  id: string,
  request: PatchTeamRequest
): Promise<void> => {
  await api.patch(`/${subscriptionId}/team/${id}`, request);
};
