import {
  GetInvoicesRequest,
  GetInvoiceProductItemsRequest,
  IInvoice,
  IInvoiceDetails,
  IInvoiceProductItem,
  IPaginated,
  PostInvoicesRequest,
  XeroInvoiceEmailRequest,
  GetInvoicesStatisticsRequest,
  IInvoiceStatistics,
  PutInvoicesRequest,
} from "../../models";

import { api } from "../api";

export const getInvoices = async (
  subscriptionId: string,
  request?: GetInvoicesRequest
): Promise<IInvoice[]> => {
  const response = await api.get<IInvoice[]>(
    `/${subscriptionId}/xero/invoice`,
    {
      params: {
        ...request,
      },
    }
  );
  return response.data;
};

export const getInvoicesPage = async (
  subscriptionId: string,
  currentPage: number,
  itemsPerPage: number,
  request?: GetInvoicesRequest
): Promise<IPaginated<IInvoice>> => {
  const response = await api.get<IPaginated<IInvoice>>(
    `/${subscriptionId}/xero/invoice`,
    {
      params: {
        currentPage: currentPage,
        itemsPerPage: itemsPerPage,
        ...request,
      },
    }
  );
  return response.data;
};

export const getInvoice = async (
  subscriptionId: string,
  request?: GetInvoicesRequest
): Promise<IInvoiceDetails[]> => {
  const response = await api.get<IInvoiceDetails[]>(
    `/${subscriptionId}/xero/invoice`,
    {
      params: {
        ...request,
      },
    }
  );
  return response.data;
};

export const getProductItems = async (
  subscriptionId: string,
  request?: GetInvoiceProductItemsRequest
): Promise<IInvoiceProductItem[]> => {
  const response = await api.get<IInvoiceProductItem[]>(
    `/${subscriptionId}/xero/product-item`,
    {
      params: {
        ...request,
      },
    }
  );
  return response.data;
};

export const postInvoice = async (
  subscriptionId: string,
  request: PostInvoicesRequest
): Promise<void> => {
  const response = await api.post<void>(
    `/${subscriptionId}/xero/invoice`,
    request
  );
  return response.data;
};

export const xeroInvoiceEmail = async (
  subscriptionId: string,
  invoiceId: string,
  request: XeroInvoiceEmailRequest
): Promise<void> => {
  const response = await api.get<void>(
    `/${subscriptionId}/xero/invoice/email/${invoiceId}`,
    request
  );
  return response.data;
};

export const getInvoiceStatistics = async (
  subscriptionId: string,
  request: GetInvoicesStatisticsRequest
): Promise<IInvoiceStatistics> => {
  const response = await api.get<IInvoiceStatistics>(
    `/${subscriptionId}/xero/invoice/statistics`,
    {
      params: {
        ...request,
      },
    }
  );
  return response.data;
};

export const putInvoice = async (
  subscriptionId: string,
  invoiceId: string,
  request: PutInvoicesRequest
): Promise<void> => {
  const response = await api.put<void>(
    `/${subscriptionId}/xero/invoice/${invoiceId}`,
    request
  );
  return response.data;
};
