import React, { ReactNode, createContext, useLayoutEffect } from "react";

import { AxiosError } from "axios";
import { api } from "@daxrm/api";
import { useNavigate } from "react-router-dom";

export type APIContextType = {};

const APIContext = createContext<APIContextType | null>(null);

function APIProvider({ children }: { children: ReactNode }) {
  const navigate = useNavigate();

  const apiResponseErrorInterceptor = (error: AxiosError) => {
    if ([401].includes(error.response?.status || 0)) {
      return Promise.reject(error);
    } else if ([403].includes(error.response?.status || 0)) {
      if (error.response && error.response?.data && error.response?.data.errors.length > 0) {
        return Promise.reject(error);
      } else {
        navigate("/oops/403", { replace: true });
        return;
      }
    } else {
      return Promise.reject(error);
    }
  };

  useLayoutEffect(() => {
    api.interceptors.response.use(undefined, apiResponseErrorInterceptor);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <APIContext.Provider value={{}}>{children}</APIContext.Provider>;
}

export { APIContext, APIProvider };
