import ReCAPTCHA from 'react-google-recaptcha';

const { REACT_APP_RECAPTCHA_SITE_KEY } = process.env;

export interface IReCaptchaProps {
  onChangeCaptchaResponse: (captchaResponse: string | null) => Promise<void>;
}

export function ReCaptcha({ onChangeCaptchaResponse }: IReCaptchaProps) {
  const handleCaptchaChange = (response: string | null) => {
    onChangeCaptchaResponse(response);
  };

  return (<>
    {REACT_APP_RECAPTCHA_SITE_KEY && <>
      <ReCAPTCHA sitekey={REACT_APP_RECAPTCHA_SITE_KEY} onChange={handleCaptchaChange} />
    </>}
  </>)
}

