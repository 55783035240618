import { IAccountAddress, PostOrPutAccountAddressRequest } from "../../models";
import { api } from "../api";

export const getAccountAddress = async (
  subscriptionId: string,
  companyId: string
): Promise<IAccountAddress> => {
  const response = await api.get<IAccountAddress>(
    `/${subscriptionId}/account-setting/address/${companyId}`
  );
  return response.data;
};

export const postOrPutAccountAddress = async (
  subscriptionId: string,
  companyId: string,
  request: PostOrPutAccountAddressRequest
): Promise<IAccountAddress> => {
  const response = await api.patch<IAccountAddress>(
    `/${subscriptionId}/account-setting/${companyId}`,
    request
  );
  return response.data;
};
