import { Box, BoxProps, styled } from '@mui/material';

import { BaseOptionChart } from './chart-setting';
import ReactApexChart from 'react-apexcharts';
import { fNumber, secondsToDhms } from '@daxrm/utils';
import merge from 'lodash/merge';
// @mui
import { useTheme } from '@mui/material/styles';
type IChartType = "pie" | "donut";

const CHART_HEIGHT = 275;
// const LEGEND_HEIGHT = 42;
interface ReactApexPieChartReactApexPieChartProps extends BoxProps {
  chartType?: IChartType;
  chartColors: string[];
  chartData: {
    label: string;
    value: number;
  }[];
  combinedStyle?: any
  metricType?: string;
}

export function RAPieChart({
  chartType = "pie",
  combinedStyle,
  chartColors,
  chartData,
  metricType,
  ...otherBoxProps
}: ReactApexPieChartReactApexPieChartProps) {
  const theme = useTheme();
  const chartLabels = chartData.map((i) => i.label);

  const ChartWrapperStyle = styled(Box)(({ theme }) => ({
    height: "100% !important",
    minHeight: "100% !important",
    // marginTop: theme.spacing(5),
    '& .apexcharts-canvas svg': { height: CHART_HEIGHT },
    '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
      overflow: 'visible',
    },
    '& .apexcharts-legend': {
      // height: 'auto',
      alignContent: 'center',
      position: 'absolute !important' as 'absolute',
      // borderTop: `solid 1px ${theme.palette.divider}`,
      // top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
    },
  }));

  const chartSeries = chartData.map((i) => i.value);

  const chartOptions = merge(BaseOptionChart(), {
    colors: chartColors,
    labels: chartLabels,
    stroke: { show: false, colors: [theme.palette.background.paper] },
    legend: {
      show: true,
      position: 'bottom',
      horizontalAlign: 'center',
      fontSize: '11px',
      itemMargin: {
        horizontal: 3,
        vertical: 0,
      },
      markers: {
        width: 6,
        height: 6,
        size: 6,
        radius: 10
      },
      offsetX: 0,
      offsetY: 0,
      labels: {
        colors: [combinedStyle.color, combinedStyle.color, combinedStyle.color, combinedStyle.color],
      },
      // formatter: function (val: number, opts: { w: { globals: { series: { [x: string]: string; }; }; }; seriesIndex: string | number; }) {
      //   // return val + " - " + opts.w.globals.series[opts.seriesIndex];
      //   return val;
      // },
    },
    dataLabels: {
      enabled: true,
      dropShadow: { enabled: false },
      background: {
        enabled: true,
        foreColor: '#000',
        borderRadius: 2,
        padding: 4,
        opacity: 0.3,
        borderWidth: 1,
        borderColor: '#fff'
      }
    },
    tooltip: {
      fillSeriesColor: false,
      y: {
        formatter: (seriesName: string) => {
          if (
            metricType &&
            (
              metricType === 'ga4-pieChart-acquisitionAllUserEngagement' ||
              metricType === 'ga4-pieChart-acquisitionOrganicSearchUserEngagement' ||
              metricType === 'ga4-pieChart-acquisitionPaidSearchUserEngagement' ||
              metricType === 'ga4-pieChart-audienceDevicesUserEngagement'
            )
          ) {
            return secondsToDhms(seriesName);
          } else {
            return fNumber(seriesName);
          }

        },
        title: {
          formatter: (seriesName: string) => `${seriesName}`,
        },
      },
    },
    plotOptions: {
      pie: { donut: { labels: { show: false } } },
    },
  });

  return (
    <ChartWrapperStyle
      {...otherBoxProps}
      sx={{ ...otherBoxProps.sx }}
      dir="ltr"
    >
      <ReactApexChart type={chartType} series={chartSeries} options={chartOptions} height={275} />
    </ChartWrapperStyle>
  );
}
