import {
  AuthTokensResponse,
  IMe,
  IPermissions,
  ISubscription,
} from "../models";

import { api } from "./api";

export const getMe = async (): Promise<IMe> => {
  const response = await api.get<IMe>("/account/me");
  return response.data;
};

export const getPermissions = async (): Promise<IPermissions> => {
  const response = await api.get<IPermissions>("/account/permissions");
  return response.data;
};

export const getSubscriptions = async (): Promise<ISubscription[]> => {
  const response = await api.get<{ subscriptions: ISubscription[] }>(
    "/account/subscriptions"
  );
  return response.data.subscriptions;
};

export const signInToSubscription = async (
  toSubscriptionId: string,
  rememberMe: boolean
): Promise<AuthTokensResponse> => {
  const response = await api.post("/account/signin-to-subscription", {
    subscriptionId: toSubscriptionId,
    rememberMe: rememberMe,
  });
  return response.data;
};
