import {
  GetAccountConnectorRequest,
  GetGoogleConnectorReportRequest,
  GetGoogleGscConnectorAuthUrlRequest,
  IGSCAccount,
  IGSCAccountReport,
  IGSCConnectedAccount,
  IGoogleConnector,
  IGoogleConnectorAuthUrlResponse,
  PatchGSCAccountRequest,
  PatchUnlinkAccountConnectedItemRequest,
  PostConnectGoogleConnectorRequest,
} from "../../../../models";

import { api } from "../../../api";

export const getGoogleGscConnectorAuthUrl = async (
  subscriptionId: string,
  request?: GetGoogleGscConnectorAuthUrlRequest
): Promise<IGoogleConnectorAuthUrlResponse> => {
  const response = await api.get<IGoogleConnectorAuthUrlResponse>(
    `/${subscriptionId}/connector/google/gsc/auth-url`,
    {
      params: request,
    }
  );
  return response.data;
};

export const getGoogleGscConnectors = async (
  subscriptionId: string
): Promise<IGoogleConnector[]> => {
  const response = await api.get<IGoogleConnector[]>(
    `/${subscriptionId}/connector/google/gsc`
  );
  return response.data;
};

export const connectToGoogleGscConnector = async (
  subscriptionId: string,
  request: PostConnectGoogleConnectorRequest
): Promise<IGoogleConnector> => {
  const response = await api.post<IGoogleConnector>(
    `/${subscriptionId}/connector/google/gsc/connect`,
    request
  );
  return response.data;
};

export const disconnectFromGoogleGscConnector = async (
  subscriptionId: string,
  id: string
): Promise<void> => {
  await api.delete(
    `/${subscriptionId}/connector/google/gsc/disconnect/${encodeURIComponent(
      id
    )}`
  );
};

export const getGoogleGscAccounts = async (
  subscriptionId: string,
  request?: GetAccountConnectorRequest
): Promise<IGSCAccount> => {
  const response = await api.get<IGSCAccount>(
    `/${subscriptionId}/account-connector/link`,
    {
      params: request,
    }
  );
  return response.data;
};

export const patchGSCAccount = async (
  subscriptionId: string,
  accountId: string,
  request: PatchGSCAccountRequest
): Promise<void> => {
  await api.patch(`/${subscriptionId}/account-connector/${accountId}`, request);
};

export const getGSCConnectedAccount = async (
  subscriptionId: string,
  accountId: string,
  request?: GetAccountConnectorRequest
): Promise<IGSCConnectedAccount> => {
  const response = await api.get<IGSCConnectedAccount>(
    `/${subscriptionId}/account-connector/${accountId}/list`,
    {
      params: request,
    }
  );
  return response.data;
};

export const unlinkGSCConnectedLocation = async (
  subscriptionId: string,
  accountId: string,
  request: PatchUnlinkAccountConnectedItemRequest
): Promise<void> => {
  await api.patch(
    `/${subscriptionId}/account-connector/${accountId}/unlink`,
    request
  );
};

export const getGSCAccountReport = async (
  subscriptionId: string,
  accountId: string,
  request?: GetGoogleConnectorReportRequest
): Promise<IGSCAccountReport> => {
  const response = await api.get<IGSCAccountReport>(
    `/${subscriptionId}/account-connector/${accountId}/report`,
    {
      params: request,
    }
  );
  return response.data;
};
