import Stripe from "stripe";
import { UpgradeExistingPlanRequest } from "../../models";
import { api } from "../api";

export const upgradeExistingPlan = async (
  subscriptionId: string,
  request: UpgradeExistingPlanRequest
): Promise<Stripe.Subscription> => {
  const response = await api.put<Stripe.Subscription>(
    `/${subscriptionId}/subscription/plan-limit`,
    request
  );
  return response.data;
};
