import {
  GetTasksRequest,
  IPaginated,
  ITask,
  ITaskStatus,
  PatchTaskRequest,
  PostTaskRequest,
} from "../../models";

import { api } from "../api";

// export const getTaskByStatus = async (
//   subscriptionId: string,
//   statusCode: string
// ): Promise<ITask[]> => {
//   const response = await api.get<IContactEmail[]>(
//     `/${subscriptionId}/contact/${contactId}/email`,
//     {
//       params: {
//         ...request,
//       },
//     }
//   );
//   return response.data;
// };

export const getTasks = async (
  subscriptionId: string,
  currentPage: number,
  itemsPerPage: number,
  request?: GetTasksRequest
): Promise<IPaginated<ITask>> => {
  const response = await api.get<IPaginated<ITask>>(`/${subscriptionId}/task`, {
    params: {
      currentPage: currentPage,
      itemsPerPage: itemsPerPage,
      ...request,
    },
  });
  return response.data;
};

export const getTask = async (
  subscriptionId: string,
  id: string
): Promise<ITask> => {
  const response = await api.get<ITask>(`/${subscriptionId}/task/${id}`);
  return response.data;
};

export const postTask = async (
  subscriptionId: string,
  request: PostTaskRequest
): Promise<ITask> => {
  const response = await api.post<ITask>(`/${subscriptionId}/task`, request);
  return response.data;
};

export const patchTask = async (
  subscriptionId: string,
  id: string,
  request: PatchTaskRequest
): Promise<void> => {
  await api.patch(`/${subscriptionId}/task/${id}`, request);
};

export const deleteTask = async (
  subscriptionId: string,
  id: string
): Promise<void> => {
  await api.delete(`/${subscriptionId}/task/${id}`);
};
