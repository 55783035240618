import {
  GetTeamMembersRequest,
  IPaginated,
  ITeamMember,
  PostTeamMemberRequest,
} from "../../models";
import { api } from "../api";

export const getTeamMembers = async (
  subscriptionId: string,
  teamId: string,
  currentPage: number,
  itemsPerPage: number,
  request?: GetTeamMembersRequest
): Promise<IPaginated<ITeamMember>> => {
  const response = await api.get<IPaginated<ITeamMember>>(
    `/${subscriptionId}/team-member/${teamId}`,
    {
      params: {
        currentPage: currentPage,
        itemsPerPage: itemsPerPage,
        ...request,
      },
    }
  );
  return response.data;
};

export const postTeamMember = async (
  subscriptionId: string,
  request: PostTeamMemberRequest
): Promise<ITeamMember> => {
  const response = await api.post<ITeamMember>(
    `/${subscriptionId}/team-member`,
    request
  );
  return response.data;
};

export const deleteTeamMember = async (
  subscriptionId: string,
  teamMemberId: string
): Promise<void> => {
  await api.delete(`/${subscriptionId}/team-member/${teamMemberId}`);
};
