import {
  GetAccountConnectorRequest,
  GetGoogleConnectorReportRequest,
  IGA3Account,
  IGA3Report,
  IGAConnectedAccount,
  PatchGAAccountRequest,
  PatchUnlinkAccountConnectedItemRequest,
} from "../../../../models";

import { api } from "../../../api";

export const getGA3Accounts = async (
  subscriptionId: string,
  request?: GetAccountConnectorRequest
): Promise<IGA3Account> => {
  const response = await api.get<IGA3Account>(
    `/${subscriptionId}/account-connector/link`,
    {
      params: request,
    }
  );
  return response.data;
};

export const patchGA3Account = async (
  subscriptionId: string,
  accountId: string,
  request: PatchGAAccountRequest
): Promise<void> => {
  await api.patch(`/${subscriptionId}/account-connector/${accountId}`, request);
};

export const getGA3ConnectedAccount = async (
  subscriptionId: string,
  accountId: string,
  request?: GetAccountConnectorRequest
): Promise<IGAConnectedAccount> => {
  const response = await api.get<IGAConnectedAccount>(
    `/${subscriptionId}/account-connector/${accountId}/list`,
    {
      params: request,
    }
  );
  return response.data;
};

export const unlinkGA3ConnectedAccount = async (
  subscriptionId: string,
  accountId: string,
  request: PatchUnlinkAccountConnectedItemRequest
): Promise<void> => {
  await api.patch(
    `/${subscriptionId}/account-connector/${accountId}/unlink`,
    request
  );
};

export const getGA3Report = async (
  subscriptionId: string,
  accountId: string,
  request?: GetGoogleConnectorReportRequest
): Promise<IGA3Report> => {
  const response = await api.get<IGA3Report>(
    `/${subscriptionId}/account-connector/${accountId}/report`,
    {
      params: request,
    }
  );
  return response.data;
};
