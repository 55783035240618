import { api } from '@daxrm/api';
import jwtDecode from 'jwt-decode';

const ACCESS_TOKEN_KEY = 'dp.auth.at';
const REFRESH_TOKEN_KEY = 'dp.auth.rt';

const isValidToken = (accessToken: string) => {
  if (!accessToken) {
    return false;
  }
  const decoded = jwtDecode<{ exp: number }>(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const getRefreshToken = (): string | null => localStorage.getItem(REFRESH_TOKEN_KEY);

const setRefreshTokeSession = (refreshToken: string | null) => {
  if (refreshToken) {
    localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
  } else {
    localStorage.removeItem(REFRESH_TOKEN_KEY);
  }
};

const getAccessToken = (): string | null => localStorage.getItem(ACCESS_TOKEN_KEY);

const setSession = (accessToken: string | null) => {
  if (accessToken) {
    localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
    api.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    delete api.defaults.headers.common.Authorization;
  }
};

export { setSession, getRefreshToken, setRefreshTokeSession, getAccessToken, isValidToken };
