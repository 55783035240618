import {
  GetAccountManagerRequest,
  IContactRelationAccountManager,
  IPaginated,
  PostAccountManagerRequest,
} from "../../models";
import { api } from "../api";

export const getAccountManagers = async (
  subscriptionId: string,
  accountId: string,
  currentPage: number,
  itemsPerPage: number,
  request?: GetAccountManagerRequest
): Promise<IPaginated<IContactRelationAccountManager>> => {
  const response = await api.get<IPaginated<IContactRelationAccountManager>>(
    `/${subscriptionId}/account/${accountId}/relation`,
    {
      params: {
        currentPage: currentPage,
        itemsPerPage: itemsPerPage,
        ...request,
      },
    }
  );
  return response.data;
};

export const postAccountManager = async (
  subscriptionId: string,
  request: PostAccountManagerRequest
): Promise<IContactRelationAccountManager> => {
  const response = await api.post<IContactRelationAccountManager>(
    `/${subscriptionId}/account/relation`,
    request
  );
  return response.data;
};

export const deleteAccountManager = async (
  subscriptionId: string,
  accountManagerId: string
): Promise<void> => {
  await api.delete(`/${subscriptionId}/account/relation/${accountManagerId}`);
};
