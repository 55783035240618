import axios from "axios";

const defaultHeaders = {
  "Content-Type": "application/json",
  "Accept-Language": "en-US",
};

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: defaultHeaders,
});

const apiCore = axios.create({
  baseURL: process.env.REACT_APP_CORE_API_URL,
  headers: defaultHeaders,
});

const validatorApi = axios.create({
  baseURL: process.env.REACT_APP_VALIDATOR_API,
  headers: defaultHeaders,
});

const mockApi = axios.create();

export { api, apiCore, validatorApi, mockApi };
