import React, { ReactNode, } from 'react';

import { ErrorBoundary } from 'react-error-boundary'
import {
  Typography,
} from '@mui/material';

function DefaultErrorFallback() {
  return <Typography variant="body1" component="span">
    ⚡ Something went wrong!
  </Typography>;
}

const ErrorContainer = ({
  children,
}: {
  children: ReactNode;
  fallbackComponent?: ReactNode;
}) => {

  const onError = (error: Error) => {
    console.error(error);
  };
  return <>
    <ErrorBoundary FallbackComponent={DefaultErrorFallback} onError={onError}>
      {children}
    </ErrorBoundary>
  </>
};


export { ErrorContainer };
