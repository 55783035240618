import {
  GetProjectTemplateSectionTaskRequest,
  ITask,
  PatchProjectTemplateSectionTaskRequest,
  PatchProjectTemplateSectionTaskSequenceRequest,
  PostProjectTemplateSectionTaskRequest,
} from "../../models";

import { api } from "../api";

export const getProjectTemplateSectionTasks = async (
  subscriptionId: string,
  projectTemplateId: string,
  request?: GetProjectTemplateSectionTaskRequest
): Promise<ITask[]> => {
  const response = await api.get<ITask[]>(
    `/${subscriptionId}/project-template/${projectTemplateId}/task`,
    {
      params: {
        ...request,
      },
    }
  );
  return response.data;
};

export const postProjectTemplateSectionTask = async (
  subscriptionId: string,
  projectTemplateId: string,
  request: PostProjectTemplateSectionTaskRequest
): Promise<ITask> => {
  const response = await api.post<ITask>(
    `/${subscriptionId}/project-template/${projectTemplateId}/task`,
    request
  );
  return response.data;
};

export const patchProjectTemplateSectionTask = async (
  subscriptionId: string,
  projectTemplateId: string,
  id: string,
  request: PatchProjectTemplateSectionTaskRequest
): Promise<void> => {
  await api.patch(
    `/${subscriptionId}/project-template/${projectTemplateId}/task/${id}/update`,
    request
  );
};

export const patchProjectTemplateSectionTaskSequence = async (
  subscriptionId: string,
  projectTemplateId: string,
  request: PatchProjectTemplateSectionTaskSequenceRequest
): Promise<void> => {
  await api.patch(
    `/${subscriptionId}/project-template/${projectTemplateId}/task/sequence`,
    request
  );
};

export const deleteProjectTemplateSectionTask = async (
  subscriptionId: string,
  projectTemplateId: string,
  id: string
): Promise<void> => {
  await api.delete(
    `/${subscriptionId}/project-template/${projectTemplateId}/task/${id}/delete`
  );
};
