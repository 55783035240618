import {
  IContactEmail,
  GetContactEmailsRequest,
  PostContactEmailRequest,
  PutContactEmailRequest,
  PatchContactEmailRequest,
} from "../../models";

import { api } from "../api";

export const getContactEmails = async (
  subscriptionId: string,
  contactId: string,
  request?: GetContactEmailsRequest
): Promise<IContactEmail[]> => {
  const response = await api.get<IContactEmail[]>(
    `/${subscriptionId}/contact/${contactId}/email`,
    {
      params: {
        ...request,
      },
    }
  );
  return response.data;
};

export const postContactEmail = async (
  subscriptionId: string,
  contactId: string,
  request: PostContactEmailRequest
): Promise<IContactEmail> => {
  const response = await api.post<IContactEmail>(
    `/${subscriptionId}/contact/${contactId}/email`,
    request
  );
  return response.data;
};

export const putContactEmail = async (
  subscriptionId: string,
  contactId: string,
  id: string,
  request: PutContactEmailRequest
): Promise<void> => {
  await api.put(`/${subscriptionId}/contact/${contactId}/email/${id}`, request);
};

export const patchContactEmail = async (
  subscriptionId: string,
  contactId: string,
  id: string,
  request: PatchContactEmailRequest
): Promise<void> => {
  await api.patch(
    `/${subscriptionId}/contact/${contactId}/email/${id}`,
    request
  );
};

export const deleteContactEmail = async (
  subscriptionId: string,
  contactId: string,
  id: string
): Promise<void> => {
  await api.delete(`/${subscriptionId}/contact/${contactId}/email/${id}`);
};
