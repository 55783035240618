// form
import { useFormContext, Controller } from 'react-hook-form';
import { MuiColorInput, MuiColorInputFormat, MuiColorInputProps } from "mui-color-input";
// ----------------------------------------------------------------------

type IProps = {
  name: string;
  format?: MuiColorInputFormat;
};

type Props = IProps & MuiColorInputProps;

export default function RHFColorPickerField({ name, format = "hex", ...other }: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <MuiColorInput
          {...field}
          format={format}
          error={!!error}
          {...other}
        />
      )}
    />
  );
}
