import { Stack, Typography, TypographyProps } from "@mui/material"

import { ReactNode } from "react";

interface ISidebarFormHeaderTitleProps {
    icon?: ReactNode;
    title?: ReactNode;
    titleProps?: TypographyProps
}

export const SidebarFormHeaderTitle = ({ icon, title, titleProps }: ISidebarFormHeaderTitleProps) => <>
    <Stack direction="row" spacing={1} alignItems="center">
        {icon}
        {typeof title === "string"
            ? <Typography {...titleProps} >{title}</Typography>
            : title
        }
    </Stack>
</>