import {
  GetProjectsRequest,
  IProject,
  IPaginated,
  PatchProjectRequest,
  PostProjectRequest,
} from "../../models";
import { api } from "../api";

export const getProjects = async (
  subscriptionId: string,
  currentPage: number,
  itemsPerPage: number,
  request?: GetProjectsRequest
): Promise<IPaginated<IProject>> => {
  const response = await api.get<IPaginated<IProject>>(
    `/${subscriptionId}/project`,
    {
      params: {
        currentPage: currentPage,
        itemsPerPage: itemsPerPage,
        ...request,
      },
    }
  );
  return response.data;
};

export const getProject = async (
  subscriptionId: string,
  id: string
): Promise<IProject> => {
  const response = await api.get<IProject>(`/${subscriptionId}/project/${id}`);
  return response.data;
};

export const postProject = async (
  subscriptionId: string,
  request: PostProjectRequest
): Promise<IProject> => {
  const response = await api.post<IProject>(
    `/${subscriptionId}/project`,
    request
  );
  return response.data;
};

export const patchProject = async (
  subscriptionId: string,
  id: string,
  request: PatchProjectRequest
): Promise<void> => {
  await api.patch(`/${subscriptionId}/project/${id}`, request);
};

export const deleteProject = async (
  subscriptionId: string,
  projectId: string
): Promise<void> => {
  await api.delete(`/${subscriptionId}/project/${projectId}`);
};
