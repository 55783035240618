import {
  GetPlansRequest,
  GetSubscriptionPlanFeaturePriceRequest,
  GetSubscriptionPlanFeatureRequest,
  IPlanAddOnPrice,
  ISubscriptionPlanFeature,
  ISubscriptionPlanFeaturePrice,
  Plan,
  SubscribeNewPlanRequest,
} from "../../models";

import Stripe from "stripe";
import { api } from "../api";
const { REACT_APP_LOOKUP_API_URL } = process.env;

export const getPlans = async (
  subscriptionId: string,
  request: GetPlansRequest
): Promise<Plan[]> => {
  const response = await api.get<Plan[]>(`/subscription/stripe/plan`, {
    params: {
      ...request,
    },
  });
  return response.data;
};

export const getPlanFeatures = async (
  request: GetSubscriptionPlanFeatureRequest
): Promise<ISubscriptionPlanFeature[]> => {
  const response = await api.get<ISubscriptionPlanFeature[]>(
    `/lookup/plan-details`,
    {
      baseURL: `${REACT_APP_LOOKUP_API_URL}`,
      params: {
        ...request,
      },
    }
  );
  return response.data;
};

export const getPlanFeaturePrices = async (
  request: GetSubscriptionPlanFeaturePriceRequest
): Promise<ISubscriptionPlanFeaturePrice[]> => {
  const response = await api.get<ISubscriptionPlanFeaturePrice[]>(
    `/lookup/plan-price`,
    {
      baseURL: `${REACT_APP_LOOKUP_API_URL}`,
      params: {
        ...request,
      },
    }
  );
  return response.data;
};

export const getPlanAddOnPrices = async (): Promise<IPlanAddOnPrice[]> => {
  const response = await api.get<IPlanAddOnPrice[]>(
    `/lookup/addon-plan-price`,
    {
      baseURL: `${REACT_APP_LOOKUP_API_URL}`,
    }
  );
  return response.data;
};
