import { ElementType, Suspense, lazy } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';

import { ErrorContainer } from '../components/error';
import LoadingScreen from '../components/LoadingScreen';
import { useAuth } from '../hooks';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isAuthenticated } = useAuth();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={isAuthenticated} />}>
      <ErrorContainer>
        <Component {...props} />
      </ErrorContainer>
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    { path: '', element: <Dashboard /> },
    { path: 'report-export/:id', element: <ReportViewerPage /> },
    { path: 'report/:id', element: <ViewReportPage /> },
    { path: '*', element: <Navigate to="/404" replace /> },
    { path: '404', element: <NotFound /> },
  ]);
}

const Dashboard = Loadable(lazy(() => import('../pages/dashboard/Dashboard')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
const ReportViewerPage = Loadable(lazy(() => import('../pages/report/ReportViewerPage')));
const ViewReportPage = Loadable(lazy(() => import('../pages/report/ViewReportPage')));