import {
  GetContactsRequest,
  IContact,
  IPaginated,
  PatchContactRequest,
  PostContactRequest,
  PutContactRequest,
} from "../../models";

import { api } from "../api";

export const getContacts = async (
  subscriptionId: string,
  currentPage: number,
  itemsPerPage: number,
  request?: GetContactsRequest
): Promise<IPaginated<IContact>> => {
  const response = await api.get<IPaginated<IContact>>(
    `/${subscriptionId}/contact`,
    {
      params: {
        currentPage: currentPage,
        itemsPerPage: itemsPerPage,
        ...request,
      },
    }
  );
  return response.data;
};

export const getContact = async (
  subscriptionId: string,
  id: string
): Promise<IContact> => {
  const response = await api.get<IContact>(`/${subscriptionId}/contact/${id}`);
  return response.data;
};

export const postContact = async (
  subscriptionId: string,
  request: PostContactRequest
): Promise<IContact> => {
  const response = await api.post<IContact>(
    `/${subscriptionId}/contact`,
    request
  );
  return response.data;
};

export const putContact = async (
  subscriptionId: string,
  id: string,
  request: PutContactRequest
): Promise<void> => {
  await api.put(`/${subscriptionId}/contact/${id}`, request);
};

export const patchContact = async (
  subscriptionId: string,
  id: string,
  request: PatchContactRequest
): Promise<void> => {
  await api.patch(`/${subscriptionId}/contact/${id}`, request);
};

export const deleteContact = async (
  subscriptionId: string,
  id: string
): Promise<void> => {
  await api.delete(`/${subscriptionId}/contact/${id}`);
};
