import {
  GetCompanyEmailsRequest,
  ICompanyEmail,
  PostCompanyEmailRequest,
  PutCompanyEmailRequest,
  PatchCompanyEmailRequest,
} from "../models";

import { api } from "./api";

export const getCompanyEmails = async (
  subscriptionId: string,
  companyId: string,
  request?: GetCompanyEmailsRequest
): Promise<ICompanyEmail[]> => {
  const response = await api.get<ICompanyEmail[]>(
    `/${subscriptionId}/company/${companyId}/email`,
    {
      params: {
        ...request,
      },
    }
  );
  return response.data;
};

export const postCompanyEmail = async (
  subscriptionId: string,
  companyId: string,
  request: PostCompanyEmailRequest
): Promise<ICompanyEmail> => {
  const response = await api.post<ICompanyEmail>(
    `/${subscriptionId}/company/${companyId}/email`,
    request
  );
  return response.data;
};

export const putCompanyEmail = async (
  subscriptionId: string,
  companyId: string,
  id: string,
  request: PutCompanyEmailRequest
): Promise<void> => {
  await api.put(`/${subscriptionId}/company/${companyId}/email/${id}`, request);
};

export const patchCompanyEmail = async (
  subscriptionId: string,
  companyId: string,
  id: string,
  request: PatchCompanyEmailRequest
): Promise<void> => {
  await api.patch(`/${subscriptionId}/company/${companyId}/email/${id}`, request);

};

export const deleteCompanyEmail = async (
  subscriptionId: string,
  companyId: string,
  id: string
): Promise<void> => {
  await api.delete(`/${subscriptionId}/company/${companyId}/email/${id}`);
};
