import { GetAttachmentRequest, IAttachment } from "../../models";
import { api } from "../api";

export const getFileAttachments = async (
  subscriptionId: string,
  reference: string,
  referenceId: string,
  request?: GetAttachmentRequest
): Promise<IAttachment[]> => {
  const response = await api.get<IAttachment[]>(
    `/${subscriptionId}/${reference}/${referenceId}/attachments`,
    {
      params: {
        ...request,
      },
    }
  );
  return response.data;
};

export const createTaskAttachment = async (
  subscriptionId: string,
  taskId: string,
  file: File
): Promise<IAttachment> => {
  const formData = new FormData();
  formData.append("file", file);

  const response = await api.post<IAttachment>(
    `/${subscriptionId}/task/${taskId}/task-attachment`,
    formData,
    {
      headers: {
        "Content-type": "multipart/form-date",
      },
    }
  );
  return response.data;
};

export const deleteAttachment = async (
  subscriptionId: string,
  reference: string,
  referenceId: string,
  id: string
): Promise<void> => {
  await api.delete(
    `/${subscriptionId}/${reference}/${referenceId}/attachments/${id}`
  );
};
