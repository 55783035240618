import {
  IContactPhone,
  GetContactPhonesRequest,
  PostContactPhoneRequest,
  PutContactPhoneRequest,
  PatchContactPhoneRequest,
} from "../../models";

import { api } from "../api";

export const getContactPhones = async (
  subscriptionId: string,
  contactId: string,
  request?: GetContactPhonesRequest
): Promise<IContactPhone[]> => {
  const response = await api.get<IContactPhone[]>(
    `/${subscriptionId}/contact/${contactId}/phone`,
    {
      params: {
        ...request,
      },
    }
  );
  return response.data;
};

export const postContactPhone = async (
  subscriptionId: string,
  contactId: string,
  request: PostContactPhoneRequest
): Promise<IContactPhone> => {
  const response = await api.post<IContactPhone>(
    `/${subscriptionId}/contact/${contactId}/phone`,
    request
  );
  return response.data;
};

export const putContactPhone = async (
  subscriptionId: string,
  contactId: string,
  id: string,
  request: PutContactPhoneRequest
): Promise<void> => {
  await api.put(`/${subscriptionId}/contact/${contactId}/phone/${id}`, request);
};

export const patchContactPhone = async (
  subscriptionId: string,
  contactId: string,
  id: string,
  request: PatchContactPhoneRequest
): Promise<void> => {
  await api.patch(
    `/${subscriptionId}/contact/${contactId}/phone/${id}`,
    request
  );
};

export const deleteContactPhone = async (
  subscriptionId: string,
  contactId: string,
  id: string
): Promise<void> => {
  await api.delete(`/${subscriptionId}/contact/${contactId}/phone/${id}`);
};
