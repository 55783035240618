import {
  ITaskStatus,
  PatchTaskSequenceRequest,
  PatchTaskStatusRequest,
  PostTaskStatusRequest,
} from "../../models";
import { api } from "../api";

export const getTaskStatus = async (
  subscriptionId: string
): Promise<ITaskStatus[]> => {
  const response = await api.get<ITaskStatus[]>(
    `/${subscriptionId}/task/lifecycle/status`
  );
  return response.data;
};

export const postTaskStatus = async (
  subscriptionId: string,
  request: PostTaskStatusRequest
): Promise<ITaskStatus> => {
  const response = await api.post<ITaskStatus>(
    `/${subscriptionId}/task/lifecycle/status`,
    request
  );
  return response.data;
};

export const patchTaskStatus = async (
  subscriptionId: string,
  request: PatchTaskStatusRequest
): Promise<void> => {
  await api.patch(`/${subscriptionId}/task/lifecycle/status`, request);
};

export const patchTaskSequence = async (
  subscriptionId: string,
  request: PatchTaskSequenceRequest
): Promise<void> => {
  await api.patch(`/${subscriptionId}/task/lifecycle/sequence`, request);
};

export const deleteTaskStatus = async (
  subscriptionId: string,
  code: string
): Promise<void> => {
  await api.delete(`/${subscriptionId}/task/lifecycle/status/${code}`);
};
