import {
  GetMyobCompanyLookupRequest,
  GetMyobContactsRequest,
  GetMyobLogsRequest,
  IAccountConnectorCompanyLookup,
  IAccountingConnectorContact,
  IAccountingConnectorContactStatistics,
  IAccountingConnectorLogs,
  IMyobConnector,
  IMyobConnectorTenant,
  IPaginated,
  PatchMyobCompanyUnLinkRequest,
  PatchMyobContactRequest,
  PatchMyobContactSourceStatusTypeRequest,
  PostConnectMyobRequest,
  PostMyobCompanyLinkRequest,
} from "../models";

import { api } from "./api";

export const getMyobConnector = async (
  subscriptionId: string
): Promise<IMyobConnector> => {
  const response = await api.get<IMyobConnector>(
    `/${subscriptionId}/connector/myob/connect`
  );
  return response.data;
};

export const connectToMyob = async (
  subscriptionId: string,
  request: PostConnectMyobRequest
): Promise<IMyobConnector> => {
  const response = await api.post<IMyobConnector>(
    `/${subscriptionId}/connector/myob/connect`,
    request
  );
  return response.data;
};

export const disconnectFromMyob = async (
  subscriptionId: string,
  id: string
): Promise<void> => {
  await api.delete(
    `/${subscriptionId}/connector/myob/connect/${encodeURIComponent(id)}`
  );
};

export const getMyobConnectorTenants = async (
  subscriptionId: string
): Promise<IMyobConnectorTenant[]> => {
  const response = await api.get<{ tenants: IMyobConnectorTenant[] }>(
    `/${subscriptionId}/connector/myob/tenants`
  );
  return response.data.tenants;
};

export const connectToMyobTenat = async (
  subscriptionId: string,
  tenantId: string
): Promise<void> => {
  const response = await api.post<void>(
    `/${subscriptionId}/connector/myob/tenants/${tenantId}/connect`
  );
  return response.data;
};

export const getMyobContactStatistics = async (
  subscriptionId: string
): Promise<IAccountingConnectorContactStatistics[]> => {
  const response = await api.get<IAccountingConnectorContactStatistics[]>(
    `/${subscriptionId}/myob/contact/statistics`
  );
  return response.data;
};

export const getMyobContacts = async (
  subscriptionId: string,
  currentPage: number,
  itemsPerPage: number,
  request?: GetMyobContactsRequest
): Promise<IPaginated<IAccountingConnectorContact>> => {
  const response = await api.get<IPaginated<IAccountingConnectorContact>>(
    `/${subscriptionId}/myob/contact`,
    {
      params: {
        currentPage: currentPage,
        itemsPerPage: itemsPerPage,
        ...request,
      },
    }
  );
  return response.data;
};

export const patchMyobContact = async (
  subscriptionId: string,
  id: string,
  request: PatchMyobContactRequest
): Promise<void> => {
  await api.patch(`/${subscriptionId}/myob/contact/${id}`, request);
};

export const patchMyobContactSourceStatusType = async (
  subscriptionId: string,
  request: PatchMyobContactSourceStatusTypeRequest
): Promise<void> => {
  await api.patch(`/${subscriptionId}/myob/contact`, request);
};

export const getMyobCompanyLookup = async (
  subscriptionId: string,
  currentPage: number,
  itemsPerPage: number,
  request?: GetMyobCompanyLookupRequest
): Promise<IPaginated<IAccountConnectorCompanyLookup>> => {
  const response = await api.get<IPaginated<IAccountConnectorCompanyLookup>>(
    `/${subscriptionId}/myob/search`,
    {
      params: {
        currentPage: currentPage,
        itemsPerPage: itemsPerPage,
        ...request,
      },
    }
  );
  return response.data;
};

export const postMyobCompanyLink = async (
  subscriptionId: string,
  request: PostMyobCompanyLinkRequest
): Promise<void> => {
  const response = await api.post<void>(
    `/${subscriptionId}/myob/link`,
    request
  );
  return response.data;
};

export const patchMyobCompanyUnLink = async (
  subscriptionId: string,
  companyId: string,
  request: PatchMyobCompanyUnLinkRequest
): Promise<void> => {
  const response = await api.patch<void>(
    `/${subscriptionId}/myob/unlink/${companyId}`,
    request
  );
  return response.data;
};


export const getMyobLogs = async (
  subscriptionId: string,
  currentPage: number,
  itemsPerPage: number,
  request?: GetMyobLogsRequest
): Promise<IPaginated<IAccountingConnectorLogs>> => {
  const response = await api.get<IPaginated<IAccountingConnectorLogs>>(
    `/${subscriptionId}/myob/sync-contacts-logs`,
    {
      params: {
        currentPage: currentPage,
        itemsPerPage: itemsPerPage,
        ...request,
      },
    }
  );
  return response.data;
};