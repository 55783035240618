import {
  GetQuickbooksCompanyLookupRequest,
  GetQuickbooksContactsRequest,
  GetQuickbooksLogsRequest,
  IAccountConnectorCompanyLookup,
  IAccountingConnectorContact,
  IAccountingConnectorContactStatistics,
  IAccountingConnectorLogs,
  IPaginated,
  IQuickbooksConnector,
  IQuickbooksConnectorAuthorizer,
  PatchQuickbooksCompanyUnLinkRequest,
  PatchQuickbooksContactRequest,
  PatchQuickbooksContactSourceStatusTypeRequest,
  PostConnectQuickbooksRequest,
  PostQuickbooksCompanyLinkRequest,
} from "../models";

import { api } from "./api";

export const getQuickbooksConnectorAuthorizer = async (
  subscriptionId: string
): Promise<IQuickbooksConnectorAuthorizer> => {
  const response = await api.get<IQuickbooksConnectorAuthorizer>(
    `/${subscriptionId}/connector/quickbooks/connect/authorizer`
  );
  return response.data;
};

export const getQuickbooksConnector = async (
  subscriptionId: string
): Promise<IQuickbooksConnector> => {
  const response = await api.get<IQuickbooksConnector>(
    `/${subscriptionId}/connector/quickbooks/connect`
  );
  return response.data;
};

export const connectToQuickbooks = async (
  subscriptionId: string,
  request: PostConnectQuickbooksRequest
): Promise<IQuickbooksConnector> => {
  const response = await api.post<IQuickbooksConnector>(
    `/${subscriptionId}/connector/quickbooks/connect`,
    request
  );
  return response.data;
};

export const disconnectFromQuickbooks = async (
  subscriptionId: string,
  id: string
): Promise<void> => {
  await api.delete(
    `/${subscriptionId}/connector/quickbooks/connect/${encodeURIComponent(id)}`
  );
};

export const getQuickbooksContactStatistics = async (
  subscriptionId: string
): Promise<IAccountingConnectorContactStatistics[]> => {
  const response = await api.get<IAccountingConnectorContactStatistics[]>(
    `/${subscriptionId}/quickbooks/contact/statistics`
  );
  return response.data;
};

export const getQuickbooksContacts = async (
  subscriptionId: string,
  currentPage: number,
  itemsPerPage: number,
  request?: GetQuickbooksContactsRequest
): Promise<IPaginated<IAccountingConnectorContact>> => {
  const response = await api.get<IPaginated<IAccountingConnectorContact>>(
    `/${subscriptionId}/quickbooks/contact`,
    {
      params: {
        currentPage: currentPage,
        itemsPerPage: itemsPerPage,
        ...request,
      },
    }
  );
  return response.data;
};

export const patchQuickbooksContact = async (
  subscriptionId: string,
  id: string,
  request: PatchQuickbooksContactRequest
): Promise<void> => {
  await api.patch(`/${subscriptionId}/quickbooks/contact/${id}`, request);
};

export const patchQuickbooksContactSourceStatusType = async (
  subscriptionId: string,
  request: PatchQuickbooksContactSourceStatusTypeRequest
): Promise<void> => {
  await api.patch(`/${subscriptionId}/quickbooks/contact`, request);
};

export const getQuickbooksCompanyLookup = async (
  subscriptionId: string,
  currentPage: number,
  itemsPerPage: number,
  request?: GetQuickbooksCompanyLookupRequest
): Promise<IPaginated<IAccountConnectorCompanyLookup>> => {
  const response = await api.get<IPaginated<IAccountConnectorCompanyLookup>>(
    `/${subscriptionId}/quickbooks/search`,
    {
      params: {
        currentPage: currentPage,
        itemsPerPage: itemsPerPage,
        ...request,
      },
    }
  );
  return response.data;
};

export const postQuickbooksCompanyLink = async (
  subscriptionId: string,
  request: PostQuickbooksCompanyLinkRequest
): Promise<void> => {
  const response = await api.post<void>(
    `/${subscriptionId}/quickbooks/link`,
    request
  );
  return response.data;
};

export const patchQuickbooksCompanyUnLink = async (
  subscriptionId: string,
  companyId: string,
  request: PatchQuickbooksCompanyUnLinkRequest
): Promise<void> => {
  const response = await api.patch<void>(
    `/${subscriptionId}/quickbooks/unlink/${companyId}`,
    request
  );
  return response.data;
};

export const getQuickbooksLogs = async (
  subscriptionId: string,
  currentPage: number,
  itemsPerPage: number,
  request?: GetQuickbooksLogsRequest
): Promise<IPaginated<IAccountingConnectorLogs>> => {
  const response = await api.get<IPaginated<IAccountingConnectorLogs>>(
    `/${subscriptionId}/quickbooks/sync-contacts-logs`,
    {
      params: {
        currentPage: currentPage,
        itemsPerPage: itemsPerPage,
        ...request,
      },
    }
  );
  return response.data;
};