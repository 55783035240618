import { INotification, PatchNotificationRequest } from "../../models";

import { api } from "../api";

export const getNotification = async (): Promise<INotification> => {
  const response = await api.get<INotification>(`/notification`);
  return response.data;
};

export const patchNotification = async (
  request: PatchNotificationRequest
): Promise<void> => {
  await api.patch(`/notification`, request);
};
