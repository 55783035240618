import {
  GetXeroCompanyLookupRequest,
  GetXeroContactsRequest,
  GetXeroLogsRequest,
  IPaginated,
  IXeroCompanyLookup,
  IXeroConnector,
  IXeroConnectorLogs,
  IXeroContact,
  IXeroContactStatistics,
  PatchXeroCompanyUnLinkRequest,
  PatchXeroContactRequest,
  PatchXeroContactSourceStatusTypeRequest,
  PostConnectXeroRequest,
  PostXeroCompanyLinkRequest,
} from "../models";

import { api } from "./api";

export const getXeroConnector = async (
  subscriptionId: string
): Promise<IXeroConnector> => {
  const response = await api.get<IXeroConnector>(
    `/${subscriptionId}/connector/xero/connect`
  );
  return response.data;
};

export const connectToXero = async (
  subscriptionId: string,
  request: PostConnectXeroRequest
): Promise<IXeroConnector> => {
  const response = await api.post<IXeroConnector>(
    `/${subscriptionId}/connector/xero/connect`,
    request
  );
  return response.data;
};

export const disconnectFromXero = async (
  subscriptionId: string,
  id: string
): Promise<void> => {
  await api.delete(
    `/${subscriptionId}/connector/xero/connect/${encodeURIComponent(id)}`
  );
};

export const getXeroLogs = async (
  subscriptionId: string,
  currentPage: number,
  itemsPerPage: number,
  request?: GetXeroLogsRequest
): Promise<IPaginated<IXeroConnectorLogs>> => {
  const response = await api.get<IPaginated<IXeroConnectorLogs>>(
    `/${subscriptionId}/xero/sync-contacts-logs`,
    {
      params: {
        currentPage: currentPage,
        itemsPerPage: itemsPerPage,
        ...request,
      },
    }
  );
  return response.data;
};

export const getXeroContacts = async (
  subscriptionId: string,
  currentPage: number,
  itemsPerPage: number,
  request?: GetXeroContactsRequest
): Promise<IPaginated<IXeroContact>> => {
  const response = await api.get<IPaginated<IXeroContact>>(
    `/${subscriptionId}/xero/contact`,
    {
      params: {
        currentPage: currentPage,
        itemsPerPage: itemsPerPage,
        ...request,
      },
    }
  );
  return response.data;
};

export const patchXeroContact = async (
  subscriptionId: string,
  id: string,
  request: PatchXeroContactRequest
): Promise<void> => {
  await api.patch(`/${subscriptionId}/xero/contact/${id}`, request);
};

export const patchXeroContactSourceStatusType = async (
  subscriptionId: string,
  request: PatchXeroContactSourceStatusTypeRequest
): Promise<void> => {
  await api.patch(`/${subscriptionId}/xero/contact`, request);
};

export const getXeroContactStatistics = async (
  subscriptionId: string
): Promise<IXeroContactStatistics[]> => {
  const response = await api.get<IXeroContactStatistics[]>(
    `/${subscriptionId}/xero/contact/statistics`
  );
  return response.data;
};

export const getXeroCompanyLookup = async (
  subscriptionId: string,
  currentPage: number,
  itemsPerPage: number,
  request?: GetXeroCompanyLookupRequest
): Promise<IPaginated<IXeroCompanyLookup>> => {
  const response = await api.get<IPaginated<IXeroCompanyLookup>>(
    `/${subscriptionId}/xero/search`,
    {
      params: {
        currentPage: currentPage,
        itemsPerPage: itemsPerPage,
        ...request,
      },
    }
  );
  return response.data;
};

export const postXeroCompanyLink = async (
  subscriptionId: string,
  request: PostXeroCompanyLinkRequest
): Promise<void> => {
  const response = await api.post<void>(
    `/${subscriptionId}/xero/link`,
    request
  );
  return response.data;
};

export const patchXeroCompanyUnLink = async (
  subscriptionId: string,
  companyId: string,
  request: PatchXeroCompanyUnLinkRequest
): Promise<void> => {
  const response = await api.patch<void>(
    `/${subscriptionId}/xero/unlink/${companyId}`,
    request
  );
  return response.data;
};
