import { PostBrandingLogoRequest } from "../../models";
import { api } from "../api";

// export const getAccountSettingBrandingLogo = async (
//   subscriptionId: string
// ): Promise<IAccountSetting> => {
//   const response = await api.get<IAccountSetting>(
//     `/${subscriptionId}/account-setting/brand-logo`
//   );
//   return response.data;
// };

export const postAccountSettingBrandingLogo = async (
  subscriptionId: string,
  request: PostBrandingLogoRequest
): Promise<void> => {
  const formData = new FormData();
  formData.append("file", request.file);
  const response = await api.post(
    `/${subscriptionId}/account-setting/brand-logo`,
    formData,
    {
      headers: {
        "Content-type": "multipart/form-date",
      },
    }
  );
  return response.data;
};
