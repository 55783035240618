import { api } from "..";
import { IPaginated, IReleaseNotes } from "../../models";


// export const getReleaseNotes = async (
// ): Promise<IReleaseNotes[]> => {
//     const response = await api.get<IReleaseNotes[]>(
//         `/release-notes`
//     );
//     return response.data;
// };

export const getReleaseNotes = async (
    currentPage: number,
    itemsPerPage: number,
): Promise<IPaginated<IReleaseNotes>> => {
    const response = await api.get<IPaginated<IReleaseNotes>>(
        `/release-notes`,
        {
            params: {
                currentPage: currentPage,
                itemsPerPage: itemsPerPage,
            },
        }
    );
    return response.data;
};