import { CardProps } from "@mui/material";
import merge from 'lodash/merge';
import ReactApexChart from 'react-apexcharts';
import { fCurrency, fFloatNumber, fNumber, fPercent, fShortenNumber, secondsToDhms } from "@daxrm/utils";
import { useTheme, styled } from '@mui/material/styles';
import { BaseOptionChart } from "./chart-setting";
import { useResponsive } from "../../../hooks";

// ----------------------------------------------------------------------
const ChartStyle = styled('div')(({ theme }) => ({
    '& .apexcharts-legend': {
        width: 240,
        margin: 'auto',
        [theme.breakpoints.up('sm')]: {
            flexWrap: 'wrap',
            height: 160,
            width: '55%',
        },
    },
    // '& .apexcharts-datalabels-group': {
    //     display: 'none',
    // },
}));

// ----------------------------------------------------------------------

interface ReactApexDonutChartProps extends CardProps {
    chartData: {
        label: string;
        value: number;
    }[];
    chartColors?: string[];
    metricType?: string;
}

export const RADonutLegendRightChart = ({
    chartData,
    chartColors,
    metricType
}: ReactApexDonutChartProps) => {
    const theme = useTheme();
    const isDesktop = useResponsive('up', 'sm');
    const chartLabels = chartData.map((i) => i.label);
    const chartSeries = chartData.map((i) => i.value);

    const chartOptions = merge(BaseOptionChart(), {
        colors: chartColors,
        labels: chartLabels,
        stroke: { show: false, colors: [theme.palette.background.paper] },
        legend: {
            position: 'right',
            itemMargin: {
                horizontal: 10,
                vertical: 5,
            },
            formatter: (val: string, opts: { w: { globals: { series: { [x: string]: string; }; }; }; seriesIndex: string | number; }) => {
                let finalVal = '';
                if (val == null) {
                    finalVal = "";
                }
                if (val.length <= 25) {
                    finalVal = val;
                } else {
                    val = val.substring(0, 25);
                    finalVal = val + "...";
                }

                if (metricType && metricType === 'userEngagementDuration') {
                    return finalVal + " - " + secondsToDhms(opts.w.globals.series[opts.seriesIndex]);
                } else if (metricType && metricType === 'gsc_impressions') {
                    return finalVal + " - " + fShortenNumber(opts.w.globals.series[opts.seriesIndex]);
                } else if (metricType && metricType === 'gsc_position') {
                    return finalVal + " - " + fFloatNumber(opts.w.globals.series[opts.seriesIndex]);
                } else if (metricType && metricType === 'gsc_ctr') {
                    return finalVal + " - " + fPercent(Number(opts.w.globals.series[opts.seriesIndex]));
                } else if (metricType && metricType === 'fads_impressions') {
                    return finalVal + " - " + fShortenNumber(Number(opts.w.globals.series[opts.seriesIndex]));
                } else if (metricType && metricType === 'fads_ctr') {
                    return finalVal + " - " + fPercent(Number(opts.w.globals.series[opts.seriesIndex]));
                } else if (metricType && metricType === 'fads_cpc') {
                    return finalVal + " - " + fCurrency(Number(opts.w.globals.series[opts.seriesIndex]));
                } else if (metricType && metricType === 'bwt_crawl_errors') {
                    return finalVal + " - " + fShortenNumber(opts.w.globals.series[opts.seriesIndex]);
                } else {
                    return finalVal + " - " + opts.w.globals.series[opts.seriesIndex]
                }
            }
        },
        tooltip: {
            fillSeriesColor: false,
            y: {
                formatter: (seriesName: string) => {
                    if (metricType && metricType === 'userEngagementDuration') {
                        return secondsToDhms(seriesName);
                    } else {
                        return fNumber(seriesName);
                    }

                },
                title: {
                    formatter: (seriesName: string) => `${seriesName}`,
                },
            },
        },
        plotOptions: {
            pie: {
                donut: {
                    size: '60%',
                    labels: {
                        value: {
                            fontSize: (metricType && metricType === 'userEngagementDuration') ? 14 : 22,
                            formatter: (val: number | string) => {
                                if (metricType && metricType === 'userEngagementDuration') {
                                    return secondsToDhms(val);
                                }
                                else if (metricType && metricType === 'gsc_impressions') {
                                    return fShortenNumber(val);
                                } else if (metricType && metricType === 'gsc_position') {
                                    return fFloatNumber(val);
                                } else if (metricType && metricType === 'gsc_ctr') {
                                    return fPercent(Number(val));
                                } else {
                                    return fNumber(val);
                                }
                            },
                        },
                        total: {
                            formatter: (w: { globals: { seriesTotals: number[] } }) => {
                                const sum = w.globals.seriesTotals.reduce((a, b) => a + b, 0);
                                if (metricType && metricType === 'userEngagementDuration') {
                                    return secondsToDhms(sum);
                                } else if (metricType && metricType === 'gsc_impressions') {
                                    return fShortenNumber(sum);
                                } else if (metricType && metricType === 'gsc_position') {
                                    return fFloatNumber(sum);
                                } else if (metricType && metricType === 'gsc_ctr') {
                                    return fPercent(Number(sum));
                                } else {
                                    return fNumber(sum);
                                }
                            },
                        }

                    },
                },
            },
        },
        responsive: [
            {
                breakpoint: theme.breakpoints.values.sm,
                options: {
                    legend: {
                        position: 'bottom',
                        horizontalAlign: 'left',
                    },
                },
            },
        ],
    });

    return (
        <ChartStyle>
            <ReactApexChart type="donut" series={chartSeries} options={chartOptions} height={isDesktop ? 280 : 360} />
        </ChartStyle>
    );
}