import { IAccountServiceLookup } from "../../models";

import { api } from "../api";

export const getAccountServiceLookup = async (
  subscriptionId: string,
  accountId: string
): Promise<IAccountServiceLookup[]> => {
  const response = await api.get<IAccountServiceLookup[]>(
    `/${subscriptionId}/account/${accountId}/service/lookup`
  );
  return response.data;
};
