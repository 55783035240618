import {
  ISubscriptionInvoice,
  ISubscriptionUpcomingInvoice,
} from "../../models";
import { api } from "../api";

export const getSubscriptionInvoices = async (
  subscriptionId: string
): Promise<ISubscriptionInvoice[]> => {
  const response = await api.get<ISubscriptionInvoice[]>(
    `/${subscriptionId}/subscription/invoice`
  );
  return response.data;
};

export const getSubscriptionUpcomingInvoices = async (
  subscriptionId: string
): Promise<ISubscriptionUpcomingInvoice> => {
  const response = await api.get<ISubscriptionUpcomingInvoice>(
    `/${subscriptionId}/subscription/invoice/upcoming`
  );
  return response.data;
};
