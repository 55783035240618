import { fCurrencyNumber, fPercent, fShortenNumber, secondsToDhms } from "@daxrm/utils";
import { Box, CardProps } from "@mui/material";
import merge from 'lodash/merge';
import ReactApexChart from 'react-apexcharts';
import { BaseOptionChart } from "./chart-setting";

interface ReactApexColumnChartProps extends CardProps {
    chartLabels: string[] | string;
    chartData: {
        type?: string;
        fill?: string;
        name: string;
        data: number[];
    }[];
    combinedStyle?: any;
    chartColors?: string[];
    metricType?: string;
    isDistributed?: boolean;
}

export const RAColumnBarChart = ({
    combinedStyle,
    chartLabels,
    chartData,
    chartColors,
    metricType,
    isDistributed
}: ReactApexColumnChartProps) => {

    const chartOptions = merge(BaseOptionChart(), {
        colors: chartColors,
        chart: {
            height: "auto"
        },
        theme: {
            palette: 'palette2'
        },
        legend: {
            show: false
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent'],
        },
        xaxis: {
            categories: chartLabels,
            labels: {
                rotate: 0,
                trim: true,
                style: {
                    colors: combinedStyle.color,
                },
            }
        },
        yaxis: [{
            labels: {
                style: {
                    colors: combinedStyle.color,
                },
            }
        }],
        tooltip: {
            y: {
                formatter: (val: number) => {
                    if (
                        metricType &&
                        (
                            metricType === 'userEngagementDuration' ||
                            metricType === 'ga4-barChart-acquisitionDirectUserEngagement' ||
                            metricType === 'ga4-barChart-acquisitionSocialUserEngagement' ||
                            metricType === 'ga4-barChart-acquisitionReferralUserEngagement' ||
                            metricType === 'ga4-barChart-acquisitionDisplayUserEngagement' ||
                            metricType === 'ga4-barChart-audienceLocationUserEngagement' ||
                            metricType === 'ga4-barChart-audienceLanguageUserEngagement' ||
                            metricType === 'ga4-barChart-audienceBrowserUserEngagement' ||
                            metricType === 'ga4-barChart-audienceOperatingSystemUserEngagement' ||
                            metricType === 'ga4-barChart-conversionsUserEngagement' ||
                            metricType === 'ga4-barChart-eventsUserEngagement'
                        )
                    ) {
                        return secondsToDhms(val);
                    } else if (metricType === 'mads_impressions') {
                        return fShortenNumber(val);
                    } else if (
                        metricType === "mads_ctr" ||
                        metricType === "fads_ctr" ||
                        metricType === "fads-barChart-campaignsAvgCTR" ||
                        metricType === "fads-barChart-adSetsAvgCTR" ||
                        metricType === "fads-barChart-adsAvgCTR"
                    ) {
                        return fPercent(val);
                    } else if (
                        metricType === "mads_spend" ||
                        metricType === "mads_revenue" ||
                        metricType === "fads_cpc" ||
                        metricType === "fads-barChart-campaignsAvgCPC" ||
                        metricType === "fads-barChart-adSetsAvgCPC" ||
                        metricType === "fads-barChart-adsAvgCPC" ||
                        metricType === "fads-barChart-campaignsCPM" ||
                        metricType === "fads-barChart-adSetsCPM" ||
                        metricType === "fads-barChart-adsCPM" ||
                        metricType === "fads-barChart-campaignsSpend" ||
                        metricType === "fads-barChart-adSetsSpend" ||
                        metricType === "fads-barChart-adsSpend"
                    ) {
                        return `$${fCurrencyNumber(val)}`;
                    }
                    else {
                        return val;
                    }
                },
            },
            x: {
                show: true,
            },

        },
        plotOptions: {
            bar: {
                distributed: isDistributed
            }
        }
    });

    return (<>
        <Box sx={{ height: 1 }} dir="ltr">
            <ReactApexChart type="bar" series={chartData} options={chartOptions} height={combinedStyle?.height || 250} />
        </Box>
    </>);

}