import {
  GetAccountConnectorRequest,
  GetFacebookOrgConnectorAuthUrlRequest,
  GetMetaConnectorReportRequest,
  IFORGAccount,
  IFORGConnectedAccount,
  IFacebookConnectorAuthUrlResponse,
  IMetaConnector,
  IMetaConnectorReport,
  PatchFORGAccountRequest,
  PatchUnlinkMetaConnectedItemRequest,
  PostConnectMetaConnectorRequest,
} from "../../../models";

import { api } from "../../api";

export const getFacebookOrgConnectorAuthUrl = async (
  subscriptionId: string,
  request?: GetFacebookOrgConnectorAuthUrlRequest
): Promise<IFacebookConnectorAuthUrlResponse> => {
  const response = await api.get<IFacebookConnectorAuthUrlResponse>(
    `/${subscriptionId}/connector/meta/forg/auth-url`,
    {
      params: request,
    }
  );
  return response.data;
};

export const getMetaForgConnectors = async (
  subscriptionId: string
): Promise<IMetaConnector[]> => {
  const response = await api.get<IMetaConnector[]>(
    `/${subscriptionId}/connector/meta/forg`
  );
  return response.data;
};

export const connectToMetaForgConnector = async (
  subscriptionId: string,
  request: PostConnectMetaConnectorRequest
): Promise<IMetaConnector> => {
  const response = await api.post<IMetaConnector>(
    `/${subscriptionId}/connector/meta/forg/connect`,
    request
  );
  return response.data;
};

export const disconnectFromMetaForgConnector = async (
  subscriptionId: string,
  id: string
): Promise<void> => {
  await api.delete(
    `/${subscriptionId}/connector/meta/forg/disconnect/${encodeURIComponent(
      id
    )}`
  );
};

export const getMetaForgAccounts = async (
  subscriptionId: string,
  request?: GetAccountConnectorRequest
): Promise<IFORGAccount> => {
  const response = await api.get<IFORGAccount>(
    `/${subscriptionId}/account-connector/link`,
    {
      params: request,
    }
  );
  return response.data;
};

export const patchMetaForgAccount = async (
  subscriptionId: string,
  accountId: string,
  request: PatchFORGAccountRequest
): Promise<void> => {
  await api.patch(`/${subscriptionId}/account-connector/${accountId}`, request);
};

export const getMetaForgConnectedAccount = async (
  subscriptionId: string,
  accountId: string,
  request?: GetAccountConnectorRequest
): Promise<IFORGConnectedAccount> => {
  const response = await api.get<IFORGConnectedAccount>(
    `/${subscriptionId}/account-connector/${accountId}/list`,
    {
      params: request,
    }
  );
  return response.data;
};

export const unlinkMetaForgConnectedLocation = async (
  subscriptionId: string,
  accountId: string,
  request: PatchUnlinkMetaConnectedItemRequest
): Promise<void> => {
  await api.patch(
    `/${subscriptionId}/account-connector/${accountId}/unlink`,
    request
  );
};

export const getMetaForgAccountReport = async (
  subscriptionId: string,
  accountId: string,
  request?: GetMetaConnectorReportRequest
): Promise<IMetaConnectorReport> => {
  const response = await api.get<IMetaConnectorReport>(
    `/${subscriptionId}/account-connector/${accountId}/report`,
    {
      params: request,
    }
  );
  return response.data;
};
