import {
  GetCompanyNotesRequest,
  ICompanyNote,
  PatchCompanyNoteRequest,
  PostCompanyNoteRequest,
  PutCompanyNoteRequest,
} from "../models";

import { api } from "./api";

export const getCompanyNotes = async (
  subscriptionId: string,
  companyId: string,
  request?: GetCompanyNotesRequest
): Promise<ICompanyNote[]> => {
  const response = await api.get<ICompanyNote[]>(
    `/${subscriptionId}/company/${companyId}/note`,
    {
      params: {
        ...request,
      },
    }
  );
  return response.data;
};

export const postCompanyNote = async (
  subscriptionId: string,
  companyId: string,
  request: PostCompanyNoteRequest
): Promise<ICompanyNote> => {
  const response = await api.post<ICompanyNote>(
    `/${subscriptionId}/company/${companyId}/note`,
    request
  );
  return response.data;
};

export const putCompanyNote = async (
  subscriptionId: string,
  companyId: string,
  id: string,
  request: PutCompanyNoteRequest
): Promise<void> => {
  await api.put(`/${subscriptionId}/company/${companyId}/note/${id}`, request);
};

export const patchCompanyNote = async (
  subscriptionId: string,
  companyId: string,
  id: string,
  request: PatchCompanyNoteRequest
): Promise<void> => {
  await api.patch(
    `/${subscriptionId}/company/${companyId}/note/${id}`,
    request
  );
};

export const deleteCompanyNote = async (
  subscriptionId: string,
  companyId: string,
  id: string
): Promise<void> => {
  await api.delete(`/${subscriptionId}/company/${companyId}/note/${id}`);
};
