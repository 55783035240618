import {
  ExportReportRequest,
  ExportReportResponse,
  GetReportsRequest,
  GetWidgetDataRequest,
  GmbWidgetDataResponse,
  IGridWidgetHead,
  IPaginated,
  IReport,
  IReportTemplateLookup,
  PatchReportRequest,
  PostReportBrandingLogoRequest,
  PostReportRequest,
  PostReportTemplateCloneRequest,
  WidgetDataMode,
  WidgetRenderingMode,
  WidgetSubCategoryType,
  WidgetType,
} from "../../models";

import { api, apiCore } from "../api";
import { sleep } from "@daxrm/utils";
import { getMockGridWidgetBodyData } from ".";

export const getReportTemplatesLookup = async (
  subscriptionId: string,
  currentPage: number,
  itemsPerPage: number,
  request?: GetReportsRequest
): Promise<IPaginated<IReportTemplateLookup>> => {
  const response = await api.get<IPaginated<IReportTemplateLookup>>(
    `/${subscriptionId}/report-template/lookup`,
    {
      params: {
        currentPage: currentPage,
        itemsPerPage: itemsPerPage,
        ...request,
      },
    }
  );
  return response.data;
};

export const getReports = async (
  subscriptionId: string,
  currentPage: number,
  itemsPerPage: number,
  request?: GetReportsRequest
): Promise<IPaginated<IReport>> => {
  const response = await api.get<IPaginated<IReport>>(
    `/${subscriptionId}/report-template`,
    {
      params: {
        currentPage: currentPage,
        itemsPerPage: itemsPerPage,
        ...request,
      },
    }
  );
  return response.data;
};

export const getReport = async (
  subscriptionId: string,
  id: string
): Promise<IReport> => {
  const response = await api.get<IReport>(
    `/${subscriptionId}/report-template/${id}`
  );
  return response.data;
};

export const getReportViaInternalApi = async (
  id: string
): Promise<IReport> => {
  const response = await api.get<IReport>(
    `/report/${id}`
  );
  return response.data;
};

export const postReport = async (
  subscriptionId: string,
  request: PostReportRequest
): Promise<IReport> => {
  const response = await api.post<IReport>(
    `/${subscriptionId}/report-template`,
    request
  );
  return response.data;
};

export const patchReport = async (
  subscriptionId: string,
  id: string,
  request: PatchReportRequest
): Promise<void> => {
  await api.patch(`/${subscriptionId}/report-template/${id}`, request);
};

export const deleteReportTemplate = async (
  subscriptionId: string,
  id: string
): Promise<void> => {
  await api.delete(`/${subscriptionId}/report-template/${id}`);
};

export const getGmbStatisticsReviewsWidgetData = async (
  subscriptionId: string,
  accountId: string,
  request: GetWidgetDataRequest
): Promise<GmbWidgetDataResponse> => {
  return new Promise<GmbWidgetDataResponse>(async (resolver, rejector) => {
    await sleep(500);
    resolver({ data: 1021 });
  });
};

export const getWidgetData = async (
  subscriptionId: string,
  accountId: string,
  type: WidgetType,
  subCategory: WidgetSubCategoryType,
  mode: WidgetRenderingMode,
  dataMode: WidgetDataMode,
  request: GetWidgetDataRequest
): Promise<GmbWidgetDataResponse> => {
  switch (dataMode) {
    case "LIVEDATA":
      const response = await api.get<GmbWidgetDataResponse>(
        `/${subscriptionId}/report-widget-data/${accountId}/${type}`,
        {
          params: request,
        }
      );
      return response.data;
    case "SAMPLEDATA":
      return await _getMockWidgetData(
        subscriptionId,
        accountId,
        type,
        subCategory,
        request
      );
    default:
      throw new Error(`Invalid widget dataMode: ${dataMode}`);
  }
};

export const getExportWidgetData = async (
  subscriptionId: string,
  accountId: string,
  type: WidgetType,
  subCategory: WidgetSubCategoryType,
  mode: WidgetRenderingMode,
  dataMode: WidgetDataMode,
  request: GetWidgetDataRequest
): Promise<GmbWidgetDataResponse> => {
  switch (dataMode) {
    case "LIVEDATA":
      const response = await api.get<GmbWidgetDataResponse>(
        `/${subscriptionId}/report-widget-data/${accountId}/${type}`,
        {
          params: request,
        }
      );
      return response.data;
    case "SAMPLEDATA":
      return await _getMockWidgetData(
        subscriptionId,
        accountId,
        type,
        subCategory,
        request
      );
    default:
      throw new Error(`Invalid widget dataMode: ${dataMode}`);
  }
};

const _getMockWidgetData = async (
  subscriptionId: string,
  accountId: string,
  type: WidgetType,
  subCategory: WidgetSubCategoryType,
  request: GetWidgetDataRequest
): Promise<GmbWidgetDataResponse> => {
  return new Promise<GmbWidgetDataResponse>(async (resolver, rejector) => {
    await sleep(500);
    switch (subCategory) {
      case "stats":
      case "customstatistics":
        resolver({ data: (type === 'ga4-statistics-acquisitionAllUserEngagement') ? '2d, 10h, 25m, 50s' : 1021 });
        break;
      case "barChart":
        resolver({
          data: {
            chartLabels: [
              "01/01/2003",
              "02/01/2003",
              "03/01/2003",
              "04/01/2003",
              "05/01/2003",
              "06/01/2003",
              "07/01/2003",
              "08/01/2003",
              "09/01/2003",
              "10/01/2003",
              "11/01/2003",
            ],
            chartData: [
              {
                name: "Bar",
                fill: "solid",
                data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39],
              },
            ],
          },
        });
        break;
      case "lineChart":
        resolver({
          data: {
            chartLabels: [
              "01/01/2003",
              "02/01/2003",
              "03/01/2003",
              "04/01/2003",
              "05/01/2003",
              "06/01/2003",
              "07/01/2003",
              "08/01/2003",
              "09/01/2003",
              "10/01/2003",
              "11/01/2003",
            ],
            chartData: [
              {
                name: "Line",
                fill: "solid",
                data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39],
              },
            ],
          },
        });
        break;
      case "pieChart":
        resolver({
          data: {
            // chartColors: [],
            chartData: [
              { label: "Desktop Search", value: 665 },
              { label: "Mobile Search", value: 343 },
              { label: "Desktop Maps", value: 35 },
              { label: "Mobile Maps", value: 26 },
            ],
          },
        });
        break;
      case "horizontalBarChart":
        resolver({
          data: {
            chartData: [
              { label: "01/01/2023", value: 10 },
              { label: "02/01/2023", value: 6 },
              { label: "03/01/2023", value: 6 },
              { label: "04/01/2023", value: 5 },
              { label: "05/01/2023", value: 5 },
            ],
          },
        });
        break;
      case "progressBarChart":
        resolver({
          data: {
            chartData: [
              { label: "01/01/2023", value: 10, percentage: 10 },
              { label: "02/01/2023", value: 6, percentage: 6 },
              { label: "03/01/2023", value: 6, percentage: 6 },
              { label: "04/01/2023", value: 5, percentage: 5 },
              { label: "05/01/2023", value: 5, percentage: 5 },
            ],
          },
        });
        break;
      case "listGrid":
        resolver({
          data: {
            headItems: (await getGridWidgetHead(subscriptionId, accountId, type)).items,
            bodyItems: await getMockGridWidgetBodyData(type)
          }
        });
        break;
      default:
        break;
    }
  });
};

export const exportReport = async (
  subscriptionId: string,
  accountId: string,
  reportId: string,
  request: ExportReportRequest
): Promise<ExportReportResponse> => {
  const response = await api.get<ExportReportResponse>(
    `/report/${reportId}/export`,
    {
      params: request,
    }
  );
  return response.data;
};

export const exportViewReport = async (
  reportId: string,
  request: ExportReportRequest
): Promise<ExportReportResponse> => {
  const response = await apiCore.get<ExportReportResponse>(
    `/report/${reportId}/export`,
    {
      params: request,
    }
  );
  return response.data;
};

export const getGridWidgetHead = async (
  subscriptionId: string,
  accountId: string,
  // dataMode: WidgetDataMode,
  type: WidgetType,
): Promise<IGridWidgetHead> => {
  const response = await api.get<IGridWidgetHead>(
    `/${subscriptionId}/${accountId || "-"}/grid-widget-head/${type}`
  );
  return response.data;
  // switch (dataMode) {
  // case "LIVEDATA":
  // case "SAMPLEDATA":
  //   return {
  //     items: await getMockGridWidgetHeadData(type)
  //   }
  // default:
  //   throw new Error(`Invalid widget dataMode: ${dataMode}`);
  // }
};

export const uploadReportBrandingLogo = async (
  subscriptionId: string,
  request: PostReportBrandingLogoRequest
): Promise<void> => {
  const formData = new FormData();
  formData.append("file", request.file);
  const response = await api.post(
    `/${subscriptionId}/report-branding/brand-logo`,
    formData,
    {
      headers: {
        "Content-type": "multipart/form-date",
      },
    }
  );
  return response.data;
};

export const cloneReportTemplate = async (
  subscriptionId: string,
  request: PostReportTemplateCloneRequest
): Promise<void> => {
  const response = await api.post<void>(
    `/${subscriptionId}/report-template/clone`,
    request
  );
  return response.data;
};