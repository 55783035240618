import {
  GetNewNotificationCountRequest,
  GetNewNotificationCountResponse,
  GetNewNotificationsRequest,
  GetNewNotificationsResponse,
  GetNotificationsRequest,
  GetNotificationsResponse,
  IInAppNotification,
} from "../models";

import { api } from "./api";

export const getNewNotificationCount = async (
  subscriptionId: string,
  request?: GetNewNotificationCountRequest
): Promise<GetNewNotificationCountResponse> => {
  const response = await api.get<GetNewNotificationCountResponse>(
    `/${subscriptionId}/notifications/new/count`,
    {
      params: {
        ...request,
      },
    }
  );
  return response.data;
};

export const getNotifications = async (
  subscriptionId: string,
  request?: GetNotificationsRequest
): Promise<GetNotificationsResponse> => {
  const response = await api.get<GetNotificationsResponse>(
    `/${subscriptionId}/notifications`,
    {
      params: {
        nextToken: request?.nextToken,
        readStatus: request?.readStatus || undefined,
      },
    }
  );
  return response.data;
};

export const getNewNotifications = async (
  subscriptionId: string,
  request: GetNewNotificationsRequest
): Promise<GetNewNotificationsResponse> => {
  const response = await api.get<GetNewNotificationsResponse>(
    `/${subscriptionId}/notifications/new`,
    {
      params: {
        afterDateTime: request.afterDateTime,
      },
    }
  );
  return response.data;
};

export const markNotificationAsRead = async (
  subscriptionId: string,
  dateTime: number
): Promise<IInAppNotification> => {
  const response = await api.patch<IInAppNotification>(
    `/${subscriptionId}/notifications/${dateTime}/read`,
    {}
  );
  return response.data;
};

export const markNotificationAsUnRead = async (
  subscriptionId: string,
  dateTime: number
): Promise<IInAppNotification> => {
  const response = await api.patch<IInAppNotification>(
    `/${subscriptionId}/notifications/${dateTime}/unread`,
    {}
  );
  return response.data;
};

export const markNotificationsAsViewed = async (
  subscriptionId: string,
  fromDateTime: number
): Promise<void> => {
  await api.patch<void>(`/${subscriptionId}/notifications/viewed`, {
    fromDateTime: fromDateTime,
  });
};
