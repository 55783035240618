import { PatchAccountConnectorRequest } from "../../models";

import { api } from "../api";

export const patchAccountConnectors = async (
  subscriptionId: string,
  request: PatchAccountConnectorRequest
): Promise<void> => {
  await api.patch(`/${subscriptionId}/account-integration`, request);
};
