import {
  IPaginated,
  IAccountLookup,
  GetAccountLookupRequest,
} from "../../models";

import { api } from "../api";

export const getAccountLookup = async (
  subscriptionId: string,
  currentPage: number,
  itemsPerPage: number,
  request?:GetAccountLookupRequest
): Promise<IPaginated<IAccountLookup>> => {
  const response = await api.get<IPaginated<IAccountLookup>>(
    `/${subscriptionId}/account/lookup`,
    {
      params: {
        currentPage: currentPage,
        itemsPerPage: itemsPerPage,
        ...request,
      },
    }
  );
  return response.data;
};
