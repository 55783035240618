import {
  IContactAddress,
  GetContactAddresssRequest,
  PostContactAddressRequest,
  PutContactAddressRequest,
  PatchContactAddressRequest,
} from "../../models";

import { api } from "../api";

export const getContactAddresses = async (
  subscriptionId: string,
  contactId: string,
  request?: GetContactAddresssRequest
): Promise<IContactAddress[]> => {
  const response = await api.get<IContactAddress[]>(
    `/${subscriptionId}/contact/${contactId}/address`,
    {
      params: {
        ...request,
      },
    }
  );
  return response.data;
};

export const postContactAddress = async (
  subscriptionId: string,
  contactId: string,
  request: PostContactAddressRequest
): Promise<IContactAddress> => {
  const response = await api.post<IContactAddress>(
    `/${subscriptionId}/contact/${contactId}/address`,
    request
  );
  return response.data;
};

export const putContactAddress = async (
  subscriptionId: string,
  contactId: string,
  id: string,
  request: PutContactAddressRequest
): Promise<void> => {
  await api.put(
    `/${subscriptionId}/contact/${contactId}/address/${id}`,
    request
  );
};

export const patchContactAddress = async (
  subscriptionId: string,
  contactId: string,
  id: string,
  request: PatchContactAddressRequest
): Promise<void> => {
  await api.patch(
    `/${subscriptionId}/contact/${contactId}/address/${id}`,
    request
  );
};

export const deleteContactAddress = async (
  subscriptionId: string,
  contactId: string,
  id: string
): Promise<void> => {
  await api.delete(`/${subscriptionId}/contact/${contactId}/address/${id}`);
};
