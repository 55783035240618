import {
  GetSerpLocationLookupRequest,
  ISerpLocationLookup,
} from "../../models";

import { api } from "../api";

export const getSerpLocationLookup = async (
  subscriptionId: string,
  serpId: string,
  request?: GetSerpLocationLookupRequest
): Promise<ISerpLocationLookup[]> => {
  const response = await api.get<ISerpLocationLookup[]>(
    `/${subscriptionId}/serp/${serpId}/location-lookup`,
    {
      params: {
        ...request,
      },
    }
  );
  return response.data;
};
