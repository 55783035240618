import {
  GetServicesRequest,
  IAccount,
  IPaginated,
  PatchAccountRequest,
  PatchAccountStatusRequest,
  PostAccountRequest,
} from "../../models";
import { api } from "../api";

export const getAccounts = async (
  subscriptionId: string,
  currentPage: number,
  itemsPerPage: number,
  request?: GetServicesRequest
): Promise<IPaginated<IAccount>> => {
  const response = await api.get<IPaginated<IAccount>>(
    `/${subscriptionId}/account`,
    {
      params: {
        currentPage: currentPage,
        itemsPerPage: itemsPerPage,
        ...request,
      },
    }
  );
  return response.data;
};

export const getAccount = async (
  subscriptionId: string,
  id: string
): Promise<IAccount> => {
  const response = await api.get<IAccount>(`/${subscriptionId}/account/${id}`);
  return response.data;
};

export const postAccount = async (
  subscriptionId: string,
  request: PostAccountRequest
): Promise<IAccount> => {
  const response = await api.post<IAccount>(
    `/${subscriptionId}/account`,
    request
  );
  return response.data;
};

export const patchAccount = async (
  subscriptionId: string,
  id: string,
  request: PatchAccountRequest
): Promise<void> => {
  await api.patch(`/${subscriptionId}/account/${id}`, request);
};

export const patchAccountStatus = async (
  subscriptionId: string,
  id: string,
  request: PatchAccountStatusRequest
): Promise<void> => {
  await api.patch(`/${subscriptionId}/account/${id}/status`, request);
};

export const deleteAccount = async (
  subscriptionId: string,
  id: string
): Promise<void> => {
  await api.delete(`/${subscriptionId}/account/${id}`);
};
