import {
  GetUsersRequest,
  IPaginated,
  IUser,
  IUserInfo,
  PatchUserRequest,
  PostUserRequest,
} from "../../models";
import { api } from "../api";

export const getUsers = async (
  subscriptionId: string,
  currentPage: number,
  itemsPerPage: number,
  request?: GetUsersRequest
): Promise<IPaginated<IUser>> => {
  const response = await api.get<IPaginated<IUser>>(`/${subscriptionId}/user`, {
    params: {
      currentPage: currentPage,
      itemsPerPage: itemsPerPage,
      ...request,
    },
  });
  return response.data;
};

export const getUser = async (
  subscriptionId: string,
  id: string
): Promise<IUserInfo> => {
  const response = await api.get<IUserInfo>(`/${subscriptionId}/user/${id}`);
  return response.data;
};

export const postUser = async (
  subscriptionId: string,
  request: PostUserRequest
): Promise<IUser> => {
  const response = await api.post<IUser>(`/${subscriptionId}/user`, request);
  return response.data;
};

export const patchUser = async (
  subscriptionId: string,
  id: string,
  request: PatchUserRequest
): Promise<void> => {
  await api.patch(`/${subscriptionId}/user/${id}`, request);
};
