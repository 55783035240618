import {
  IContactNote,
  GetContactNotesRequest,
  PostContactNoteRequest,
  PutContactNoteRequest,
  PatchContactNoteRequest,
} from "../../models";

import { api } from "../api";

export const getContactNotes = async (
  subscriptionId: string,
  contactId: string,
  request?: GetContactNotesRequest
): Promise<IContactNote[]> => {
  const response = await api.get<IContactNote[]>(
    `/${subscriptionId}/contact/${contactId}/note`,
    {
      params: {
        ...request,
      },
    }
  );
  return response.data;
};

export const postContactNote = async (
  subscriptionId: string,
  contactId: string,
  request: PostContactNoteRequest
): Promise<IContactNote> => {
  const response = await api.post<IContactNote>(
    `/${subscriptionId}/contact/${contactId}/note`,
    request
  );
  return response.data;
};

export const putContactNote = async (
  subscriptionId: string,
  contactId: string,
  id: string,
  request: PutContactNoteRequest
): Promise<void> => {
  await api.put(`/${subscriptionId}/contact/${contactId}/note/${id}`, request);
};

export const patchContactNote = async (
  subscriptionId: string,
  contactId: string,
  id: string,
  request: PatchContactNoteRequest
): Promise<void> => {
  await api.patch(
    `/${subscriptionId}/contact/${contactId}/note/${id}`,
    request
  );
};

export const deleteContactNote = async (
  subscriptionId: string,
  contactId: string,
  id: string
): Promise<void> => {
  await api.delete(`/${subscriptionId}/contact/${contactId}/note/${id}`);
};
