import { Box, BoxProps } from "@mui/material";
import { BaseOptionChart } from "./chart-setting";
import ReactApexChart from 'react-apexcharts';
import merge from 'lodash/merge';
import { fCurrencyNumber, fPercent, fShortenNumber, secondsToDhms } from "@daxrm/utils";

interface ReactApexLineChartProps extends BoxProps {
    chartLabels: string[] | string;
    chartData: {
        type?: string;
        fill?: string;
        name: string;
        data: number[];
    }[];
    combinedStyle?: any;
    chartColors?: string[];
    metricType?: string;
    isYaxisReversed?: boolean;
    isYaxisOpposite?: boolean;
}

export const RALineChart = ({
    combinedStyle = { color: "#919EAB" },
    chartLabels,
    chartData,
    chartColors,
    metricType,
    isYaxisReversed = false,
    isYaxisOpposite = false
}: ReactApexLineChartProps) => {
    const chartOptions = merge(BaseOptionChart(), {
        colors: chartColors,
        legend: { position: 'top', horizontalAlign: 'right' },
        chart: {
            height: "auto"
        },
        grid: {
            padding: {
                right: 35
            }
        },
        markers: {
            size: 4,
            hover: {
                size: 6,
                sizeOffset: 6
            }
        },
        xaxis: {
            categories: chartLabels,
            labels: {
                rotate: 0,
                trim: false,
                style: {
                    colors: combinedStyle.color,
                },
            }
        },
        yaxis: [
            {
                reversed: isYaxisReversed,
                title: {
                    text: isYaxisOpposite ? 'Impressions' : '',
                },
                labels: {
                    style: {
                        colors: combinedStyle.color,
                    },
                    formatter: (val: number) => {
                        if (metricType &&
                            (
                                metricType === "userEngagementDuration" ||
                                metricType === "ga4-lineChart-acquisitionAllUserEngagement" ||
                                metricType === "ga4-lineChart-acquisitionOrganicSearchUserEngagement" ||
                                metricType === "ga4-lineChart-acquisitionPaidSearchUserEngagement" ||
                                metricType === "ga4-lineChart-acquisitionDirectUserEngagement" ||
                                metricType === "ga4-lineChart-acquisitionSocialUserEngagement" ||
                                metricType === "ga4-lineChart-acquisitionReferralUserEngagement" ||
                                metricType === "ga4-lineChart-acquisitionDisplayUserEngagement" ||
                                metricType === "ga4-lineChart-audienceLanguageUserEngagement" ||
                                metricType === "ga4-lineChart-audienceDevicesUserEngagement" ||
                                metricType === "ga4-lineChart-audienceBrowserUserEngagement" ||
                                metricType === "ga4-lineChart-audienceOperatingSystemUserEngagement" ||
                                metricType === "ga4-lineChart-conversionsUserEngagement" ||
                                metricType === "ga4-lineChart-pagesAllUserEngagement" ||
                                metricType === "ga4-lineChart-pagesPathUserEngagement" ||
                                metricType === "ga4-lineChart-pagesTitleUserEngagement" ||
                                metricType === "ga4-lineChart-pagesContentGroupUserEngagement" ||
                                metricType === "ga4-lineChart-eventsUserEngagement"
                            )
                        ) {
                            return secondsToDhms(val);
                        } else if (
                            metricType === "gsc_impressions" ||
                            metricType === "mads_impressions" ||
                            metricType === "gsc-lineChart-topQueriesImpressions" ||
                            metricType === "gsc-lineChart-topPagesImpressions" ||
                            metricType === "gsc-lineChart-countriesImpressions" ||
                            metricType === "gsc-lineChart-devicesImpressions"
                        ) {
                            return fShortenNumber(val);
                        } else if (
                            metricType === "gsc_ctr" ||
                            metricType === "mads_ctr" ||
                            metricType === "gsc-lineChart-topQueriesCTR" ||
                            metricType === "gsc-lineChart-topPagesCTR" ||
                            metricType === "gsc-lineChart-countriesCTR" ||
                            metricType === "gsc-lineChart-devicesCTR" ||
                            metricType === "fads-lineChart-campaignsAvgCTR" ||
                            metricType === "fads-lineChart-adSetsAvgCTR" ||
                            metricType === "fads-lineChart-adsAvgCTR"
                        ) {
                            return fPercent(val);
                        } else if (
                            metricType === "mads_spend" ||
                            metricType === "mads_revenue" ||
                            metricType === "fads-lineChart-campaignsCPM" ||
                            metricType === "fads-lineChart-adSetsCPM" ||
                            metricType === "fads-lineChart-adsCPM" ||
                            metricType === "fads-lineChart-campaignsSpend" ||
                            metricType === "fads-lineChart-adSetsSpend" ||
                            metricType === "fads-lineChart-adsSpend" ||
                            metricType === "fads-lineChart-campaignsConversionsRate" ||
                            metricType === "fads-lineChart-adSetsConversionsRate" ||
                            metricType === "fads-lineChart-adsConversionsRate"
                        ) {
                            return `$${fCurrencyNumber(val)}`;
                        } else if (
                            metricType === "engagementRate" ||
                            metricType === "userKeyEventRate"
                        ) {
                            return fPercent(val * 100);
                        } else {
                            return val;
                        }
                    }
                }
            },
            {
                show: isYaxisOpposite,
                opposite: true,
                title: {
                    text: 'Clicks',
                },
                labels: {
                    style: {
                        colors: combinedStyle.color,
                    },
                }
            }],
        tooltip: {
            y: {
                formatter: (val: number) => {
                    if (metricType &&
                        (
                            metricType === "userEngagementDuration" ||
                            metricType === "ga4-lineChart-acquisitionAllUserEngagement" ||
                            metricType === "ga4-lineChart-acquisitionOrganicSearchUserEngagement" ||
                            metricType === "ga4-lineChart-acquisitionPaidSearchUserEngagement" ||
                            metricType === "ga4-lineChart-acquisitionDirectUserEngagement" ||
                            metricType === "ga4-lineChart-acquisitionSocialUserEngagement" ||
                            metricType === "ga4-lineChart-acquisitionReferralUserEngagement" ||
                            metricType === "ga4-lineChart-acquisitionDisplayUserEngagement" ||
                            metricType === "ga4-lineChart-audienceLanguageUserEngagement" ||
                            metricType === "ga4-lineChart-audienceDevicesUserEngagement" ||
                            metricType === "ga4-lineChart-audienceBrowserUserEngagement" ||
                            metricType === "ga4-lineChart-audienceOperatingSystemUserEngagement" ||
                            metricType === "ga4-lineChart-conversionsUserEngagement" ||
                            metricType === "ga4-lineChart-pagesAllUserEngagement" ||
                            metricType === "ga4-lineChart-pagesPathUserEngagement" ||
                            metricType === "ga4-lineChart-pagesTitleUserEngagement" ||
                            metricType === "ga4-lineChart-pagesContentGroupUserEngagement" ||
                            metricType === "ga4-lineChart-eventsUserEngagement"
                        )
                    ) {
                        return secondsToDhms(val);
                    } else if (
                        metricType === "gsc_impressions" ||
                        metricType === "mads_impressions" ||
                        metricType === "gsc-lineChart-topQueriesImpressions" ||
                        metricType === "gsc-lineChart-topPagesImpressions" ||
                        metricType === "gsc-lineChart-countriesImpressions" ||
                        metricType === "gsc-lineChart-devicesImpressions"
                    ) {
                        return fShortenNumber(val);
                    } else if (
                        metricType === "gsc_ctr" ||
                        metricType === "mads_ctr" ||
                        metricType === "gsc-lineChart-topQueriesCTR" ||
                        metricType === "gsc-lineChart-topPagesCTR" ||
                        metricType === "gsc-lineChart-countriesCTR" ||
                        metricType === "gsc-lineChart-devicesCTR" ||
                        metricType === "fads-lineChart-campaignsAvgCTR" ||
                        metricType === "fads-lineChart-adSetsAvgCTR" ||
                        metricType === "fads-lineChart-adsAvgCTR"
                    ) {
                        return fPercent(val);
                    } else if (
                        metricType === "mads_spend" ||
                        metricType === "mads_revenue" ||
                        metricType === "fads-lineChart-campaignsCPM" ||
                        metricType === "fads-lineChart-adSetsCPM" ||
                        metricType === "fads-lineChart-adsCPM" ||
                        metricType === "fads-lineChart-campaignsSpend" ||
                        metricType === "fads-lineChart-adSetsSpend" ||
                        metricType === "fads-lineChart-adsSpend" ||
                        metricType === "fads-lineChart-campaignsConversionsRate" ||
                        metricType === "fads-lineChart-adSetsConversionsRate" ||
                        metricType === "fads-lineChart-adsConversionsRate"
                    ) {
                        return `$${fCurrencyNumber(val)}`;
                    } else if (
                        metricType === "engagementRate" ||
                        metricType === "userKeyEventRate"
                    ) {
                        return fPercent(val * 100);
                    } else {
                        return val;
                    }
                },
            },
            x: {
                show: true,
            },
        },
    });

    return (<>
        <Box sx={{ height: 1 }} dir="ltr">
            <ReactApexChart type="line" series={chartData} options={chartOptions} height={combinedStyle?.height || 300} />
        </Box>
    </>);
}
