// @mui
import { Box, BoxProps } from '@mui/material';

import { BaseOptionChart } from './chart-setting';
import ReactApexChart from 'react-apexcharts';
import { fNumber } from '@daxrm/utils';
import merge from 'lodash/merge';
import { useResponsive } from "../../../hooks";

interface RABarChartProps extends BoxProps {
  chartData: {
    label: string;
    value: number;
  }[];
  chartColors?: string[];
  metricType?: string;
}

export function RABarChart({
  chartData,
  chartColors,
  metricType,
  ...bpxProps
}: RABarChartProps) {
  const isDesktop = useResponsive('up', 'sm');

  const chartLabels = chartData.map((i) => i.label);
  const chartSeries = chartData.map((i) => i.value);

  const chartOptions = merge(BaseOptionChart(), {
    colors: chartColors,
    tooltip: {
      marker: { show: false },
      y: {
        formatter: (seriesName: string) => fNumber(seriesName),
        title: {
          formatter: () => '',
        },
      },
    },
    plotOptions: {
      bar: { horizontal: true, barHeight: '80%', borderRadius: 2 },
    },
    xaxis: {
      categories: chartLabels,
    },
  });

  return (

    <Box {...bpxProps} sx={{ ...bpxProps.sx, mx: 3 }} dir="ltr">
      <ReactApexChart
        type="bar"
        series={[{ data: chartSeries }]}
        options={chartOptions}
        height={isDesktop ? 280 : 360}
      />
    </Box>

  );
}
