import {
  GetContactLookupRequest,
  IContactLookup,
  IPaginated,
} from "../../models";

import { api } from "../api";

export const getContactLookup = async (
  subscriptionId: string,
  currentPage: number,
  itemsPerPage: number,
  request?: GetContactLookupRequest
): Promise<IPaginated<IContactLookup>> => {
  const response = await api.get<IPaginated<IContactLookup>>(
    `/${subscriptionId}/contact-lookup`,
    {
      params: {
        currentPage: currentPage,
        itemsPerPage: itemsPerPage,
        ...request,
      },
    }
  );
  return response.data;
};
