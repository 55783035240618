import { GetRoleLookupRequest, IRoleLookup, IPaginated } from "../../models";

import { api } from "../api";

export const getRoleLookup = async (
  subscriptionId: string,
  currentPage: number,
  itemsPerPage: number,
  request?: GetRoleLookupRequest
): Promise<IPaginated<IRoleLookup>> => {
  const response = await api.get<IPaginated<IRoleLookup>>(
    `/${subscriptionId}/role/role-lookup`,
    {
      params: {
        currentPage: currentPage,
        itemsPerPage: itemsPerPage,
        ...request,
      },
    }
  );
  return response.data;
};
