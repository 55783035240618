import { Box, Divider, Drawer, Grid, Stack, Typography } from "@mui/material"
import { IconButtonAnimate, Iconify } from "..";

import { ReactNode } from "react";
import { useResponsive } from "../../..";

interface ISidebarFormContainerProps {
    open: boolean,
    children: (props: { close: VoidFunction }) => ReactNode;
    title?: ReactNode;
    whoColumns?: ReactNode;
    headerChildren?: ReactNode;
    onClose?: VoidFunction;
    minWidthValue?: string;
    drawerVariant?: 'persistent';
}

export const SidebarFormContainer = ({ open, children, title, whoColumns, headerChildren, onClose = () => { }, minWidthValue, drawerVariant }: ISidebarFormContainerProps) => {
    const isMdDown = useResponsive("down", "md");

    const onDrawerCloseHandler = (_event: {}, _reason: "backdropClick" | "escapeKeyDown") => {
        onClose();
    }

    const onCloseHandler = () => {
        onClose();
    }

    return <>
        <Drawer
            variant={drawerVariant}
            anchor={isMdDown ? "bottom" : "right"}
            open={open}
            onClose={onDrawerCloseHandler}
            PaperProps={{
                sx: {
                    minWidth: (theme) => isMdDown ? undefined : minWidthValue ?
                        (minWidthValue === 'xs') ? theme.breakpoints.values.xs :
                            (minWidthValue === 'sm') ? theme.breakpoints.values.sm :
                                (minWidthValue === 'md') ? theme.breakpoints.values.md :
                                    (minWidthValue === 'lg') ? theme.breakpoints.values.lg :
                                        (minWidthValue === 'xl') ? theme.breakpoints.values.xl :
                                            theme.breakpoints.values.md : theme.breakpoints.values.md
                }
            }}
        >
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ pt: 2, pr: 1, pb: 1, pl: 2 }}
            >
                <Grid container>
                    <Grid item xs={12} md={!!whoColumns ? 7 : 12}>
                        <Box>
                            {typeof title === "string"
                                ? <Typography variant="subtitle1">{title}</Typography>
                                : title
                            }
                        </Box>
                    </Grid>
                    {!!whoColumns && <>
                        <Grid item xs={12} md={5}>
                            <Box>
                                {whoColumns}
                            </Box>
                        </Grid>
                    </>}
                </Grid>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-end"
                    spacing={1}
                >
                    {headerChildren}
                    <IconButtonAnimate onClick={onCloseHandler} size="medium">
                        <Iconify icon={'mdi:close-circle-outline'} />
                    </IconButtonAnimate>
                </Stack>
            </Stack>
            <Divider sx={{ borderStyle: 'dashed' }} />
            <>
                {
                    children({
                        close: onCloseHandler
                    })
                }
            </>
        </Drawer>
    </>
}