import { format, formatDistanceToNow, getTime } from "date-fns";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";

const BROWSER_TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;

export function fToISO1806Date(date: Date | string | number) {
  return format(new Date(date), "yyyy-MM-dd");
}

export const toLocalDate = (date: Date | string | number): Date => {
  return utcToZonedTime(date, BROWSER_TIMEZONE);
};

export const toUtcDate = (date: Date | string | number): Date => {
  return zonedTimeToUtc(date, BROWSER_TIMEZONE);
};

export function fDate(
  date: Date | string | number,
  template: string = "MM/dd/yyyy"
) {
  return format(new Date(date), template);
}

export function fDateToLocal(
  date: Date | string | number,
  template: string = "MM/dd/yyyy"
) {
  return format(toLocalDate(date), template);
}

export function fDateTime(
  date: Date | string | number,
  template: string = "MM/dd/yyyy hh:mm:ss a"
) {
  return format(new Date(date), template);
}

export function fDateTimeToLocal(
  date: Date | string | number,
  template: string = "MM/dd/yyyy hh:mm:ss a"
) {
  return format(toLocalDate(date), template);
}

export function fTimestamp(date: Date | string | number) {
  return getTime(new Date(date));
}

export function fTimestampToLocal(date: Date | string | number) {
  return getTime(toLocalDate(date));
}

export function fToNow(date: Date | string | number) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
  });
}

export function fToNowToLocal(date: Date | string | number) {
  return formatDistanceToNow(toLocalDate(date), {
    addSuffix: true,
  });
}

export function secondsToDhms(seconds: string | number) {
  seconds = Number(seconds);
  let d = Math.floor((seconds % (86400 * 30)) / 86400);
  let h = Math.floor((seconds % 86400) / 3600);
  let m = Math.floor((seconds % 3600) / 60);
  let s = Math.floor((seconds % 3600) % 60);

  let dDisplay = d > 0 ? d + "d, " : "";
  let hDisplay = h > 0 ? h + "h, " : "";
  let mDisplay = m > 0 ? m + "m, " : "";
  let sDisplay = s > 0 ? s + "s" : "";

  return dDisplay + hDisplay + mDisplay + sDisplay;
}

export function timeDuration(miliSeconds: string | number) {
  let seconds = Number(miliSeconds) / 1000;
  let h = Math.floor((seconds % 86400) / 3600);
  let m = Math.floor((seconds % 3600) / 60);
  let s = Math.floor((seconds % 3600) % 60);

  let hDisplay = h > 0 ? (h < 9 ? "0" + h : h) + ":" : "00:";
  let mDisplay = m > 0 ? (m < 9 ? "0" + m : m) + ": " : "00:";
  let sDisplay = s > 0 ? (s < 9 ? "0" + s : s) : "00";

  return hDisplay + mDisplay + sDisplay;
}

export function convertJsonDateToDate(
  microsoftJsonDateString: string
): Date | null {
  // Extract the timestamp from the Microsoft JSON date string
  // @ts-ignore: Object is possibly 'null'.
  const timestamp = parseInt(microsoftJsonDateString?.match(/\d+/)[0], 10);

  try {
    // Create a Date object from the timestamp
    const dateObject = new Date(timestamp);

    // Check if the Date object is valid
    if (isNaN(dateObject.getTime())) {
      throw new Error("Invalid date");
    }

    return dateObject;
  } catch (error) {
    console.error(`Error converting date: ${error}`);
    return null;
  }
}
