import {
  GetServiceCategoryLookupRequest,
  IServiceCategoryLookup,
} from "../../models";

import { api } from "../api";

export const getServiceCategoryLookup = async (
  subscriptionId: string,
  request?: GetServiceCategoryLookupRequest
): Promise<IServiceCategoryLookup[]> => {
  const response = await api.get<IServiceCategoryLookup[]>(
    `/${subscriptionId}/service/category-lookup`,
    {
      params: {
        ...request,
      },
    }
  );
  return response.data;
};
