import {
    GetCompanyAddresssRequest,
    ICompanyAddress,
    PostCompanyAddressRequest,
    PutCompanyAddressRequest,
    PatchCompanyAddressRequest,
  } from "../models";
  
  import { api } from "./api";
  
  export const getCompanyAddresses = async (
    subscriptionId: string,
    companyId: string,
    request?: GetCompanyAddresssRequest
  ): Promise<ICompanyAddress[]> => {
    const response = await api.get<ICompanyAddress[]>(
      `/${subscriptionId}/company/${companyId}/address`,
      {
        params: {
          ...request,
        },
      }
    );
    return response.data;
  };
  
  export const postCompanyAddress = async (
    subscriptionId: string,
    companyId: string,
    request: PostCompanyAddressRequest
  ): Promise<ICompanyAddress> => {
    const response = await api.post<ICompanyAddress>(
      `/${subscriptionId}/company/${companyId}/address`,
      request
    );
    return response.data;
  };
  
  export const putCompanyAddress = async (
    subscriptionId: string,
    companyId: string,
    id: string,
    request: PutCompanyAddressRequest
  ): Promise<void> => {
    await api.put(`/${subscriptionId}/company/${companyId}/address/${id}`, request);
  };
  
  export const patchCompanyAddress = async (
    subscriptionId: string,
    companyId: string,
    id: string,
    request: PatchCompanyAddressRequest
  ): Promise<void> => {
    await api.patch(`/${subscriptionId}/company/${companyId}/address/${id}`, request);
  };
  
  export const deleteCompanyAddress = async (
    subscriptionId: string,
    companyId: string,
    id: string
  ): Promise<void> => {
    await api.delete(`/${subscriptionId}/company/${companyId}/address/${id}`);
  };
  