// import { styled } from '@mui/material/styles';
import { styled, SxProps, Typography } from "@mui/material";

const Label = styled(Typography)(({ theme }) => ({
    ...theme.typography.body2,
    fontSize: 14,
    flexShrink: 0,
    color: theme.palette.text.secondary,
}));

interface Props {
    sx?: SxProps;
    text: string;
}

export function LabelStyle({ text, sx, ...other }: Props) {
    return <Label sx={{ ...sx }} {...other}>{text}</Label>;
}