import { PostProjectBulkActionRequest } from "../../models";
import { api } from "../api";

export const postProjectBulkAction = async (
  subscriptionId: string,
  request: PostProjectBulkActionRequest
): Promise<string> => {
  const response = await api.post<string>(
    `/${subscriptionId}/task-bulk-action`,
    request
  );
  return response.data;
};

export const deleteProjectBulkTask = async (
  subscriptionId: string,
  taskIds: string[]
): Promise<void> => {
  await api.delete(`/${subscriptionId}/task-bulk-action`, {
    data: { taskIds: taskIds },
  });
};
