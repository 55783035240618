import {
  IPaginated,
  ITeamLookup,
  GetTeamLookupRequest,
  IProjectLookup,
  GetProjectLookupRequest,
} from "../../models";

import { api } from "../api";

export const getProjectLookup = async (
  subscriptionId: string,
  currentPage: number,
  itemsPerPage: number,
  request?: GetProjectLookupRequest
): Promise<IPaginated<IProjectLookup>> => {
  const response = await api.get<IPaginated<IProjectLookup>>(
    `/${subscriptionId}/project/lookup`,
    {
      params: {
        currentPage: currentPage,
        itemsPerPage: itemsPerPage,
        ...request,
      },
    }
  );
  return response.data;
};
