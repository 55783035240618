import {
  GetAccountAttachmentsRequest,
  IAccountAttachment,
  IPaginated,
  PatchAccountAttachmentsRequest,
  PostAccountAttachmentsRequest,
} from "../../models";
import { api } from "../api";

export const getAccountAttachments = async (
  subscriptionId: string,
  accountId: string,
  currentPage: number,
  itemsPerPage: number,
  request?: GetAccountAttachmentsRequest
): Promise<IPaginated<IAccountAttachment>> => {
  const response = await api.get<IPaginated<IAccountAttachment>>(
    `/${subscriptionId}/account-attachment/${accountId}`,
    {
      params: {
        currentPage: currentPage,
        itemsPerPage: itemsPerPage,
        ...request,
      },
    }
  );
  return response.data;
};

export const postAccountAttachment = async (
  subscriptionId: string,
  accountId: string,
  file: File | string,
  request: PostAccountAttachmentsRequest
): Promise<IAccountAttachment> => {
  const formData = new FormData();
  formData.append("name", request.name);
  formData.append("file", file);
  // for (var i = 0; i < files.length; i++) {
  //   formData.append("file[]", files[i]);
  // }

  const response = await api.post(
    `/${subscriptionId}/account-attachment/${accountId}/attachment`,
    formData,
    {
      headers: {
        "Content-type": "multipart/form-date",
      },
    }
  );
  return response.data;
};

export const patchAccountAttachment = async (
  subscriptionId: string,
  accountId: string,
  attachmentId: string,
  request: PatchAccountAttachmentsRequest
): Promise<void> => {
  await api.patch(
    `/${subscriptionId}/account-attachment/${accountId}/attachment/${attachmentId}`,
    request
  );
};

export const deleteAccountAttachment = async (
  subscriptionId: string,
  accountId: string,
  attachmentId: string
): Promise<void> => {
  await api.delete(
    `/${subscriptionId}/account-attachment/${accountId}/attachment/${attachmentId}`
  );
};
