import {
  GetProjectSectionRequest,
  PostProjectSectionRequest,
  PatchProjectSectionRequest,
  PatchProjectSectionSequenceRequest,
  ISection,
} from "../../models";
import { api } from "../api";

export const getProjectSections = async (
  subscriptionId: string,
  projectId: string,
  request?: GetProjectSectionRequest
): Promise<ISection[]> => {
  const response = await api.get<ISection[]>(
    `/${subscriptionId}/project/${projectId}/section`,
    {
      params: {
        ...request,
      },
    }
  );
  return response.data;
};

export const getProjectSection = async (
  subscriptionId: string,
  projectId: string,
  id: string
): Promise<ISection> => {
  const response = await api.get<ISection>(
    `/${subscriptionId}/project/${projectId}/section/${id}`
  );
  return response.data;
};

export const postProjectSection = async (
  subscriptionId: string,
  projectId: string,
  request: PostProjectSectionRequest
): Promise<ISection> => {
  const response = await api.post<ISection>(
    `/${subscriptionId}/project/${projectId}/section`,
    request
  );
  return response.data;
};

export const patchProjectSection = async (
  subscriptionId: string,
  projectId: string,
  id: string,
  request: PatchProjectSectionRequest
): Promise<void> => {
  await api.patch(
    `/${subscriptionId}/project/${projectId}/section/${id}`,
    request
  );
};

export const patchProjectSectionSequence = async (
  subscriptionId: string,
  projectId: string,
  request: PatchProjectSectionSequenceRequest
): Promise<void> => {
  await api.patch(`/${subscriptionId}/project/${projectId}/sequence`, request);
};

export const deleteProjectSection = async (
  subscriptionId: string,
  projectId: string,
  id: string
): Promise<void> => {
  await api.delete(`/${subscriptionId}/project/${projectId}/section/${id}`);
};
