import {
  GetUserTeamRequest,
  IPaginated,
  IUserTeam,
  PostUserTeamRequest,
} from "../../models";
import { api } from "../api";

export const getUserTeams = async (
  subscriptionId: string,
  userId: string,
  currentPage: number,
  itemsPerPage: number,
  request?: GetUserTeamRequest
): Promise<IPaginated<IUserTeam>> => {
  const response = await api.get<IPaginated<IUserTeam>>(
    `/${subscriptionId}/user/user-team/${userId}`,
    {
      params: {
        currentPage: currentPage,
        itemsPerPage: itemsPerPage,
        ...request,
      },
    }
  );
  return response.data;
};

export const postUserTeam = async (
  subscriptionId: string,
  request: PostUserTeamRequest
): Promise<IUserTeam> => {
  const response = await api.post<IUserTeam>(
    `/${subscriptionId}/user/user-team`,
    request
  );
  return response.data;
};

export const deleteUserTeam = async (
  subscriptionId: string,
  teamMemberId: string
): Promise<void> => {
  await api.delete(`/${subscriptionId}/team-member/${teamMemberId}`);
};
