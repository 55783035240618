import { fPercent, fShortenNumber, secondsToDhms } from "@daxrm/utils";
import { Box, CardProps } from "@mui/material";
import merge from 'lodash/merge';
import ReactApexChart from 'react-apexcharts';
import { BaseOptionChart } from "./chart-setting";

interface ReactApexTreeMapProps extends CardProps {
    chartData: {
        name: string;
        data: {
            x: string,
            y: number
        }[];
    }[];
    metricType?: string;
    isDistributed?: boolean;
}

export const RATreeMap = ({
    chartData,
    metricType,
    isDistributed
}: ReactApexTreeMapProps) => {

    const chartOptions = merge(BaseOptionChart(), {
        theme: {
            palette: 'palette2'
        },
        legend: {
            show: false
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent'],
        },
        xaxis: {
            labels: {
                rotate: -45,
                trim: true,
            }
        },
        dataLabels: { enabled: true, dropShadow: { enabled: false } },
        tooltip: {
            y: {
                formatter: (val: number) => {

                    if (metricType && metricType === 'userEngagementDuration') {
                        return secondsToDhms(val);
                    } else if (metricType === 'gsc_impressions') {
                        return fShortenNumber(val);
                    } else if (metricType === 'gsc_ctr') {
                        return fPercent(val);
                    } else {
                        return val;
                    }
                },
            },
            x: {
                show: true,
            },
        },
        colors: [
            "#00bfff",
            "#189AB4",
            "#75E6DA",
            "#5E71F9",
            "#A8B2FC",
            "#C2C9FC",
            "#C6E2FF",
            "#2774AE",
            "#00538C",
            "#003399",
            "#005A9C",
            "#4B9CD3",
            "#0077c0",
            "#3E8EDE",
        ],
        plotOptions: {
            bar: {
                distributed: isDistributed
            },
            treemap: {
                distributed: true,
                enableShades: false
            }
        }
    });

    return (<>
        <Box sx={{ p: 3, pb: 1 }} dir="ltr">
            <ReactApexChart type="treemap" series={chartData} options={chartOptions} height={250} />
        </Box>
    </>);

}