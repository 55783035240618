import { IPaginated, ITeamLookup, GetTeamLookupRequest } from "../../models";

import { api } from "../api";

export const getTeamLookup = async (
  subscriptionId: string,
  currentPage: number,
  itemsPerPage: number,
  request?: GetTeamLookupRequest
): Promise<IPaginated<ITeamLookup>> => {
  const response = await api.get<IPaginated<ITeamLookup>>(
    `/${subscriptionId}/team/team-lookup`,
    {
      params: {
        currentPage: currentPage,
        itemsPerPage: itemsPerPage,
        ...request,
      },
    }
  );
  return response.data;
};

export const getTeamsLookup = async (
  subscriptionId: string,
  currentPage: number,
  itemsPerPage: number,
  request?: GetTeamLookupRequest
): Promise<IPaginated<ITeamLookup>> => {
  const response = await api.get<IPaginated<ITeamLookup>>(
    `/${subscriptionId}/team/teams-lookup`,
    {
      params: {
        currentPage: currentPage,
        itemsPerPage: itemsPerPage,
        ...request,
      },
    }
  );
  return response.data;
};
