// scroll bar

import 'simplebar/src/simplebar.css';
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

import { APIProvider } from './contexts/APIContext';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import App from './App';
import { AuthProvider } from './contexts';
import { BrowserRouter } from 'react-router-dom';
import { CollapseDrawerProvider } from './contexts/CollapseDrawerContext';
import { HelmetProvider } from 'react-helmet-async';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import ReactDOM from 'react-dom';
import { SettingsProvider } from './contexts/SettingsContext';

ReactDOM.render(
  <BrowserRouter>
    <AuthProvider>
      <APIProvider>
        <HelmetProvider>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <SettingsProvider>
              <CollapseDrawerProvider>
                <App />
              </CollapseDrawerProvider>
            </SettingsProvider>
          </LocalizationProvider>
        </HelmetProvider>
      </APIProvider>
    </AuthProvider>
  </BrowserRouter>,
  document.getElementById('root')
);
