import {
  GetUserLookupContactRequest,
  GetUserLookupRequest,
  IPaginated,
  IUserLookup,
  IUserLookupContact,
} from "../../models";

import { api } from "../api";

export const searchUserMention = async (
  subscriptionId: string,
  request?: GetUserLookupRequest
): Promise<{ id: string; value: string; email: string }[]> => {
  return (await getUserLookup(subscriptionId, 1, 20, request)).items.map(
    (x) => {
      return {
        id: x.id,
        value: `${x.firstName} ${x.lastName}`,
        email: x.email,
      };
    }
  );
};

export const getUserLookup = async (
  subscriptionId: string,
  currentPage: number,
  itemsPerPage: number,
  request?: GetUserLookupRequest
): Promise<IPaginated<IUserLookup>> => {
  const response = await api.get<IPaginated<IUserLookup>>(
    `/${subscriptionId}/user/lookup`,
    {
      params: {
        currentPage: currentPage,
        itemsPerPage: itemsPerPage,
        ...request,
      },
    }
  );
  return response.data;
};

export const getUserLookupContact = async (
  subscriptionId: string,
  currentPage: number,
  itemsPerPage: number,
  request?: GetUserLookupContactRequest
): Promise<IPaginated<IUserLookupContact>> => {
  const response = await api.get<IPaginated<IUserLookupContact>>(
    `/${subscriptionId}/user/lookup/contact`,
    {
      params: {
        currentPage: currentPage,
        itemsPerPage: itemsPerPage,
        ...request,
      },
    }
  );
  return response.data;
};
