import {
  GetAccountConnectorRequest,
  GetMicrosoftConnectorReportRequest,
  GetMicrosoftMadsConnectorAuthUrlRequest,
  IMADSAccount,
  IMADSConnectedAccount,
  IMadsConnectorReport,
  IMicrosoftConnector,
  IMicrosoftConnectorAuthUrlResponse,
  IMicrosoftConnectorTokenResponse,
  PatchMADSAccountRequest,
  PatchUnlinkMicrosoftConnectedItemRequest,
  PostConnectMicrosoftConnectorRequest,
} from "../../models";
import { api } from "../api";

export const getMicrosoftMadsConnectorAuthUrl = async (
  subscriptionId: string,
  request?: GetMicrosoftMadsConnectorAuthUrlRequest
): Promise<IMicrosoftConnectorAuthUrlResponse> => {
  const response = await api.get<IMicrosoftConnectorAuthUrlResponse>(
    `/${subscriptionId}/connector/microsoft/mads/auth-url`,
    {
      params: request,
    }
  );
  return response.data;
};

export const getMicrosoftMadsConnectors = async (
  subscriptionId: string
): Promise<IMicrosoftConnector[]> => {
  const response = await api.get<IMicrosoftConnector[]>(
    `/${subscriptionId}/connector/microsoft/mads`
  );
  return response.data;
};

export const connectToMicrosoftMadsConnector = async (
  subscriptionId: string,
  request: PostConnectMicrosoftConnectorRequest
): Promise<IMicrosoftConnector> => {
  const response = await api.post<IMicrosoftConnector>(
    `/${subscriptionId}/connector/microsoft/mads/connect`,
    request
  );
  return response.data;
};

export const disconnectFromMicrosoftMadsConnector = async (
  subscriptionId: string,
  id: string
): Promise<void> => {
  await api.delete(
    `/${subscriptionId}/connector/microsoft/mads/disconnect/${encodeURIComponent(
      id
    )}`
  );
};

export const getMicrosoftMadsConnectorToken = async (
  subscriptionId: string,
  id: string
): Promise<IMicrosoftConnectorTokenResponse> => {
  const response = await api.get<IMicrosoftConnectorTokenResponse>(
    `/${subscriptionId}/connector/google/mads/token/${encodeURIComponent(id)}`
  );
  return response.data;
};

export const getMicrosoftMadsAccounts = async (
  subscriptionId: string,
  request?: GetAccountConnectorRequest
): Promise<IMADSAccount> => {
  const response = await api.get<IMADSAccount>(
    `/${subscriptionId}/account-connector/link`,
    {
      params: request,
    }
  );
  return response.data;
};

export const patchMicrosoftMadsAccount = async (
  subscriptionId: string,
  accountId: string,
  request: PatchMADSAccountRequest
): Promise<void> => {
  await api.patch(`/${subscriptionId}/account-connector/${accountId}`, request);
};

export const getMADSConnectedAccount = async (
  subscriptionId: string,
  accountId: string,
  request?: GetAccountConnectorRequest
): Promise<IMADSConnectedAccount> => {
  const response = await api.get<IMADSConnectedAccount>(
    `/${subscriptionId}/account-connector/${accountId}/list`,
    {
      params: request,
    }
  );
  return response.data;
};

export const unlinkMADSConnectedAccount = async (
  subscriptionId: string,
  accountId: string,
  request: PatchUnlinkMicrosoftConnectedItemRequest
): Promise<void> => {
  await api.patch(
    `/${subscriptionId}/account-connector/${accountId}/unlink`,
    request
  );
};

export const getMicrosoftMadsAccountReport = async (
  subscriptionId: string,
  accountId: string,
  request?: GetMicrosoftConnectorReportRequest
): Promise<IMadsConnectorReport> => {
  const response = await api.get<IMadsConnectorReport>(
    `/${subscriptionId}/account-connector/${accountId}/report`,
    {
      params: request,
    }
  );
  return response.data;
};
