import { SnackbarKey, useSnackbar } from "notistack";

import { Icon } from "@iconify/react";
import { MIconButton } from "..";
import closeFill from "@iconify/icons-eva/close-fill";

export const NCloseAction = (key: SnackbarKey) => {
  const { closeSnackbar } = useSnackbar();

  const onCloseHandler = () => {
    closeSnackbar(key);
  };

  return (
    <MIconButton
      size="small"
      sx={{
        // color: (theme) => theme.palette.primary.contrastText,
      }}
      onClick={onCloseHandler}
    >
      <Icon icon={closeFill} />
    </MIconButton>
  );
}