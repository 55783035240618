import { GetReportImageGalleryRequest, GetReportsRequest, IPaginated, IReport, IReportImageGallery, PatchReportAccountRequest, PostReportAccountCloneRequest, PostReportAccountRequest, PostReportBrandingLogoRequest, PostReportImageGalleryUploadRequest } from "../../models";
import { api } from "../api";

export const getReportImageGallery = async (
  subscriptionId: string,
  currentPage: number,
  itemsPerPage: number,
  request?: GetReportImageGalleryRequest
): Promise<IPaginated<IReportImageGallery>> => {
  const response = await api.get<IPaginated<IReportImageGallery>>(
    `/${subscriptionId}/image-gallery`,
    {
      params: {
        currentPage: currentPage,
        itemsPerPage: itemsPerPage,
        ...request,
      },
    }
  );
  return response.data;
};

export const postReportImageGalleryUpload = async (
  subscriptionId: string,
  request: PostReportImageGalleryUploadRequest
): Promise<void> => {
  const formData = new FormData();
  formData.append("file", request.file);
  const response = await api.post(`/${subscriptionId}/image-gallery`, formData, {
    headers: {
      "Content-type": "multipart/form-date",
    },
  });
  return response.data;
};


export const deleteReportImageGallery = async (
  subscriptionId: string,
  id: string
): Promise<void> => {
  await api.delete(`/${subscriptionId}/image-gallery/${id}`);
};