import { Button, Dialog, DialogActions, DialogContentText, Stack, Typography } from '@mui/material';
import React, { useState } from "react";

import { LoadingButton } from '@mui/lab';
import { Iconify } from '../components';

type IDialogType = "info" | "success" | "warning" | "error" | "custom";
type IBtnColorType = "info" | "primary" | "success" | "warning" | "error";
type IWidthType = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

export interface IUseConfirmationProps {
    onResult: (result: boolean, data?: any) => Promise<void>;
    dialogType?: IDialogType;
    dialogTypeIcon?: React.ReactNode;
    maxWidthValue?: IWidthType;
    title?: React.ReactNode;
    subTitle?: React.ReactNode;
    yesButtonText?: React.ReactNode;
    yesBtnColor?: IBtnColorType;
    yesBtnDisabled?: boolean;
    noButtonText?: React.ReactNode;
    content?: React.ReactNode;
    extraActions?: React.ReactNode;
    anchor?: JSX.Element;
}

export interface UseConfirmationState {
    open: (data?: any) => void;
    close: VoidFunction;
    dialog: React.ReactNode;
}

export const useConfirmation = ({
    onResult,
    dialogType = 'error',
    dialogTypeIcon,
    maxWidthValue = 'sm',
    title = "Are you sure?",
    subTitle,
    yesButtonText = "Yes",
    yesBtnColor = "error",
    yesBtnDisabled = false,
    noButtonText = "No",
    content,
    extraActions,
    anchor
}: IUseConfirmationProps): UseConfirmationState => {
    const [isOpen, setIsOpen] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [data, setData] = useState<any>();

    const onOpenHandler = (contextData?: any) => {
        setData(contextData);
        setIsOpen(true);
    }

    const onCloseHandler = () => {
        setIsOpen(false);
    };

    const onYesHandler = async () => {
        setIsProcessing(true);
        await onResult(true, data);
        setIsProcessing(false);
        setIsOpen(false);
    }

    const onNoHandler = () => {
        onResult(false, data);
        setIsOpen(false);
    }

    const ConfirmationDialog = <>
        {anchor &&
            <>
                {
                    React.cloneElement(anchor, {
                        onClick: onOpenHandler
                    })
                }
            </>
        }
        <Dialog fullWidth maxWidth={maxWidthValue} open={isOpen} onClose={onCloseHandler}>
            <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
                sx={{ mt: 2 }}
            >
                {dialogType === 'info' && <Iconify icon={'eva:info-outline'} width={96} height={96} sx={{ color: 'info.main' }} />}
                {dialogType === 'success' && <Iconify icon={'eva:checkmark-circle-2-outline'} width={96} height={96} sx={{ color: 'success.main' }} />}
                {dialogType === 'warning' && <Iconify icon={'eva:alert-triangle-outline'} width={96} height={96} sx={{ color: 'warning.main' }} />}
                {dialogType === 'error' && <Iconify icon={'eva:close-circle-outline'} width={96} height={96} sx={{ color: 'error.main' }} />}
                {dialogType === 'custom' && dialogTypeIcon && dialogTypeIcon}
            </Stack>

            <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                sx={{ p: 2 }}
            >
                <Typography variant="h5" align='center'>
                    {title}
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary', px: 2 }}>
                    {subTitle}
                </Typography>
            </Stack>

            {content &&
                <>
                    <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={2}
                        sx={{ px: 3, mt: 2 }}
                    >
                        {typeof content === "string"
                            ? <DialogContentText>
                                {content}
                            </DialogContentText>
                            : <DialogActions>
                                {content}
                            </DialogActions>
                        }
                    </Stack>
                </>
            }
            <DialogActions>
                <LoadingButton
                    onClick={onYesHandler}
                    loading={isProcessing}
                    variant="contained"
                    disabled={yesBtnDisabled}
                    color={yesBtnColor}
                >
                    {yesButtonText}
                </LoadingButton>
                <Button
                    onClick={onNoHandler}
                    variant="outlined"
                    color='inherit'
                >
                    {noButtonText}
                </Button>
                {extraActions}
            </DialogActions>
        </Dialog>
    </>;

    return {
        open: onOpenHandler,
        close: onCloseHandler,
        dialog: ConfirmationDialog,
    };
}