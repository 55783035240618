import {
  IAccountClose,
  IAccountSetting,
  PatchAccountSettingRequest,
} from "../../models";
import { api } from "../api";

export const getAccountSetting = async (
  subscriptionId: string
): Promise<IAccountSetting> => {
  const response = await api.get<IAccountSetting>(
    `/${subscriptionId}/account-setting`
  );
  return response.data;
};

export const patchAccountSetting = async (
  subscriptionId: string,
  id: string,
  request: PatchAccountSettingRequest
): Promise<void> => {
  await api.patch(`/${subscriptionId}/account-setting/${id}`, request);
};

export const closeAccount = async (
  subscriptionId: string
): Promise<IAccountClose> => {
  const response = await api.get<IAccountClose>(
    `/${subscriptionId}/account-setting/close-subscription/export`
  );
  return response.data;
};
