import { ITaskAssignee, PostTaskAssigneeRequest } from "../../models";
import { api } from "../api";

export const postTaskAssignee = async (
  subscriptionId: string,
  taskId: string,
  request: PostTaskAssigneeRequest
): Promise<ITaskAssignee> => {
  const response = await api.post<ITaskAssignee>(
    `/${subscriptionId}/task/${taskId}/assignee`,
    request
  );
  return response.data;
};

export const deleteTaskAssignee = async (
  subscriptionId: string,
  taskId: string,
  id: string
): Promise<void> => {
  await api.delete(`/${subscriptionId}/task/${taskId}/assignee/${id}`);
};
