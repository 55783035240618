// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_PAGE = '/oops';
const ROOTS_AUTH = '/auth';
const ROOTS_APP = '';

// ----------------------------------------------------------------------

export const PATH_PAGE = {
  root: ROOTS_PAGE,
  page403: path(ROOTS_PAGE, '/403'),
  page404: path(ROOTS_PAGE, '/404'),
};

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  completeRegistration: path(ROOTS_AUTH, '/google/complete-registration'),
  forgotPassword: path(ROOTS_AUTH, '/forgot-password'),
  action: path(ROOTS_AUTH, '/action'),
};

export const PATH_DASHBOARD = {
  root: ROOTS_APP,
  general: {
    app: path(ROOTS_APP, '/app'),
    ecommerce: path(ROOTS_APP, '/ecommerce'),
    dashboard: path(ROOTS_APP, '/dashboard'),
    banking: path(ROOTS_APP, '/banking'),
    booking: path(ROOTS_APP, '/booking'),
  },
  company: {
    root: path(ROOTS_APP, '/company'),
    new: path(ROOTS_APP, '/company/new'),
    list: path(ROOTS_APP, '/company/list'),
    overview: (id: string, tabCode: string) =>
      path(ROOTS_APP, `/company/${id}/${tabCode}/overview`),
    edit: (id: string) => path(ROOTS_APP, `/company/${id}/edit`),
  },
  contact: {
    root: path(ROOTS_APP, '/contact'),
    list: path(ROOTS_APP, '/contact/list'),
  },
  connector: {
    root: path(ROOTS_APP, '/connector'),
    gmb: {
      root: path(ROOTS_APP, '/connector/gmb'),
      connectCallback: path(ROOTS_APP, '/connector/google/gmb/callback'),
    },
    ga: {
      root: path(ROOTS_APP, '/connector/ga'),
      connectCallback: path(ROOTS_APP, '/connector/google/ga/callback'),
    },
    gads: {
      root: path(ROOTS_APP, '/connector/gads'),
      connectCallback: path(ROOTS_APP, '/connector/google/gads/callback'),
    },
    xero: {
      connectCallback: path(ROOTS_APP, '/connector/xero/connect/callback'),
    },
  },
  profile: {
    root: path(ROOTS_APP, '/my-profile'),
  },
  invoice: {
    overview: (id: string, overviewId: string) =>
      path(ROOTS_APP, `/invoice/${overviewId}/${id}/overview`),
    new: (id: string) => path(ROOTS_APP, `/invoice/${id}/new`),
    edit: (id: string, overviewId: string) => path(ROOTS_APP, `/invoice/${overviewId}/${id}/edit`),
  },
  settings: {
    root: path(ROOTS_APP, '/settings'),
    list: path(ROOTS_APP, '/settings/list'),
    user: path(ROOTS_APP, '/settings/user'),
    role: path(ROOTS_APP, '/settings/role'),
    team: path(ROOTS_APP, '/settings/team'),
    service: path(ROOTS_APP, '/settings/service'),
    subscriptionInvoice: path(ROOTS_APP, '/settings/subscription-invoice'),
  },
  account: {
    root: path(ROOTS_APP, '/account'),
    list: path(ROOTS_APP, '/account/list'),
    overview: (id: string, tabCode: string, isOpenSlider: number) =>
      path(ROOTS_APP, `/account/${id}/${tabCode}/${isOpenSlider}/overview`),
  },
  project: {
    root: path(ROOTS_APP, '/project'),
    list: path(ROOTS_APP, '/project/list'),
    overview: (id: string) => path(ROOTS_APP, `/project/${id}/overview`),
  },
  report: {
    root: path(ROOTS_APP, '/report'),
    list: path(ROOTS_APP, '/report/designer'),
  },
  projectTemplate: {
    root: path(ROOTS_APP, '/projecttemplate'),
    list: path(ROOTS_APP, '/projecttemplate/list'),
    overview: (id: string) => path(ROOTS_APP, `/projecttemplate/${id}/overview`),
  },
  mytask: {
    root: path(ROOTS_APP, '/mytask'),
    list: path(ROOTS_APP, '/mytask/list'),
  },
  teamTask: {
    root: path(ROOTS_APP, '/teamtask'),
    list: (id: string) => path(ROOTS_APP, `/teamtask/${id}/list`),
  },
};
