import { ReactNode, useEffect } from 'react';

import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { useTheme } from '@mui/material/styles';

// rtl
// import rtlPlugin from 'stylis-plugin-rtl';

// ----------------------------------------------------------------------

type Props = {
  children: ReactNode;
};

export default function RtlLayout({ children }: Props) {
  const theme = useTheme();

  useEffect(() => {
    document.dir = theme.direction;
  }, [theme.direction]);

  const cacheRtl = createCache({
    key: theme.direction === 'rtl' ? 'rtl' : 'css',
    // stylisPlugins: theme.direction === 'rtl' ? [rtlPlugin] : [],
    stylisPlugins: theme.direction === 'rtl' ? [] : [],

  });

  return <CacheProvider value={cacheRtl}>{children}</CacheProvider>;
}
