import {
  GetImportReportTemplateFromGalleryRequest,
  IImportReportTemplateFromGallery,
  IPaginated,
  PostImportReportTemplateFromGalleryRequest
} from "../../models";

import { api } from "../api";

export const getImportReportTemplateFromGalley = async (
  subscriptionId: string,
  currentPage: number,
  itemsPerPage: number,
  request?: GetImportReportTemplateFromGalleryRequest
): Promise<IPaginated<IImportReportTemplateFromGallery>> => {
  const response = await api.get<IPaginated<IImportReportTemplateFromGallery>>(
    `/${subscriptionId}/report-template-gallery`,
    {
      params: {
        currentPage: currentPage,
        itemsPerPage: itemsPerPage,
        ...request,
      },
    }
  );
  return response.data;
};

export const postImportReportTemplateFromGalley = async (
  subscriptionId: string,
  request: PostImportReportTemplateFromGalleryRequest
): Promise<IImportReportTemplateFromGallery> => {
  const response = await api.post<IImportReportTemplateFromGallery>(
    `/${subscriptionId}/report-template-gallery`,
    request
  );
  return response.data;
};