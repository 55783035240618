import {
  DeleteKeywordRequest,
  GetKeywordAnalysisAuditRequest,
  GetKeywordSuggestionRequest,
  GetKeywordTrackingsRequest,
  IKeywordAnalysis,
  IKeywordAnalysisAudit,
  IKeywordSuggestion,
  IKeywordSuggestionList,
  IKeywordSuggestionLocationList,
  IKeywordTracking,
  IPaginated,
  PatchKeywordTrackingRequest,
  PostKeywordTrackingRequest,
} from "../../../models";
import { api } from "../../api";
import { _keywordTrackings } from "./keywordData";
// _mock_

export const getKeywords = async (
  subscriptionId: string,
  serpId: string,
  currentPage: number,
  itemsPerPage: number,
  request?: GetKeywordTrackingsRequest
): Promise<IPaginated<IKeywordTracking>> => {
  const response = await api.get<IPaginated<IKeywordTracking>>(
    `/${subscriptionId}/serp/${serpId}/keyword`,
    {
      params: {
        currentPage: currentPage,
        itemsPerPage: itemsPerPage,
        ...request,
      },
    }
  );
  return response.data;
};

export const postKeyword = async (
  subscriptionId: string,
  serpId: string,
  request: PostKeywordTrackingRequest
): Promise<IKeywordTracking> => {
  const response = await api.post<IKeywordTracking>(
    `/${subscriptionId}/serp/${serpId}/keyword`,
    request
  );
  return response.data;
};

export const patchKeyword = async (
  subscriptionId: string,
  serpId: string,
  request: PatchKeywordTrackingRequest
): Promise<void> => {
  await api.patch(`/${subscriptionId}/serp/${serpId}/keyword`, request);
};

export const deleteKeyword = async (
  subscriptionId: string,
  serpId: string,
  request: DeleteKeywordRequest
): Promise<void> => {
  await api.delete(`/${subscriptionId}/serp/${serpId}/keyword`, {
    data: request,
  });
};

export const getKeywordAnalysis = async (
  subscriptionId: string,
  serpId: string,
  keywordId: string
): Promise<IKeywordAnalysis> => {
  const response = await api.get<IKeywordAnalysis>(
    `/${subscriptionId}/serp/${serpId}/keyword-audit/${keywordId}`
  );
  return response.data;
};

export const getKeywordAnalysisAudit = async (
  subscriptionId: string,
  serpId: string,
  keywordId: string,
  request?: GetKeywordAnalysisAuditRequest
): Promise<IKeywordAnalysisAudit[]> => {
  const response = await api.get<IKeywordAnalysisAudit[]>(
    `/${subscriptionId}/serp/${serpId}/keyword-audit-dates/${keywordId}`,
    {
      params: {
        ...request,
      },
    }
  );
  return response.data;
};

export const getKeywordSuggestions = async (
  subscriptionId: string,
  request?: GetKeywordSuggestionRequest
): Promise<IKeywordSuggestionList> => {
  const response = await api.get<IKeywordSuggestionList>(
    `/${subscriptionId}/keyword-suggestion`,
    {
      params: {
        ...request,
      },
    }
  );
  return response.data;
};

export const getKeywordSuggestionLocations = async (
  subscriptionId: string,
  request?: GetKeywordSuggestionRequest
): Promise<IKeywordSuggestionLocationList> => {
  const response = await api.get<IKeywordSuggestionLocationList>(
    `/${subscriptionId}/keyword-suggestion`,
    {
      params: {
        ...request,
      },
    }
  );
  return response.data;
};
