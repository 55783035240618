import {
  GetGeolocationRequest,
  IGeolocation,
  IGeolocationAddressComponents,
  IPaginated,
} from "../../models";

import { api } from "../api";

export const getGeolocations = async (
  currentPage: number,
  itemsPerPage: number,
  request?: GetGeolocationRequest
): Promise<IPaginated<IGeolocation>> => {
  const response = await api.get<IPaginated<IGeolocation>>(
    `/google-autocomplete`,
    {
      params: {
        currentPage: currentPage,
        itemsPerPage: itemsPerPage,
        ...request,
      },
    }
  );
  return response.data;
};

export const getGeolocationAddressComponents = async (
  placeId: string
): Promise<IGeolocationAddressComponents> => {
  const response = await api.get<IGeolocationAddressComponents>(
    `/google-autocomplete/${placeId}`
  );
  return response.data;
};
