import { GetPlanAddOnsRequest, Plan } from "../../models";

import { api } from "../api";

export const getPlanAddOns = async (
  subscriptionId: string,
  request: GetPlanAddOnsRequest
): Promise<Plan[]> => {
  const response = await api.get<Plan[]>(`/subscription/stripe/plan-add-on`, {
    params: {
      ...request,
    },
  });
  return response.data;
};
