import {
  GetDashboardDashboardActivityStreamRequest,
  IDashboardAccountSummary,
  IDashboardActivityStream,
  IDashboardDonutChart,
  IDashboardService,
  IPaginated,
  ITask,
} from "../../models";
import { api } from "../api";

export const getDashboardAccountSummary = async (
  subscriptionId: string
): Promise<IDashboardAccountSummary> => {
  const response = await api.get<IDashboardAccountSummary>(
    `/${subscriptionId}/dashboard/account-summary`
  );
  return response.data;
};

export const getDashboardActivityStream = async (
  subscriptionId: string,
  currentPage: number,
  itemsPerPage: number,
  request?: GetDashboardDashboardActivityStreamRequest
): Promise<IPaginated<IDashboardActivityStream>> => {
  const response = await api.get<IPaginated<IDashboardActivityStream>>(
    `/${subscriptionId}/dashboard/activity`,
    {
      params: {
        currentPage: currentPage,
        itemsPerPage: itemsPerPage,
        ...request,
      },
    }
  );
  return response.data;
};

export const getDashboardDonutChart = async (
  subscriptionId: string
): Promise<IDashboardDonutChart[]> => {
  const response = await api.get<IDashboardDonutChart[]>(
    `/${subscriptionId}/dashboard/task-overview`
  );
  return response.data;
};

export const getDashboardService = async (
  subscriptionId: string
): Promise<IDashboardService[]> => {
  const response = await api.get<IDashboardService[]>(
    `/${subscriptionId}/dashboard/services-overview`
  );
  return response.data;
};

export const getDashboardMyTask = async (
  subscriptionId: string
): Promise<ITask[]> => {
  const response = await api.get<ITask[]>(
    `/${subscriptionId}/dashboard/get-mytask`
  );
  return response.data;
};
