import {
  GetSerpLocationsRequest,
  IPaginated,
  ISerpLocation,
  PostLocationLookupRequest,
} from "../../models";

import { api } from "../api";

export const getSerpLocations = async (
  subscriptionId: string,
  currentPage: number,
  itemsPerPage: number,
  request?: GetSerpLocationsRequest
): Promise<IPaginated<ISerpLocation>> => {
  const response = await api.get<IPaginated<ISerpLocation>>(
    `/${subscriptionId}/serp/location`,
    {
      params: {
        currentPage: currentPage,
        itemsPerPage: itemsPerPage,
        ...request,
      },
    }
  );
  return response.data;
};

export const postLocation = async (
  subscriptionId: string,
  request: PostLocationLookupRequest
): Promise<ISerpLocation> => {
  const response = await api.post<ISerpLocation>(
    `/${subscriptionId}/serp/location`,
    request
  );
  return response.data;
};
