import {
  GetSerpStatisticsRequest,
  IRealTimeSerp,
  ISerp,
  ISerpAuditDate,
  ISerpStatistics,
  PatchSerpRequest,
  PostRealTimeSerpRequest,
  PostSerpRequest,
} from "../../../models";
import { api } from "../../api";

export const getSerp = async (
  subscriptionId: string,
  accountId: string
): Promise<ISerp> => {
  const response = await api.get<ISerp>(`/${subscriptionId}/serp/${accountId}`);
  return response.data;
};

export const postSerp = async (
  subscriptionId: string,
  accountId: string,
  request: PostSerpRequest
): Promise<ISerp> => {
  const response = await api.post<ISerp>(
    `/${subscriptionId}/serp/${accountId}`,
    request
  );
  return response.data;
};

export const patchSerp = async (
  subscriptionId: string,
  id: string,
  request: PatchSerpRequest
): Promise<void> => {
  await api.patch(`/${subscriptionId}/serp/${id}`, request);
};

export const postRealTimeSerp = async (
  subscriptionId: string,
  serpId: string,
  accountId: string,
  request: PostRealTimeSerpRequest
): Promise<IRealTimeSerp> => {
  const response = await api.post<IRealTimeSerp>(
    `/${subscriptionId}/serp/${serpId}/real-time-serp/${accountId}`,
    request
  );
  return response.data;
};

export const getSerpAuditDates = async (
  subscriptionId: string,
  serpId: string
): Promise<ISerpAuditDate[]> => {
  const response = await api.get<ISerpAuditDate[]>(
    `/${subscriptionId}/serp/${serpId}/audit-dates`
  );
  return response.data;
};

export const getSerpStatistics = async (
  subscriptionId: string,
  serpId: string,
  request: GetSerpStatisticsRequest
): Promise<ISerpStatistics> => {
  const response = await api.get<ISerpStatistics>(
    `/${subscriptionId}/serp/${serpId}/keyword-statistics`,
    {
      params: {
        ...request,
      },
    }
  );
  return response.data;
};

export const deleteSerp = async (
  subscriptionId: string,
  serpId: string,
  accountId: string
): Promise<void> => {
  await api.delete(`/${subscriptionId}/serp/${serpId}/connector/${accountId}`);
};
