import { api } from "../api";

export const createAttachment = async (
  subscriptionId: string,
  file: File
): Promise<string> => {
  return new Promise<string>((resolve, reject) => {
    const formData = new FormData();
    formData.append("file", file);

    api
      .post(`/${subscriptionId}/attachment`, formData, {
        headers: {
          "Content-type": "multipart/form-date",
        },
      })
      .then((response) => {
        resolve(response.data.default);
      })
      .catch(() => {
        reject("Upload failed");
      });
  });
};

export const createTaskEditorAttachment = async (
  subscriptionId: string,
  taskId: string,
  file: File
): Promise<string> => {
  return new Promise<string>((resolve, reject) => {
    const formData = new FormData();
    formData.append("file", file);

    api
      .post(
        `/${subscriptionId}/task/${taskId}/task-attachment-editor`,
        formData,
        {
          headers: {
            "Content-type": "multipart/form-date",
          },
        }
      )
      .then((response) => {
        resolve(response.data.fileUrl);
      })
      .catch(() => {
        reject("Upload failed");
      });
  });
};
